<template>
  <ForceDeleteModal
    v-if="forceDeleteModal"
    forceDeleteType="account"
    @close="forceDeleteModal = false"
    :force-delete-id="forceDeleteId"
    @error="data => $emit('error', data)"
    @success="accountForceDeleteSuccess"
  />
  <RateLimits
    v-if="modalOpen === 'limits'"
    :platformId="platformId"
    :platformNames="platformNames"
    :accountId="accountIdForUpdate"
    :limitsData="limitsDataProps"
    :platformIcons="platformIcons"
    :platformToIdMap="platformToIdMap"
    @close="closeModal"
    @callGetAllConnectAccount="getAllConnectedAccount"
    @error="data => $emit('error', data)"
    @success="data => $emit('success', data)"
  />
  <ProfileVariables
    v-if="modalOpen === 'variable'"
    :key="variableDataProps"
    :accountId="accountIdForUpdate"
    :variableData="variableDataProps"
    :platformIcons="platformIcons"
    :platformToIdMap="platformToIdMap"
    :platformId="platformId"
    :platformNames="platformNames"
    @close="closeModal"
    @callGetAllConnectAccount="getAllConnectedAccount"
    @error="data => $emit('error', data)"
    @success="data => $emit('success', data)"
  />

  <div v-if="showWorkspaceManagerTable()" class="flex h-full flex-1 flex-col">
    <TableSkeletonLoader />
  </div>
  <div v-else class="flex h-full min-w-0 flex-1 flex-col">
    <div
      v-if="
        (socialAccountsLeft > 0 || socialAccountsLeft === 'UNLIMITED') &&
        userRole !== 'viewer'
      "
      class="flex items-center px-4 sm:px-5 pt-2 justify-end"
    >
      <Popper hover>
        <Button
          text="Add New"
          leftIcon="plus"
          class="h-[33px]"
          @click="onAddNewClick()"
        />
        <template #content>
          <div
            class="w-max max-w-lg border-[1px] border-blue-300 rounded bg-blue-50 p-2 text-sm"
          >
            <p>
              Social Account Limit Left:<span class="text-blue-500 font-bold"
                >&nbsp;{{ socialAccountsLeft }}</span
              >
            </p>
          </div>
        </template>
      </Popper>
    </div>
    <div
      v-else-if="userRole !== 'viewer'"
      class="flex items-center px-4 sm:px-5 pt-2 justify-end"
    >
      <Popper :show="showPopper" :interactive="true">
        <Button
          text="Add New"
          color="gray"
          leftIcon="plus"
          class="h-[33px]"
          @click="showPopper = !showPopper"
        />

        <template #content>
          <div
            class="w-max max-w-lg border-[1px] border-blue-300 rounded bg-blue-50 p-2 text-sm"
          >
            <p class="text-sm">
              Plan limit reached to
              <span class="font-bold text-blue-500">{{
                socialAccountsAllowed
              }}</span>
              social account max. Please upgrade to add more accounts
            </p>
            <p
              @click="$router.push('/settings-billing/plan')"
              class="font-bold cursor-pointer text-blue-500 text-sm"
            >
              Click Here to Upgrade Your Account
            </p>
          </div>
        </template>
      </Popper>
    </div>

    <div
      v-if="showEmptyData() && !showWorkspaceManagerTable()"
      class="mt-10 flex w-full flex-col items-center justify-center space-y-4"
    >
      <SvgIcon :name="emptyData" />
      <p class="text-3xl font-bold text-gray-900 dark:text-gray-50">
        No Accounts Available
      </p>
    </div>

    <div
      v-if="!showEmptyData() && !showWorkspaceManagerTable()"
      class="flex-1 overflow-y-auto overflow-x-hidden"
    >
      <WorkspaceManagerTable
        @modal="showModal"
        :table-data="workspaceManagerTable"
        :proxyData="proxyData"
        :desktopData="desktopData"
        @apiError="onError"
        @proxyChangeResponse="proxyChangeResponse"
        @showForceDeleteModal="showForceDeleteModal"
        @fetchAllConnectedAccounts="getAllConnectedAccountAfterUpdate"
      />
    </div>
    <div
      v-if="!showEmptyData() && !showWorkspaceManagerTable() && count > 1"
      class="flex shrink-0 items-center justify-center border-t border-gray-200 py-3 dark:border-gray-700"
    >
      <Paginator
        @page="onPaginate"
        ref="page"
        v-model:first="first"
        :rows="20"
        :totalRecords="total"
        template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown "
      ></Paginator>
    </div>
  </div>
</template>

<script>
import { getConnectedAccountsUi } from '@/apis/connectedAccounts'
import { getDesktop } from '@/apis/settings/desktop'
import { getAllProxy } from '@/apis/proxy'
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
// import Pagination from '@/components/Pagination.vue'
import Paginator from 'primevue/paginator'
import CreateNewWorkspaceSection from '@/components/settings/createNewWorkspaceSection.vue'
import SettingsMenu from '@/components/settings/settingsMenu.vue'
import WorkspaceManagerTable from '@/components/settings/WorkspaceManagerCloud/WorkspaceManagerTable.vue'
import Spinner from '@/components/Spinner.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import ToastContainer from '@/components/ToastContainer.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import ProfileVariables from './profileVariables.vue'
import RateLimits from './rateLimits.vue'
import ForceDeleteModal from '@/components/settings/WorkspaceManagerCloud/ForceDeleteModal.vue'
import Button from '@/components/Button.vue'
import { getExecutionTimeLeft } from '@/common/functions/executionTimeFetch'
import Popper from 'vue3-popper'
import TableSkeletonLoader from '@/components/settings/WorkspaceManagerCloud/TableSkeletonLoader.vue'

export default {
  name: 'WorkspaceManagerCloud',
  components: {
    AutomationHeader,
    SettingsMenu,
    CreateNewWorkspaceSection,
    Paginator,
    WorkspaceManagerTable,
    RateLimits,
    ProfileVariables,
    Spinner,
    ToastContainer,
    SvgIcon,
    ForceDeleteModal,
    Button,
    Popper,
    TableSkeletonLoader
    // ConnectNewAccountModal,
    // ViewPlatforms
  },
  data() {
    return {
      modalOpen: '',
      workspaceManagerTable: [],
      variableDataProps: [],
      limitsDataProps: [],
      accountIdForUpdate: null,
      accountId: null,
      emptyData: null,
      platformId: null,
      platformNames: null,
      platformIcons: null,
      platformToIdMap: null,
      count: 1,
      currPage: this.$route.query.page || 1,
      rateLimitDataUser: null,
      proxyData: null,
      desktopData: null,
      total: 0,
      first: 0,
      title: 'Workspace Manager Cloud',
      showAccounts: false,
      forceDeleteModal: false,
      forceDeleteId: null,
      showPopper: false,
      proxyFetch: false,
      totalProxyData: null
    }
  },

  created() {
    this.getMode()
    if (this.$route.query.page) {
      this.first = (this.$route.query.page - 1) * 20
    }
  },

  computed: {
    ...mapGetters('settings', ['getWorkspaceManagerLoadingStatus']),
    ...mapState('user', ['socialAccountsLeft']),
    ...mapState('user', ['socialAccountsAllowed']),
    ...mapState('settings', ['userRole'])
  },
  methods: {
    proxyChangeResponse(data) {
      this.$emit('proxyChangeResponse', data)
    },
    showEmptyData() {
      return (
        Array.isArray(this.workspaceManagerTable) &&
        this.workspaceManagerTable.length === 0
      )
    },

    getMode() {
      window.matchMedia('(prefers-color-scheme: dark)').matches
        ? (this.emptyData = 'empty-data-dark')
        : (this.emptyData = 'empty-data-light')
    },

    onError(data) {
      this.$emit('error', data)
    },
    showForceDeleteModal(data) {
      this.forceDeleteModal = true
      this.forceDeleteId = data
    },

    showModal(type, data) {
      if (data) {
        data.accountId && (this.accountIdForUpdate = data.accountId)
        data.platformId && (this.platformId = data.platformId)
        data.platformNames && (this.platformNames = data.platformNames)
        data.platformIcons && (this.platformIcons = data.platformIcons)
        data.platformToIdMap && (this.platformToIdMap = data.platformToIdMap)

        if (data.limits) {
          const temp = []
          const objectList = Object.entries(data.limits)

          for (const objectListElement of objectList) {
            temp.push({
              platform: objectListElement[0],
              limits: objectListElement[1]
            })
          }

          this.limitsDataProps = {
            data: data,
            limitsData: temp
          }
        }
      }

      this.modalOpen = type
    },

    closeModal() {
      this.modalOpen = ''
    },

    showWorkspaceManagerTable() {
      return this.getWorkspaceManagerLoadingStatus
    },

    ...mapActions('settings', ['updateWorkspaceManagerStatus']),

    getInitials(name) {
      const fullName = name.split(' ')
      const initials =
        fullName.length > 1
          ? fullName.shift().charAt(0) + fullName.pop().charAt(0)
          : fullName[0].substring(0, 2)
      return initials.toUpperCase()
    },

    checkIfProxyIsActive(proxyId, desktopId, proxyList, desktopList) {
      if (proxyId) {
        const selectedProxy = proxyList.find(o => o['proxyId'] === proxyId)
        return selectedProxy && selectedProxy['isUsable']
          ? selectedProxy.name
          : false
      } else if (desktopId) {
        const selectedDesktop = desktopList.find(o => o._id === desktopId)
        return selectedDesktop.name
      } else {
        return false
      }
    },

    activeProxyName(proxyId, desktopId, proxyList, desktopList) {
      if (proxyId) {
        const selectedProxy = proxyList.find(o => o['proxyId'] === proxyId)
        return selectedProxy?.name
      } else if (desktopId) {
        const selectedDesktop = desktopList.find(o => o._id === desktopId)
        return selectedDesktop.name
      } else {
        return undefined
      }
    },

    proxyNameForAccount(id, proxyList) {
      let idArr = id.split(',')
      let nameArr = []
      try {
        idArr.forEach(element => {
          let name = proxyList.find(
            item => item.forConnectedAccount === element
          )
          if (name) {
            nameArr.push(name.name)
          }
        })

        return nameArr
      } catch (error) {
        console.log(error)
      }
    },

    checkIfProxyProvidedByTexau(proxyId, proxyList) {
      const selectedProxy = proxyList.find(o => o['proxyId'] === proxyId)
      return selectedProxy && selectedProxy['isProvidedByTexAu']
        ? selectedProxy['isProvidedByTexAu']
        : false
    },

    checkProxyLocationFlag(proxyId, proxyList) {
      const selectedProxy = proxyList.find(o => o['proxyId'] === proxyId)
      if (selectedProxy) {
        return selectedProxy.location.countryCode
      } else {
        return null
      }
    },
    async formatApiResponse(dataArr, allProxyList, allDesktopList) {
      try {
        const globalArray = []
        this.desktopData = allDesktopList
        let proxyNameList

        let filteredProxy = allProxyList.filter(
          item => item.isProvidedByTexAu === false
        )
        proxyNameList = filteredProxy.map(item => item.name)

        for (const dataArrElement of dataArr) {
          const localArray = [
            {
              id: dataArrElement.id,
              platformId: dataArrElement.platformIds,
              platformNames: dataArrElement.platformNames,
              platformToIdMap: dataArrElement.platformToIdMap,
              isPremium: dataArrElement.isPremium ?? false,
              name: dataArrElement.name,
              caption: '',
              initials: this.getInitials(dataArrElement.name),
              variables: dataArrElement.variables,
              limits: dataArrElement.limits,
              dataForSync: JSON.stringify(dataArrElement.dataForSync),
              profilePictureUrl: dataArrElement.picture
                ? dataArrElement.picture
                : null,
              readyToUse: dataArrElement?.readyToUse ?? null
            },
            {
              url: dataArrElement.platformIcon
            },
            {
              options: [
                ...proxyNameList,
                ...this.proxyNameForAccount(dataArrElement.id, allProxyList)
              ]
            },
            {
              country: dataArrElement.location ?? ''
            },
            {
              updatedAt: dataArrElement.updatedAt ?? ''
            },
            {
              account: dataArrElement.accountUrl ?? ''
            },
            {
              active: !dataArrElement.isExpired
            },
            {
              activeProxy: this.checkIfProxyIsActive(
                dataArrElement['proxyId'],
                dataArrElement['desktopId'],
                allProxyList,
                allDesktopList
              ),
              activeProxyName: this.activeProxyName(
                dataArrElement['proxyId'],
                dataArrElement['desktopId'],
                allProxyList,
                allDesktopList
              ),
              providedByTexau: this.checkIfProxyProvidedByTexau(
                dataArrElement['proxyId'],
                allProxyList
              ),
              activeProxyLocationFlag: this.checkProxyLocationFlag(
                dataArrElement['proxyId'],
                allProxyList
              ),
              desktopNames: allDesktopList.map(item => item.name),
              desktops: allDesktopList
            }
          ]
          globalArray.push(localArray)
        }

        this.workspaceManagerTable = globalArray
        await this.updateWorkspaceManagerStatus({ payload: false })
      } catch (error) {
        throw error
      }
    },
    onAddNewClick() {
      this.$emit('onAddNewClick')
    },

    async getAllConnectedAccount() {
      try {
        let start = 0
        if (this.$route.query.page) {
          start = (this.$route.query.page - 1) * 20
        }
        await this.updateWorkspaceManagerStatus({ payload: true })
        await getExecutionTimeLeft()
        let connectedAccountResponse, allProxyList, allDesktopList
        if (!this.proxyFetch) {
          ;[connectedAccountResponse, allProxyList, allDesktopList] =
            await Promise.all([
              getConnectedAccountsUi(start, 20),
              getAllProxy(),
              getDesktop()
            ])
          this.totalProxyData = allProxyList.total
          this.proxyData = allProxyList.data
          this.proxyFetch = true
        } else {
          ;[connectedAccountResponse, allDesktopList] = await Promise.all([
            getConnectedAccountsUi(start, 20),
            getDesktop()
          ])
        }

        this.count = Math.ceil(connectedAccountResponse.total / 20)
        this.total = connectedAccountResponse.total

        await this.formatApiResponse(
          connectedAccountResponse.data,
          this.proxyData,
          allDesktopList.data
        )
      } catch (error) {
        await this.updateWorkspaceManagerStatus({ payload: false })
        this.onError(error)
      }
    },

    async getAllConnectedAccountAfterUpdate() {
      this.proxyFetch = false
      await this.getAllConnectedAccount()
    },
    async updateQuery(updates) {
      await this.$router.push({
        query: {
          ...this.$route.query,
          ...updates
        }
      })
    },
    async onPaginate(e) {
      await this.updateQuery({
        page: Number(e.page + 1)
      })
      this.getAllConnectedAccount()
    },
    async accountForceDeleteSuccess() {
      this.$emit('success', 'Account Deleted Successfully')
      this.getAllConnectedAccount()
    }
  },
  async beforeMount() {
    await this.getAllConnectedAccount()

    let startProxy = 50
    while (this.proxyData && this.totalProxyData != this.proxyData.length) {
      const response = await getAllProxy(startProxy, 50)
      this.proxyData.push(...response.data)
      startProxy += 50
    }
  }
}
</script>

<style scoped></style>
