<template>
  <div>
    <vee-form
      :validation-schema="schema"  
      class="mt-5" 
      @submit="submitPassword()"
    >
      <div class="space-y-5">
        <p class="flex text-sm text-gray-600 dark:text-gray-400 text-center">
          {{ otpSentToText }}
        </p>
        <div>
          <label
            class="block text-sm font-medium text-gray-900 dark:text-gray-50"
            for="" 
          >
            OTP
          </label>
          <div class="mt-1.5">
            <Input v-model="code" :text="`Enter the OTP sent to your registered ${otpSentViaText}`" type="tel" name="code" />
            <p class="mt-2 text-sm text-red-600">
              <ErrorMessage name="code" />
            </p>
          </div>
        </div>
        <div>
          <label
            class="block text-sm font-medium text-gray-900 dark:text-gray-50"
            for=""
          >
            New Password
          </label>
          <div class="mt-1.5">
            <Input
              v-model="password"
              name="Password"
              text="Enter New Password"
              :type="passwordFieldType"
              :rightIcon="passwordFieldType === 'text' ? 'eye-off' : 'eye'"
              rightIconMouse="cursor-pointer"
              @onRightIconClick="onRightIconClick('password')"
            />
            <p class="mt-2 text-sm text-red-600">
              <ErrorMessage name="Password" />
            </p>
            <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
              Your password must include:
            </p>
            <ul class="ml-4 list-disc text-sm text-gray-600 dark:text-gray-400">
              <li>At least 12 characters</li>
              <li>At least one uppercase letter (A-Z)</li>
              <li>At least one lowercase letter (a-z)</li>
              <li>At least one number (0-9)</li>
              <li>At least one special character (@$!%*?&)</li>
            </ul>
          </div>
        </div>
        <div>
          <label
            class="block text-sm font-medium text-gray-900 dark:text-gray-50"
            for=""
          >
            Confirm Password
          </label>
          <div class="mt-1.5">
            <Input
              v-model="confirmPassword"
              name="confirmPassword"
              text="Confirm New Password"
              :type="confirmPasswordFieldType"
              :rightIcon="confirmPasswordFieldType === 'text' ? 'eye-off' : 'eye'"
              rightIconMouse="cursor-pointer"
              @onRightIconClick="onRightIconClick('confirmPassword')"
            />
            <p class="mt-2 text-sm text-red-600">
              <ErrorMessage name="confirmPassword" />
            </p>
          </div>
        </div>
        <div>
          <Button
            :showLoader="isLoading"
            class="w-full"
            text="Reset Password"
            type="submit"
          />
        </div>
      </div>
    </vee-form>
    <ResendOtpCounter
      ref="resend"
      resend-otp-for-phone
      @success="onSuccess"
      @error="onError"
      @stopAPI="data => (stopAPI = data)"
      :email="email"
    />
  </div>
</template>

<script>
import { resetPassword } from '@/apis/onboarding'
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
import { ref } from 'vue'
import ResendOtpCounter from '@/components/onboarding/ResendOtpCounterResetPassword.vue'

export default {
  name: 'ResetPassword',
  computed: {
    otpSentViaText() {
      const sentVia = localStorage.getItem('otpSentVia');
      return sentVia === 'phone_number' ? 'phone number' : 'email';
    },
    otpSentToText(){
      if(this.otpSentViaText === 'phone number'){
        const phoneNumber = localStorage.getItem('otpSentTo') || '';
        return `We’ve sent a one-time password (OTP) to your registered phone number ending with ${phoneNumber.slice(-4)}`;
      } else {
        return `We’ve sent a one-time password (OTP) to your email ${this.email}`;
      }
    }
  },
  components: {
    Input,
    Button,
    ResendOtpCounter,
  },
  data() {
    return {
      code: null,
      password: null,
      isLoading: false,
      passwordFieldType: 'password',
      confirmPasswordFieldType: 'password',
      stopAPI: false,
      schema: {
        Password: 'required|password_min:12|password_no_spaces',
        confirmPassword: 'confirmPassword_required|password_min:12|password_mismatch:@Password',
        code: 'otp_required|min:6|max:6'
      },
    }
  },
  props: {
    email: { type: String, required: true }
  },
  methods: {
    async submitPassword() {
      this.isLoading = true
      let response
      try {
        let data = {
          email: this.email,
          confirmationCode: this.code,
          newPassword: this.password
        }
        response = await resetPassword(data)
        if (response['success']) {
          this.$emit('passwordReset', {
            type: 'success',
            message: 'Password Reset Successfully !'
          })
        } else {
          throw 'responseError'
        }
      } catch (error) {
        if (error === 'responseError') {
          this.$emit('passwordReset', {
            message: response.message || 'An unexpected error occurred.Please try again later.',
            type: 'fail'
          })
        } else {
          console.log(error)
        }
        this.isLoading = false
      }
    },
    onRightIconClick(field) {
      const fieldMap = {
        password: 'passwordFieldType',
        confirmPassword: 'confirmPasswordFieldType'
      };

      if (fieldMap[field]) {
        this[fieldMap[field]] = this[fieldMap[field]] === 'password' ? 'text' : 'password';
      }
    },
    async getNewOtp() {
      if (!this.email || this.email === '') {
        this.emailError = true
        return
      }
      if (this.stopAPI) {
        return this.$emit('error', 'Please wait before sending new request')
      }
      this.emailError = false
      this.emailLoading = true

      try {
        await this.$refs.resend.sendPhoneOtp();
      } catch (error) {
        this.$emit('error', 'Failed to resend OTP. Please try again later.');
      } finally {
        this.emailLoading = false;
      }
    },
    onSuccess(data) {
      this.emailLoading = false
      this.showCodeInput = true
      this.$emit('success', data)
    },
    onError(errorMessage) {
      this.emailLoading = false
      this.$emit('error', errorMessage || 'An unexpected error occurred.');
    }
  }
}
</script>

<style></style>
