<template>
  <AutomationHeader :title="automationTitle" :iconUrl="automationLogo" />
  <section
    class="flex flex-1 overflow-y-auto border-t border-gray-200 dark:border-gray-700"
  >
    <div v-if="selectedNode" class="tableClass w-[80vw] flex flex-1 px-4 pt-5">
      <div class="mt-2 flex flex-1 flex-col">
        <Output
          :isWorkflow="true"
          :selected-node="selectedNode"
          :workflowToggleOptions="
            nodes.map((node, index) => ({
              label: `Step ${node.order}${node.routeLabel}: ${node.label}`,
              value: node,
              img: node.platformIconUrl
            }))
          "
          :executionToggleOptions="getOptions()"
          :selectedExecutionNode="selectedExecutionNode"
          :fetchNewExecutionData="fetchNewExecutionData"
          @logo="automationLogo = $event"
          @nodeChange="nodeChange"
          @nodeExecutionChange="nodeExecutionChange"
          @success="e => emit('success', e)"
          @error="e => emit('error', e)"
        />
      </div>
    </div>
    <div v-else class="flex h-full w-full items-center justify-center">
      <Spinner size="large" />
    </div>
  </section>
</template>

<script setup>
import {
  getWorkflow,
  getWorkflowAllNodes,
  getWorkflowExecution
} from '@/apis/workflows'
import { formatDate, formatTime } from '@/common/functions/formatDateAndTime'
import Spinner from '@/components/Spinner.vue'
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import Output from '@/components/dataStore/output.vue'
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()
const nodes = ref(null)
const selectedNode = ref(null)
const automationTitle = ref(null)
const automationLogo = ref(null)
const executionidArr = ref(null)
const selectedExecutionNode = ref(null)
const fetchNewExecutionData = ref(1)

const emit = defineEmits(['error', 'success', 'warning'])

onMounted(async () => {
  const workflowResponse = await getWorkflow(route.query.workflowId)
  automationTitle.value = workflowResponse.data.name
  const executionResponse = await getWorkflowExecution(route.query.workflowId)
  executionidArr.value = executionResponse.data

  const workflowNodesResponse = await getWorkflowAllNodes(
    route.query.workflowId
  )

  nodes.value = bfsTraversal(
    workflowNodesResponse.data,
    workflowResponse.data.startNode
  )
  // nodes.value = nodes.value.filter(obj => !obj.hasOwnProperty('utilityType'))

  if (route.query.operationId) {
    let n = nodes.value.filter(
      node => node.platformOperationId === route.query.operationId
    )[0]
    await setOutputMode(n)
    selectedNode.value = n
  } else {
    await setOutputMode(nodes.value[0])
    selectedNode.value = nodes.value[0]
  }

  if (route.query.executionId) {
    if (route.query.outputMode === 'append') {
      selectedExecutionNode.value = 'all'
    } else {
      selectedExecutionNode.value = executionidArr.value.filter(
        node => node._id === route.query.executionId
      )[0]
    }
  } else {
    selectedExecutionNode.value = executionidArr.value[0]
  }
})

const nodeChange = async data => {
  await setOutputMode(data)

  if (data.outputMode !== 'append') {
    selectedExecutionNode.value = executionidArr.value[0]
  } else {
    selectedExecutionNode.value = 'all'
  }

  selectedNode.value = data
}

const setOutputMode = async data => {
  await router.push({
    query: {
      nodeId: route.query.nodeId,
      executionId: route.query.executionId,
      workflowId: route.query.workflowId,
      platformId: route.query.platformId,
      operationId: route.query.operationId,
      outputMode: data?.outputMode ?? 'append'
    },
    replace: true
  })
}

const nodeExecutionChange = data => {
  selectedExecutionNode.value = data
  fetchNewExecutionData.value++
}

const bfsTraversal = (nodes, startNode) => {
  const traversedNodes = []
  const nodesMap = {}
  nodes.forEach(node => {
    nodesMap[node._id] = node
  })

  let currentNodeOrder = 1
  const queue = [{ nodeId: startNode, order: currentNodeOrder, routeLabel: '' }]
  while (queue.length) {
    const { nodeId, order, routeLabel } = queue.shift()
    if (!nodeId) continue
    const currentNode = nodesMap[nodeId]
    if (!currentNode) continue
    if (
      currentNode.utilityType === 'incomingwebhook' ||
      currentNode.utilityType === 'outgoingwebhook' ||
      !currentNode.utilityType
    )
      traversedNodes.push({ ...currentNode, order, routeLabel })

    // push all the possible next nodes to the queue
    currentNodeOrder = currentNode.utilityType
      ? currentNode.utilityType === 'incomingwebhook' ||
        currentNode.utilityType === 'outgoingwebhook'
        ? order + 1
        : order
      : order + 1
    if (currentNode.nextNode)
      queue.push({
        nodeId: currentNode.nextNode,
        order: currentNodeOrder,
        routeLabel
      })
    // secondary nodes
    queue.push(
      ...currentNode.secondaryNodes.map(node => ({
        nodeId: node.nodeId,
        order: '',
        routeLabel: ''
      }))
    )

    // routing nodes
    const routingNodes =
      currentNode.inputs?.routers?.map(route => route.nextNode) || []
    for (let i = 0; i < routingNodes.length; i++) {
      if (i === 0) {
        queue.push({
          nodeId: routingNodes[i],
          order: currentNodeOrder,
          routeLabel: 'A'
        })
      } else {
        queue.push({
          nodeId: routingNodes[i],
          order: currentNodeOrder,
          routeLabel: 'B'
        })
      }
    }
  }
  return traversedNodes
}

const getOptions = () => {
  let arr = executionidArr.value.map((node, index) => ({
    label: ` Result ${executionidArr.value.length - index}: ${formatDate(
      node.createdAt
    )} ${formatTime(node.createdAt)}`,
    value: node
  }))
  if (route.query.outputMode === 'append') {
    arr.unshift({
      label: 'All',
      value: 'all'
    })
  }
  return arr
}
</script>

<style scoped></style>
