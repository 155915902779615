<template>
  <div
    class="fixed left-0 top-0 z-40 flex h-screen w-screen items-center justify-center"
  >
    <div
      class="absolute left-0 top-0 h-screen w-screen bg-gray-400 bg-opacity-40"
      @click="$emit('input-modal-close')"
    />
    <div
      class="z-10 min-h-[450px] w-[720px] rounded-[10px] bg-white px-8 py-10"
    >
      <!-- Top tab and close button -->
      <div class="relative mb-9">
        <div class="relative flex w-full justify-center space-x-4">
          <!-- <div
            @click="(inputNode = true), (outputNode = false)"
            class="select-none rounded-lg bg-gray-100 px-3 py-2 text-sm font-medium text-gray-700 transition-all duration-200 dark:bg-gray-700 dark:text-gray-50"
          >
            Input
          </div> -->
          <a
            @click="
              (inputNode = true), (outputNode = false), (delayNode = false)
            "
            href="#"
            title=""
            :class="[{ 'bg-gray-100': inputNode === true }]"
            class="rounded-lg px-3 py-2 text-sm font-medium text-gray-500 transition-all duration-200 hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50"
          >
            Input
          </a>

          <a
            v-if="operation.hasRandomDelay && selectedNode.order != 1"
            @click="
              (delayNode = true), (outputNode = false), (inputNode = false)
            "
            href="#"
            title=""
            :class="[{ 'bg-gray-100': delayNode === true }]"
            class="rounded-lg px-3 py-2 text-sm font-medium text-gray-500 transition-all duration-200 hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50"
          >
            Delay
          </a>

          <a
            @click="
              (outputNode = true), (inputNode = false), (delayNode = false)
            "
            href="#"
            title=""
            :class="[{ 'bg-gray-100': outputNode === true }]"
            class="rounded-lg px-3 py-2 text-sm font-medium text-gray-500 transition-all duration-200 hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50"
          >
            Output
          </a>
          <span
            v-show="saving || loadingInputs"
            class="absolute right-[40px] flex items-center px-3 py-2 text-gray-300"
          >
            {{ saving ? 'Saving' : 'Loading' }}
            <Spinner size="small" class="ml-1 text-gray-400" />
          </span>
        </div>
        <button
          type="button"
          class="absolute right-2 top-0 h-9 w-9 rounded-lg border border-gray-200 bg-white p-1.5 text-gray-600 transition-all duration-200 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50 dark:focus:ring-offset-gray-900"
          @click="$emit('input-modal-close')"
        >
          <span class="sr-only"> Close </span>
          <SvgIcon name="close" class="m-auto" />
        </button>
      </div>

      <div v-if="inputNode">
        <div v-if="platformInputs" class="max-h-[550px] overflow-y-scroll">
          <CsvInput
            v-if="isCSV"
            :inputs="nodeInputs"
            :platformInputs="platformInputs"
            :selectedNode="selectedNode"
            @file-uploaded="saveCsvInputs"
            @saving="saving = $event"
          />
          <div class="flex flex-col overflow-auto" v-else>
            <div class="flex justify-between" v-if="isGooglePlatform">
              <div class="mb-4 text-blue-500 flex gap-2">
                <div class="pt-[2px]">
                  <svg
                    class="shrink-0 h-4 w-4"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      opacity="0.12"
                      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                      fill="currentColor"
                    />
                    <path
                      d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <p class="text-sm font-medium text-blue-500">
                  Make sure the selected spreadsheet contains header.
                </p>
              </div>
              <div>
                <p class="text-sm font-medium text-gray-500 pl-[3px]">
                  Add New Google Account:
                </p>
                <div class="flex items-end justify-end">
                  <img
                    @click="addOauthAccount('google-sheets')"
                    class="h-[46px] cursor-pointer"
                    src="@/assets/images/logos/btn_google_signin_dark_normal_web@2x.png"
                    alt="signin-with-google"
                  />
                </div>
              </div>
            </div>

            <ValidationForm
              :platformInputs="checkAndSetPlatformInputs"
              :previousNodes="previousNodes"
              :inputs="nodeInputs"
              :errorMessage="errorMessage"
              class="grid-cols-1"
              @input-update="onInputUpdate"
              @input-blur="onInputBlur"
              @toggleOpenAiAccounts="toggleOpenAiAccounts"
            />

            <span
              v-show="loadingDynamicInputs"
              class="m-auto flex items-center text-gray-300"
            >
              Checking for more inputs
              <Spinner size="small" class="ml-1 text-gray-400" />
            </span>
          </div>
        </div>
      </div>

      <div v-else-if="outputNode" class="max-h-[550px] overflow-y-scroll">
        <OutputModeConfig
          @success="msg => $emit('success', msg)"
          :selectedNode="selectedNode"
        />
      </div>

      <div v-else class="max-h-[550px] overflow-y-scroll">
        <IterationDelayConfig
          :selectedNode="selectedNode"
          :operation="operation"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  getConnectedAccounts,
  getPlatformInfo
} from '@/apis/automation-store/Page1'
import { getGoogleHeaders } from '@/apis/automation-store/googleSheets'
import {
  getAllPlatforms,
  getOperation,
  getOperationVariableServices,
  getPlatformOperations
} from '@/apis/getPlatformDetails'
import { startOauthConnection } from '@/apis/oauth'
import { getWorkflowNode, updateWorkflowNode } from '@/apis/workflows'
import {
  AUTOMATION_VARIABLE_DIRECTION,
  AUTOMATION_VARIABLE_TYPES,
  constants
} from '@/common/constants'
import Button from '@/components/Button.vue'
import Spinner from '@/components/Spinner.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import ValidationForm from '@/components/ValidationForm.vue'
import { useVueFlow } from '@vue-flow/core'
import { computed, onMounted, ref, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { getAllPreviousNodes, updateNodeAutomation } from '../helper'
import CsvInput from './csvInput.vue'
import OutputModeConfig from '@/components/automationStore/OutputModeConfig.vue'
import IterationDelayConfig from '@/components/workflowBuilder/IterationDelayConfig.vue'
const emit = defineEmits([
  'input-modal-close',
  'error',
  'error-message',
  'success',
  'clear-configure-message'
])

const props = defineProps({
  selectedNode: {
    type: Object,
    required: true
  },
  nodes: {
    type: Object,
    required: true
  },
  desktopDataSelected: {
    type: Object
  },
  cloned: {}
})

const platformInputs = ref()
const platformDetails = ref()
const nodeInputs = ref()
const route = useRoute()
const loadingInputs = ref(true)
const loadingDynamicInputs = ref(false)
const previousNodes = ref([])
const saving = ref(false)
const platforms = ref([])
const operations = ref([])
const operation = ref([])
const connectedAccounts = ref([])
const allPlatformsFetched = ref(false)
const allOperationsFetched = ref(false)
const isCSV = ref(
  props.selectedNode.automationData.platformOperationId ===
    constants.csvPlatformOperationId
)
const errorMessage = ref({})
const isSocialAccountOptional = ref(null)
const { nodes } = useVueFlow()
const store = useStore()
const inputNode = ref(true)
const outputNode = ref(false)
const delayNode = ref(false)
const showOpenAiAccountsFlag = ref(false)
const isGooglePlatform = computed(() => {
  return (
    props.selectedNode.automationData.platformId === constants.googlePlatformId
  )
})

/**
 * Computed property that checks the selected node's order and updates
 * the `allowMapping` property of each object in `platformInputs`.
 *
 * If the selected node's order is 1, it returns a new array of objects where
 * the `allowMapping` property is set to `false` for each item. Otherwise,
 * it returns the original `platformInputs` array.
 *
 * @returns {Array<Object>} A new array of platform inputs with potentially updated `allowMapping` properties.
 */
const checkAndSetPlatformInputs = computed(() => {
  if (
    props.selectedNode.order === 1 &&
    props.selectedNode?.previousNodeId == null
  ) {
    return platformInputs.value.map(item => {
      return { ...item, allowMapping: false }
    })
  }
  return platformInputs.value
})

const fetchConnectedAccounts = async platformId => {
  // get platform details to check if cookies are required to show connected accounts dropdown
  const platformDetailsResponse = await getPlatformInfo(platformId)
  platformDetails.value = platformDetailsResponse.data
  if (isSocialAccountOptional.value) {
    connectedAccounts.value = null
    return
  }
  if (platformDetailsResponse.data.authType) {
    const accountsResponse = await getConnectedAccounts(platformId)
    connectedAccounts.value = accountsResponse.data
    return
  }
  connectedAccounts.value = null
}

// fetch all the inputs and node details on mount
// to display the input fields and their existing value
// saved in node.
onMounted(async () => {
  try {
    const [
      platformResponse,
      nodeResponse,
      platformsResponse,
      operationsResponse,
      operationResponse
    ] = await Promise.all([
      // fetch operation inputs and node details to display
      // the inputs and stored values for those inputs
      getOperationVariableServices(
        props.selectedNode.automationData.platformId,
        props.selectedNode.automationData.platformOperationId,
        AUTOMATION_VARIABLE_DIRECTION.INPUT,
        props.selectedNode.id
      ),
      getWorkflowNode(route.params.id, props.selectedNode.id),
      // $TODO: Change automation type
      getAllPlatforms(0, 50, ''),
      getPlatformOperations(props.selectedNode.automationData.platformId),
      getOperation(
        props.selectedNode.automationData.platformId,
        props.selectedNode.automationData.platformOperationId
      )
    ])

    isSocialAccountOptional.value =
      operationResponse.data?.isSocialAccountOptional
    await fetchConnectedAccounts(props.selectedNode.automationData.platformId),
      (loadingInputs.value = false)
    previousNodes.value = getAllPreviousNodes(props.selectedNode, props.nodes)
    platforms.value = platformsResponse.data

    // get selected operation and merge with list of operations
    // this is done because in some cases selected operation is not there in the list of operations.
    operations.value = mergeAndRemoveDuplicates(
      operationResponse.data,
      operationsResponse.data
    )

    operation.value = operationResponse.data

    // save the first connected account id, if connected account is empty
    if (
      nodeResponse.data.connectedAccountId == undefined &&
      connectedAccounts.value?.[0]?._id
    ) {
      await updateWorkflowNode(route.params.id, props.selectedNode.id, {
        connectedAccountId: connectedAccounts.value[0]?._id,
        isConfigured: true
      })
      emit('clear-configure-message', props.selectedNode.id)
    }

    // extract default values for inputs from platform response
    const defaultValues = platformResponse.data.reduce((acc, curr) => {
      acc[curr.name] = curr.defaultValue
      return acc
    }, {})

    for (const obj of platformResponse.data) {
      if (obj.name === 'googleSpreadsheetId') {
        if (obj?.actionButtons && obj?.actionButtons.length > 0) {
          obj.actionButtons[0].forType = 'googleSheet'
        }
      }
    }

    addExtraInputs(
      nodeResponse.data.connectedAccountId || connectedAccounts.value?.[0]?._id,
      platformResponse.data,
      {
        ...defaultValues,
        ...nodeResponse.data.inputs,
        platformId: props.selectedNode.automationData.platformId,
        platformOperationId:
          props.selectedNode.automationData.platformOperationId
      },
      nodeResponse
    )
    loadingInputs.value = false
  } catch (error) {
    console.log(error)
    emit('error', 'Error occurred while fetching inputs.')
  }
})

onUnmounted(async () => {
  try {
    if (props.cloned) {
      emit('clear-configure-message', props.selectedNode.id)
      await updateWorkflowNode(route.params.id, props.selectedNode.id, {
        isConfigured: true
      })
    }
  } catch (error) {
    emit('error', 'Error occurred while saving changes.')
  }
})

const toggleOpenAiAccounts = async (data, validationResult, input) => {
  try {
    if (data) {
      if (showOpenAiAccountsFlag.value) {
        return
      }
      loadingDynamicInputs.value = true
      const response = await getConnectedAccounts(constants.OPENAI_PLATFORM_ID)
      if (response['success']) {
        platformInputs.value.unshift({
          name: 'txOpenAiAccountId',
          type: 'select',
          label: 'OpenAI Account',
          isRequired: true,
          choices: response.data.map(account => ({
            value: account._id,
            label: account.name
          }))
        })
        nodeInputs.value = {
          ...nodeInputs.value,
          txOpenAiAccountId: response.data[0]._id
        }
        showOpenAiAccountsFlag.value = true
        loadingDynamicInputs.value = false
      } else {
        throw response.message
      }
    } else {
      showOpenAiAccountsFlag.value = false
      delete nodeInputs.value['txOpenAiAccountId']
      platformInputs.value = platformInputs.value.filter(
        item => item.name !== 'txOpenAiAccountId'
      )
    }
  } catch (error) {
    console.log(error)
    emit('error', error)
  }
}

const loadMorePlatforms = async (searchText, reset) => {
  if (allPlatformsFetched.value && !reset) return allPlatformsFetched.value

  let start = platforms.value.length
  if (reset) {
    start = 0
  }

  const data = await getAllPlatforms(start, 10, '', searchText)
  if (reset) {
    platforms.value = data.data
  } else {
    platforms.value.push(...data.data)
  }
  if (platforms.value.length === data.total) {
    allPlatformsFetched.value = true
  } else {
    allPlatformsFetched.value = false
  }

  platformInputs.value = platformInputs.value.map(input => {
    if (input.name === 'platformId') {
      return {
        ...input,
        choices: platforms.value.map(platform => ({
          value: platform._id,
          label: platform.label
        })),
        infiniteScrollConfig: {
          ...input.infiniteScrollConfig,
          done: allPlatformsFetched.value
        }
      }
    }
    return input
  })

  return allPlatformsFetched.value
}
const loadMoreOperations = async (searchText, reset) => {
  if (allOperationsFetched.value && !reset) return allOperationsFetched.value

  let start = operations.value.length
  if (reset) {
    start = 0
  }

  const data = await getPlatformOperations(
    nodeInputs.value.platformId,
    searchText,
    start,
    10
  )
  if (reset) {
    operations.value = data.data
  } else {
    operations.value.push(...data.data)
  }
  if (operations.value.length === data.total) {
    allOperationsFetched.value = true
  } else {
    allOperationsFetched.value = false
  }

  platformInputs.value = platformInputs.value.map(input => {
    if (input.name === 'platformOperationId') {
      return {
        ...input,
        choices: operations.value.map(operation => ({
          value: operation.platformOperationId,
          label: operation.name
        })),
        infiniteScrollConfig: {
          ...input.infiniteScrollConfig,
          done: allOperationsFetched.value
        }
      }
    }
    return input
  })

  return allOperationsFetched.value
}

const addExtraInputs = async (
  connectedAccountId,
  actualPlatformInputs,
  inputs,
  nodeResponse
) => {
  const extraInputs = []
  if (connectedAccounts.value) {
    inputs = {
      ...inputs,
      connectedAccountId
    }
    const account = connectedAccounts.value.find(
      acc => acc._id === connectedAccountId
    )

    actualPlatformInputs = actualPlatformInputs.map(input => {
      const updatedInput = { ...input, texauVariables: account?.variables }

      if (input.name === 'messageContent') {
        updatedInput.isPremium = account?.isPremium
      }
      return updatedInput
    })
    extraInputs.push({
      name: 'connectedAccountId',
      type: 'select',
      label: platformDetails.value.label + ' Account',
      isRequired: platformDetails.value.isOptional ? false : true,
      choices: connectedAccounts.value.map(account => ({
        value: account._id,
        label: account.name
      })),
      leftImageUrl: account?.platform?.picture,
      includeSearch: true
    })
  }

  if (
    nodeResponse.data?.inputs?.message?.promptId ||
    nodeResponse.data?.inputs?.messageContent?.promptId
  ) {
    const response = await getConnectedAccounts(constants.OPENAI_PLATFORM_ID)
    if (response['success']) {
      extraInputs.unshift({
        name: 'txOpenAiAccountId',
        type: 'select',
        label: 'OpenAI Account',
        isRequired: true,
        choices: response.data.map(account => ({
          value: account._id,
          label: account.name
        }))
      })
      showOpenAiAccountsFlag.value = true
    }
  }

  if (
    props.selectedNode.automationData.platformOperationId ===
    constants.googleOutputPlatformOperationId
  ) {
    actualPlatformInputs.push({
      name: 'columnMapping',
      type: AUTOMATION_VARIABLE_TYPES.OUTPUT_CHECKBOX,
      label: 'Select Output',
      isRequired: false,
      previousNodes: previousNodes.value.sort((a, b) => a.order - b.order),
      hasDependencyOnFields: ['googleSheetName']
    })
  }
  nodeInputs.value = inputs
  platformInputs.value = [
    // {
    //   name: 'platformId',
    //   type: 'select',
    //   label: 'Platform',
    //   isRequired: true,
    //   choices: platforms.value.map(platform => ({
    //     value: platform._id,
    //     label: platform.label
    //   })),
    //   leftImageUrl: platformDetails.value.logoUrl,
    //   includeSearch: true,
    //   infiniteScrollConfig: {
    //     onLoadMore: loadMorePlatforms
    //   }
    // },
    // {
    //   name: 'platformOperationId',
    //   type: 'select',
    //   label: 'Automation',
    //   isRequired: true,
    //   choices: operations.value.map(operation => ({
    //     value: operation.platformOperationId,
    //     label: operation.name
    //   })),
    //   leftImageUrl: platformDetails.value.logoUrl,
    //   includeSearch: true,
    //   infiniteScrollConfig: {
    //     onLoadMore: loadMoreOperations
    //   }
    // },
    ...extraInputs
  ].concat(actualPlatformInputs)
}

const onInputUpdate = async inputs => {
  const inputObj = { ...inputs }
  loadingInputs.value = true
  // check if the user change the platform input
  // if (
  //   nodeInputs.value.platformId !== inputObj.platformId &&
  //   inputObj.platformId
  // ) {
  //   // if platform was changed,
  //   // fetch the operations of the newer platform
  //   // and update the choices
  //   const [operationsResponse] = await Promise.all([
  //     getPlatformOperations(inputObj.platformId),
  //     fetchConnectedAccounts(inputObj.platformId)
  //   ])
  //   operations.value = operationsResponse.data
  //   platformInputs.value = platformInputs.value.map(input => {
  //     if (input.name === 'platformOperationId') {
  //       return {
  //         ...input,
  //         choices: getOperationOptions()
  //       }
  //     }
  //     return input
  //   })
  //   addExtraInputs(inputObj.connectedAccountId, [], inputObj)
  // }
  // if (
  //   nodeInputs.value.platformOperationId !== inputObj.platformOperationId &&
  //   inputObj.platformOperationId
  // ) {
  //   const [variableResponse] = await Promise.all([
  //     getOperationVariableServices(
  //       inputObj.platformId,
  //       inputObj.platformOperationId,
  //       AUTOMATION_VARIABLE_DIRECTION.INPUT,
  //       props.selectedNode.id
  //     )
  //   ])
  //   addExtraInputs(inputObj.connectedAccountId, variableResponse.data, inputObj)
  // }

  // update connected account picture on every change
  const account = connectedAccounts.value?.find(
    acc => acc._id === inputObj.connectedAccountId
  )

  platformInputs.value = platformInputs.value.map(input => {
    const updatedInput = { ...input, texauVariables: account?.variables }

    if (input.name === 'connectedAccountId') {
      updatedInput.leftImageUrl = account?.platform?.picture
    } else if (input.name === 'messageContent') {
      updatedInput.isPremium = account?.isPremium
    }

    return updatedInput
  })
  loadingInputs.value = false
}

const getOperationOptions = () => {
  return operations.value.map(operation => ({
    value: operation.platformOperationId,
    label: operation.name
  }))
}

const mergeAndRemoveDuplicates = (operation, operations) => {
  const merged = [operation].concat(operations)
  return [...new Map(merged.map(o => [o.platformOperationId, o])).values()]
}

// look for dynamic inputs from backend
const checkForDynamicInputs = async () => {
  loadingDynamicInputs.value = true
  const { data } = await getOperationVariableServices(
    props.selectedNode.automationData.platformId,
    props.selectedNode.automationData.platformOperationId,
    AUTOMATION_VARIABLE_DIRECTION.INPUT,
    props.selectedNode.id
  )
  // keep extra inputs only and remove all the operation inputs.
  const newPlatformInputs = platformInputs.value.filter(({ name }) =>
    [
      'connectedAccountId',
      'platformId',
      'platformOperationId',
      'txOpenAiAccountId'
    ].includes(name)
  )

  if (
    props.selectedNode.automationData.platformOperationId ===
    constants.googleOutputPlatformOperationId
  ) {
    data.push({
      name: 'columnMapping',
      type: AUTOMATION_VARIABLE_TYPES.OUTPUT_CHECKBOX,
      label: 'Select Output',
      isRequired: false,
      previousNodes: previousNodes.value.sort((a, b) => a.order - b.order),
      hasDependencyOnFields: ['googleSheetName']
    })
  }

  // merge latest inputs from backend
  platformInputs.value = newPlatformInputs.concat(...data)
  loadingDynamicInputs.value = false
}

// trigger inputs fetch and save on blur
const onInputBlur = async (inputs, validationResult, updatedField) => {
  emit(
    'error-message',
    props.selectedNode.id,
    Object.values(validationResult.errors)
  )
  if (validationResult.valid) {
    const fields = store.getters['workflow/getInputs'](
      props.selectedNode.automationData.platformOperationId
    ).map(i => i.name)

    nodeInputs.value[updatedField.name] = inputs[updatedField.name]
    let inputsToSave = Object.assign({}, inputs)

    if (
      inputsToSave.googleSpreadsheetId &&
      store._state.data.automationStore.automationInputs?.spreadSheetName
    ) {
      inputsToSave.spreadSheetName =
        store._state.data.automationStore.automationInputs?.spreadSheetName
    }

    await saveNodeInputs(inputsToSave)
    if (fields.includes(updatedField.name)) {
      await checkForDynamicInputs()
    }
  }
}

// save the node details if validation succeeds.
const saveNodeInputs = async inputs => {
  saving.value = true
  try {
    const nodeInputs = { ...inputs }
    const connectedAccountId = nodeInputs.connectedAccountId
    const platformId = nodeInputs.platformId
    const platformOperationId = nodeInputs.platformOperationId
    let dynamicOutputs
    if (platformOperationId === constants.googlePlatformOperationId) {
      const headersResponse = await getGoogleHeaders(
        connectedAccountId,
        inputs.googleSpreadsheetId,
        inputs.googleSheetId
      )
      if (headersResponse.success === false) {
        errorMessage.value['googleSheetId'] = headersResponse.message
        throw new Error(headersResponse.message)
      } else {
        errorMessage.value['googleSheetId'] = undefined
      }
      dynamicOutputs = headersResponse.data
      await store.dispatch('workflow/setDynamicOutputs', {
        nodeId: props.selectedNode.id,
        dynamicOutputs
      })
    }
    delete nodeInputs.connectedAccountId
    delete nodeInputs.platformId
    delete nodeInputs.platformOperationId
    const update = {
      id: props.selectedNode.id,
      inputs: nodeInputs,
      isConfigured: true,
      dynamicOutputs,
      connectedAccountId,
      platformId,
      platformOperationId,
      label: operations.value.find(
        o => o.platformOperationId === platformOperationId
      ).shortName,
      platformIconUrl: platformDetails.value.logoUrl
    }
    emit('clear-configure-message', props.selectedNode.id)
    if (props.desktopDataSelected && props.desktopDataSelected._id) {
      update.desktopId = props.desktopDataSelected._id
    }
    await updateWorkflowNode(route.params.id, props.selectedNode.id, update)
    updateNodeAutomation(update, nodes)
  } catch (error) {
    emit('error', 'Error occurred while saving changes.')
  }
  saving.value = false
}

const saveCsvInputs = async ({ inputs, dynamicOutputs }) => {
  saving.value = true
  try {
    const platformId = nodeInputs.value.platformId
    const platformOperationId = nodeInputs.value.platformOperationId
    const update = {
      id: props.selectedNode.id,
      inputs,
      platformId,
      platformOperationId,
      label: operations.value.find(
        o => o.platformOperationId === platformOperationId
      ).shortName,
      platformIconUrl: platformDetails.value.logoUrl,
      dynamicOutputs,
      isConfigured: true
    }
    emit('clear-configure-message', props.selectedNode.id)
    await Promise.all([
      updateWorkflowNode(route.params.id, props.selectedNode.id, update),
      store.dispatch('workflow/setDynamicOutputs', {
        nodeId: props.selectedNode.id,
        dynamicOutputs
      })
    ])
    updateNodeAutomation(update, nodes)
  } catch (error) {
    console.log(error)
    emit('error', 'Error occurred while saving changes.')
  }
  saving.value = false
}

const addOauthAccount = async appName => {
  loadingInputs.value = true
  const response = await startOauthConnection(appName)
  loadingInputs.value = false
  if (response['success']) {
    window.open(response.data.url, '_blank')
    emit('input-modal-close')
  } else {
    emit('error', 'Error in adding new google account')
  }
}
</script>
