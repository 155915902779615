<template>
  <div
    class="fixed left-0 top-0 z-40 flex h-screen w-screen items-center justify-center"
  >
    <div
      class="absolute left-0 top-0 h-screen w-screen bg-gray-400 bg-opacity-40"
      @click="$emit('modal-close')"
    />
    <div
      class="z-10 flex w-[720px] flex-col rounded-[10px] bg-white px-8 py-10"
    >
      <div class="relative mb-9">
        <nav class="flex justify-center space-x-4" aria-label="Tabs">
          <a
            href="#"
            title=""
            :class="[{ 'bg-gray-100': tab === 'scheduling' }]"
            class="rounded-lg px-3 py-2 text-sm font-medium text-gray-500 transition-all duration-200 hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50"
            aria-current="page"
            @click="onTabClick('scheduling')"
          >
            Scheduling
          </a>
          <a
            v-if="props.firstNode?.label === 'Sheet Input'"
            href="#"
            title=""
            :class="[{ 'bg-gray-100': tab === 'watchRow' }]"
            class="rounded-lg px-3 py-2 text-sm font-medium text-gray-500 transition-all duration-200 hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50"
            aria-current="page"
            @click="onTabClick('watchRow')"
          >
            Watch Row
          </a>
        </nav>
        <button
          type="button"
          class="absolute right-0 top-0 h-9 w-9 rounded-lg border border-gray-200 bg-white p-1.5 text-gray-600 transition-all duration-200 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50 dark:focus:ring-offset-gray-900"
          @click="$emit('modal-close')"
        >
          <span class="sr-only"> Close </span>
          <SvgIcon name="close" class="m-auto" />
        </button>
      </div>
      <div class="flex flex-col h-full">
        <Spinner v-if="loadingInputs" class="mx-auto" />

        <div
          v-if="
            (props.firstNode?.label === 'Sheet Input' ||
              props.firstNode?.label === 'Get data from csv') &&
            tab === 'scheduling'
          "
          class="mb-2"
        >
          <div class="rounded-md bg-blue-50 p-4 mb-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <svg
                  class="h-5 w-5 text-blue-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="ml-3">
                <p class="text-sm text-blue-700">
                  You've used
                  {{
                    props.firstNode?.label === 'Sheet Input'
                      ? 'google sheet'
                      : 'CSV'
                  }}
                  as input for workflow so we'll be automatically detecting the
                  no. of rows to be processed everyday depending on daily limits
                  and then
                  <span class="font-semibold">we'll schedule it</span> from our
                  side to run on each day until it process all the rows of
                  sheet/CSV.
                </p>
              </div>
            </div>
          </div>
          <Button
            text="Advanced Settings"
            :color="advancedButtonClicked ? 'primary' : 'tertiary'"
            size="small"
            leftIcon="plus"
            @click="advancedButtonClicked = !advancedButtonClicked"
          />
        </div>
        <div
          v-if="showModal && tab === 'scheduling'"
          class="flex flex-col flex-grow"
        >
          <Scheduling
            v-if="!loadingInputs"
            :inputs="inputs"
            :option="option"
            :trigger-validation="triggerValidation"
            @input-update="updateInputs"
            @option-update="updateOption"
            @validationSuccess="saveInputs"
            @validationFailed="triggerValidation = false"
          />

          <div class="mt-auto pt-4">
            <Button
              v-if="!loadingInputs"
              @click="triggerValidation = true"
              class="self-start"
            >
              Save
              <Spinner v-show="saving" size="small" class="ml-1 text-white" />
            </Button>
          </div>
        </div>

        <div v-if="tab === 'watchRow'" class="flex flex-col flex-grow">
          <div class="rounded-md bg-blue-50 p-4 mb-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <svg
                  class="h-5 w-5 text-blue-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="ml-3">
                <p class="text-sm text-blue-700">
                  In this mode, whenever we found a new row in your sheet we
                  will run the workflow as per your time interval
                </p>
              </div>
            </div>
          </div>
          <Scheduling
            v-if="!loadingInputs"
            :inputs="inputs"
            :option="option"
            :trigger-validation="triggerWatchRowValidation"
            :watch-row-toggle="true"
            @input-update="updateInputs"
            @option-update="updateOption"
            @validationSuccess="saveInputs"
            @validationFailed="triggerWatchRowValidation = false"
          />

          <div class="mt-auto pt-4">
            <Button
              v-if="!loadingInputs"
              @click="triggerWatchRowValidation = true"
              class="self-start"
            >
              Save
              <Spinner v-show="saving" size="small" class="ml-1 text-white" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { updateWorkflow } from '@/apis/workflows'
import Button from '@/components/Button.vue'
import Scheduling from '@/components/scheduling/config.vue'
import { convertToProperFormat } from '@/components/scheduling/utility'
import Spinner from '@/components/Spinner.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { amplitudeTrackEvent } from '@/common/functions/eventTracker'

/**
 * @typedef {Object} WorkflowProps
 * @property {Object} workflow - The workflow configuration object
 * @property {Object} firstNode - The first node in the workflow
 */

/**
 * @type {import('vue').PropType<WorkflowProps>}
 */
const props = defineProps({
  workflow: { type: Object, required: true },
  firstNode: { type: Object, required: true }
})

const emit = defineEmits(['success', 'error', 'modal-close'])

// State management using refs
const inputs = ref(props.workflow?.schedule?.rawData || {})
const option = ref(props.workflow?.schedule?.option || 0)
const saving = ref(false)
const triggerValidation = ref(false)
const triggerWatchRowValidation = ref(false)
const advancedButtonClicked = ref(false)
const watchRowToggle = ref(props.workflow?.isWatchRowEnabled || false)
const tab = ref('scheduling')
const route = useRoute()

// Computed properties
const showModal = computed(() => {
  const isSheetOrCsvInput = ['Sheet Input', 'Get data from csv'].includes(
    props.firstNode?.label
  )
  return !isSheetOrCsvInput || advancedButtonClicked.value
})

/**
 * Initialize component state on mount
 */
onMounted(() => {
  if (
    props.workflow?.schedule &&
    Object.keys(props.workflow.schedule).length > 0
  ) {
    advancedButtonClicked.value = true
  }

  if (props.workflow.isWatchRowEnabled) {
    watchRowToggle.value = true
    tab.value = 'watchRow'
  }
})

/**
 * Updates the scheduling inputs and handles end date logic
 * @param {Object} updatedInputs - The new input values
 */
const updateInputs = updatedInputs => {
  inputs.value = updatedInputs
  if (inputs.value?.endDate) {
    const date = new Date(inputs.value.endDate)
    const today = new Date()

    const isSameDay =
      date.getUTCFullYear() === today.getUTCFullYear() &&
      date.getUTCMonth() === today.getUTCMonth() &&
      date.getUTCDate() === today.getUTCDate()

    if (isSameDay) {
      const newEndDate = new Date()
      newEndDate.setHours(23, 59, 59, 0)
      inputs.value = {
        ...updatedInputs,
        endDate: newEndDate.toISOString()
      }
    }
  }
}

/**
 * Updates the schedule option
 * @param {number} newOption - The new option value
 */
const updateOption = newOption => {
  option.value = newOption
}

/**
 * Handles tab switching and resets relevant state
 * @param {string} newTab - The tab to switch to
 */
const onTabClick = newTab => {
  const isWatchRow = newTab === 'watchRow'
  const defaultState = {
    option: 0,
    inputs: {}
  }

  const defaultStateWatchRow = {
    option: 1,
    inputs: {
      minutes: 15,
      startTime: undefined,
      endTime: undefined,
      startDate: undefined,
      endDate: new Date(Date.now() + 5 * 24 * 60 * 60 * 1000).toISOString()
    }
  }

  if (isWatchRow) {
    if (!props.workflow.isWatchRowEnabled) {
      option.value = defaultStateWatchRow.option
      inputs.value = defaultStateWatchRow.inputs
    } else {
      option.value =
        props.workflow?.schedule?.option || defaultStateWatchRow.option
      inputs.value =
        props.workflow?.schedule?.rawData || defaultStateWatchRow.inputs
    }
    watchRowToggle.value = true
  } else {
    if (props.workflow.isWatchRowEnabled) {
      advancedButtonClicked.value = false
      option.value = defaultState.option
      inputs.value = defaultState.inputs
    } else {
      option.value = props.workflow?.schedule?.option || defaultState.option
      inputs.value = props.workflow?.schedule?.rawData || defaultState.inputs
    }
    watchRowToggle.value = false
  }

  tab.value = newTab
}

/**
 * Saves the workflow schedule configuration
 * @returns {Promise<void>}
 */
const saveInputs = async () => {
  //check if end date is earlier than start date
  if (new Date(inputs.value.endDate) < new Date(inputs.value.startDate)) {
    triggerWatchRowValidation.value = false
    emit('error', 'End date cannot be earlier than start date')
    return
  }

  if (watchRowToggle.value && (!option.value || option.value === '')) {
    triggerWatchRowValidation.value = false
    emit('error', 'Please select a valid schedule option')
    return
  }

  saving.value = true
  try {
    const optionVal = option.value || 0
    const timezone = Intl?.DateTimeFormat().resolvedOptions().timeZone

    const scheduleData = {
      ...convertToProperFormat(inputs.value, optionVal),
      startDate: inputs.value.startDate,
      endDate: inputs.value.endDate,
      startTime: inputs.value.startTime,
      endTime: inputs.value.endTime,
      option: optionVal,
      timezone,
      rawData: inputs.value
    }

    const workflowUpdate = {
      schedule: scheduleData,
      isScheduled: optionVal !== 0 && !watchRowToggle.value,
      isActive: false,
      isWatchRowEnabled: watchRowToggle.value
    }

    // Track event
    amplitudeTrackEvent('Workflow Schedule', localStorage.getItem('email'), {
      schedule: scheduleData,
      workflowId: route.params.id
    })

    await updateWorkflow(route.params.id, workflowUpdate)

    const successMessage = watchRowToggle.value
      ? 'Watch Row Updated Successfully'
      : 'Schedule updated successfully'
    emit('success', successMessage)
  } catch (error) {
    emit(
      'error',
      'Failed to add or update the schedule. Please try again in some time.'
    )
  } finally {
    saving.value = false
    triggerValidation.value = false
    triggerWatchRowValidation.value = false
  }
}
</script>
