<template>
  <div
    class="fixed left-0 top-0 z-40 flex h-screen w-screen items-center justify-center"
  >
    <div
      class="absolute left-0 top-0 h-screen w-screen bg-gray-400 bg-opacity-40"
      @click="$emit('modal-close')"
    />
    <div
      class="z-10 flex h-fit max-h-[95vh] min-h-[650px] w-fit min-w-[720px] max-w-[80vw] flex-col rounded-[10px] bg-white p-4"
    >
      <div class="relative mb-9">
        <button
          type="button"
          class="absolute right-0 top-0 h-9 w-9 rounded-lg border border-gray-200 bg-white p-1.5 text-gray-600 transition-all duration-200 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50 dark:focus:ring-offset-gray-900"
          @click="$emit('modal-close')"
        >
          <span class="sr-only"> Close </span>
          <SvgIcon name="close" class="m-auto" />
        </button>
      </div>
      <div class="flex flex-1 overflow-y-scroll">
        <Output
          :selectedNode="node"
          @success="msg => emit('success', msg)"
          @error="msg => emit('error', msg)"
          class="mt-2"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import Output from '@/components/dataStore/output.vue'

const props = defineProps({ node: String })

const emit = defineEmits(['success', 'error', 'modal-close'])
</script>
