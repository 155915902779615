<template>
  <div class="flex-1">
    <div class="grid h-full w-full items-start justify-center">
      <div class="h-full px-6 py-16 sm:px-12 sm:py-20 lg:px-20">
        <div class="flex h-full max-w-lg flex-col">
          <a class="" href="#" title="TexAu">
            <CompanyLogo />
          </a>

          <div class="mt-12 grid h-full place-items-center">
            <div>
              <div class="text-center">
                <Heading text="Reset Your Password" />
              </div>

              <vee-form
                @submit="submitCreated"
                :validation-schema="schema"
                class="flex mt-2 flex-col gap-5"
              >
                <div>
                  <Input
                    label="Registered Email"
                    labelClass="block text-sm font-medium text-gray-900 dark:text-gray-50"
                    type="email"
                    name="email"
                    rightIcon="lock"
                    :text="emailValue"
                    :disabled="true"
                  >
                  </Input>
                  <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
                    ** For changing email address, please contact<span
                      class="inline"
                      ><a
                        href="#"
                        title=""
                        class="text-sm font-semibold text-blue-600 hover:underline dark:text-blue-400"
                      >
                        support</a
                      >.</span
                    >
                  </p>
                </div>

                <div>
                  <Input
                    label=" Enter New Password"
                    labelClass="block text-sm font-medium text-gray-900 dark:text-gray-50"
                    text="Your new password"
                    name="New Password"
                    :type="newPasswordFieldType"
                    :rightIcon="
                      newPasswordFieldType === 'text' ? 'eye-off' : 'eye'
                    "
                    rightIconMouse="cursor-pointer"
                    @onRightIconClick="onPasswordRightIconClick('new')"
                  />
                  <p class="mt-2 text-sm text-red-600">
                    <ErrorMessage name="New Password" />
                  </p>
                </div>

                <div>
                  <Input
                    label="Confirm New Password"
                    labelClass="block text-sm font-medium text-gray-900 dark:text-gray-50"
                    text="Re-enter new password"
                    name="Confirm New Password"
                    :type="confirmNewPasswordFieldType"
                    :rightIcon="
                      confirmNewPasswordFieldType === 'text' ? 'eye-off' : 'eye'
                    "
                    rightIconMouse="cursor-pointer"
                    @onRightIconClick="onPasswordRightIconClick('confirm')"
                  />
                  <p class="mt-2 text-sm text-red-600">
                    <ErrorMessage name="Confirm New Password" />
                  </p>
                </div>

                <div>
                  <Button type="submit" text="Save" :showLoader="isLoading" />
                </div>
              </vee-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SecurityHeader from '@/components/settings/securityHeader.vue'
import Input from '@/components/Input.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Button from '@/components/Button.vue'
import UserProfile from '@/common/userWrapper'
import Heading from '../onboarding/Heading.vue'
import CompanyLogo from '../onboarding/CompanyLogo.vue'

//API call
import { forceSetPassword } from '@/apis/settings/settings-security-change-password'

export default {
  name: 'forcePassworReset',
  components: {
    SecurityHeader,
    Input,
    SvgIcon,
    Button,
    Heading,
    CompanyLogo
  },
  props: {
    challengeData: {}
  },
  data() {
    return {
      schema: {
        email: '',

        'New Password': 'required|password_min:12|password_no_spaces',
        'Confirm New Password':
          'required|password_mismatch:@New Password|password_min:12'
      },
      emailValue: '',
      isLoading: false,
      currentPasswordFieldType: 'password',
      newPasswordFieldType: 'password',
      confirmNewPasswordFieldType: 'password'
    }
  },
  created() {
    this.emailValue = this.challengeData.email
  },
  methods: {
    async submitCreated(values) {
      try {
        const data = {
          email: this.challengeData.email,
          session: this.challengeData.session,
          newPassword: values['New Password']
        }

        this.isLoading = true

        const response = await forceSetPassword(data)
        if (response['success']) {
          this.$emit('success', 'password reset successfully')
          this.$emit('backToLogin')
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('error', error)
      }
      this.isLoading = false
    },

    onPasswordRightIconClick(type) {
      if (type === 'current') {
        this.currentPasswordFieldType =
          this.currentPasswordFieldType === 'password' ? 'text' : 'password'
      } else if (type === 'new') {
        this.newPasswordFieldType =
          this.newPasswordFieldType === 'password' ? 'text' : 'password'
      } else if (type === 'confirm') {
        this.confirmNewPasswordFieldType =
          this.confirmNewPasswordFieldType === 'password' ? 'text' : 'password'
      } else {
        return
      }
    }
  }
}
</script>

<style scoped></style>
