<template>
  <div class="mt-4 text-center">
    <label
      v-if="this.timeLeftForResend >= 0 && showTimer && otpRequestCount < maxOtpRequests"
      class="block text-sm font-medium text-gray-900 dark:text-gray-50"
      for=""
    >
      Resend code in <span id="timer">{{ timeLeftForResend }}</span> second
    </label>

    <label
      v-if="this.timeLeftForResend < 0 && otpRequestCount < maxOtpRequests"
      class="block cursor-pointer text-sm font-medium text-blue-900 dark:text-blue-400"
      for=""
      @click="sendPhoneOtp()"
    >
      Resend OTP
    </label>
    <p v-if="otpRequestCount >= maxOtpRequests" class="text-red-500 text-sm">
      You have requested OTP too many times. Please wait 10 minutes before trying again.
    </p>
  </div>
  <div
    v-if="isResending"
    class="mt-1 flex w-full flex-col items-center justify-center space-y-4"
  >
    <Spinner size="small" />
  </div>
</template>

<script>
import Spinner from '@/components/Spinner.vue'
import { resendMailOtp, resendPhoneOtp, forgotPassword } from '@/apis/onboarding'
import { mapGetters, mapActions } from 'vuex'
import { constants } from '@/common/constants'
 
export default {
  name: 'ResendOtpCounter',

  components: {
    Spinner
  },

  props: {
    email: { type: String, required: true }  
  },

  mounted() {
    this.loadRecaptcha()
    this.showTimer = true
    this.calculateTimeLeft()
    this.loadOtpRequestState()
  },

  data() {
    return {
      timeLeftForResend: 60,
      isResending: false,
      showTimer: false,
      otpRequestCount: 0,
      maxOtpRequests: 3,
      resetTime: 10 * 60 * 1000, 
      lastRequestTime: null,
      isRecaptchaLoaded: false, 
      recaptchaInstance: null,
      lastSentMethod: null,
    }
  },

  computed: {
    ...mapGetters('registration', ['getRegistrationData'])
  },

  beforeDestroy() {
    clearInterval(this.timerInterval)
  },

  methods: {
    async sendPhoneOtp(){
      try{
        if (this.otpRequestCount >= this.maxOtpRequests) {
          this.$emit('error', 'OTP request limit reached. Please wait.')
          return
        }

        // Execute reCAPTCHA and get token
        if (!this.recaptchaInstance) {
          throw new Error('reCAPTCHA not initialized')
        }

        const token = await this.recaptchaInstance.execute(
          constants.recaptchaSiteKey,
          {
            action: 'submit'
          }
        )
        
          this.isResending = true
          let response
          response = await forgotPassword(this.email,token)
          
        
          if (response['success']) {
            const otpSentVia=response.data.AttributeName;
            localStorage.setItem('otpSentVia',otpSentVia)
            this.otpRequestCount++;
            this.lastRequestTime = new Date().getTime();
            localStorage.setItem('otpRequestCount', this.otpRequestCount);
            localStorage.setItem('lastRequestTime', this.lastRequestTime);
            this.$emit('success', 'OTP resend successful')
            this.showTimer = true
            this.timeLeftForResend = 60
            this.calculateTimeLeft()
            this.$emit('stopAPI', true)

            this.$emit('success', 'OTP resent successfully')
          } else {
            this.$emit('error', response.message || 'Failed to resend OTP')
          }
        }catch (error) {
          // Handle unexpected errors
          this.$emit(
            'error',
            'An unexpected error occurred. Please try again later.'
          )
        } finally {
          // Ensure loading state is reset in all cases
          this.isResending = false
          this.isLoading = false
        }
    },
    calculateTimeLeft() {
      let downloadTimer = setInterval(() => {
        if (this.timeLeftForResend <= 0) {
          this.$emit('stopAPI', false)
          clearInterval(downloadTimer)
        }
        this.timeLeftForResend -= 1
      }, 1000)
    },


    loadRecaptcha() {
      if (typeof window === 'undefined' || (window.grecaptcha && this.isRecaptchaLoaded && this.recaptchaInstance)) return

      const script = document.createElement('script')
      script.src = `https://www.google.com/recaptcha/api.js?render=${constants.recaptchaSiteKey}`
      script.async = true
      script.defer = true

      script.onload = () => {
        this.isRecaptchaLoaded = true
        window.grecaptcha.ready(() => {
          this.recaptchaInstance = window.grecaptcha
        })
      }

      script.onerror = error => {
        this.$emit('error', 'Failed to load reCAPTCHA')
      }

      document.head.appendChild(script) 
    },
    loadOtpRequestState() {
      this.otpRequestCount = parseInt(localStorage.getItem('otpRequestCount')) || 0
      this.lastRequestTime = parseInt(localStorage.getItem('lastRequestTime'))
      if (this.lastRequestTime) {
          const timeSinceLastRequest = Date.now() - this.lastRequestTime

          if (timeSinceLastRequest >= this.resetTime) {
            this.otpRequestCount = 0
            localStorage.removeItem('otpRequestCount')
            localStorage.removeItem('lastRequestTime')
          } else {
            this.timeLeftForResend = Math.max(0, 60 - Math.floor(timeSinceLastRequest / 1000))
          }
        }
    },
  },
  
}
</script>

<style scoped></style>
