<template>
  <div
    class="absolute left-[50%] top-2 z-30 hidden translate-x-[-50%] rounded-lg bg-blue-50 dark:bg-blue-900/50"
    id="texau-v2-extension-sync-status"
  >
    <div class="p-2">
      <div class="flex items-start justify-between">
        <SvgIcon class="h-5 w-5 text-blue-600" name="info" />

        <p
          class="ml-3 mr-auto flex-1 text-sm font-normal text-gray-900 dark:text-gray-50"
        >
          Syncing...
        </p>
      </div>
    </div>
  </div>
  <div
    class="absolute left-[50%] top-2 z-30 hidden translate-x-[-50%] rounded-lg bg-green-50 dark:bg-green-900/50"
    id="texau-v2-extension-sync-result-success"
  >
    <div class="p-2">
      <div class="flex items-start justify-between">
        <SvgIcon class="h-5 w-5 text-green-600" name="info" />

        <p
          class="ml-3 mr-1 flex-1 text-sm font-normal text-gray-900 dark:text-gray-50"
        >
          Account synced successfully!
        </p>
      </div>
    </div>
  </div>
  <div
    class="absolute left-[50%] top-2 z-30 hidden translate-x-[-50%] rounded-lg bg-red-50 dark:bg-red-900/50"
    id="texau-v2-extension-sync-result-fail"
  >
    <div class="p-2">
      <div class="flex items-start justify-between">
        <SvgIcon class="h-5 w-5 text-red-600" name="info" />

        <p
          class="ml-3 mr-auto flex-1 text-sm font-normal text-gray-900 dark:text-gray-50"
        >
          Error occured while syncing, please try again after sometime!
        </p>
      </div>
    </div>
  </div>

  <AutomationHeader title="Accounts" />

  <main
    class="flex min-h-0 min-w-0 flex-1 border-t border-gray-200 dark:border-gray-700"
  >
    <ConnectNewAccountModal
      v-if="modalActive"
      :integrationData="integrationData"
      @close="modalActive = false"
      @goBack="goBackToAccounts"
      @updateNotificationApiResponse="proxyChangeResponse"
    />

    <component
      :is="currentComp"
      @showTable="currentComp = 'accountTable'"
      @showConnect="showConnect"
      @proxyChangeResponse="proxyChangeResponse"
      @error="e => $emit('error', e)"
      @success="e => $emit('success', e)"
      @onAddNewClick="onAddNewClick"
    ></component>
  </main>
</template>
<script>
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import SettingsMenu from '@/components/settings/settingsMenu.vue'
import ViewPlatforms from '@/components/settings/WorkspaceManagerCloud/ViewPlatforms.vue'
import workspaceManagerCloud from '@/views/settings/settings-workspace/workspaceManagerCloud.vue'
import ConnectNewAccountModal from '@/components/settings/integration/connectNewAccountModal.vue'
export default {
  name: 'WorkspaceMain',
  components: {
    AutomationHeader,
    SettingsMenu,
    accountTable: workspaceManagerCloud,
    platforms: ViewPlatforms,
    ConnectNewAccountModal
  },
  data() {
    return {
      currentComp: localStorage.getItem('fromOnboarding') === 'true' ? 'platforms' : 'accountTable',
      integrationData: null,
      modalActive: false
    }
  },
  methods: {
    onAddNewClick() {
      this.currentComp = 'platforms'
    },
    showConnect(data) {
      this.integrationData = data
      this.modalActive = true
    },
    proxyChangeResponse(data) {
      if (data.type === 'success') {
        this.$emit('success', data.msg || data.value)
      } else {
        this.$emit('error', data.msg || data.value)
      }
    },
    goBackToAccounts() {
      this.modalActive = false
      this.currentComp = 'accountTable'
    }
  }
}
</script>
