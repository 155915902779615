<template>
  <vee-form
    @submit="submitCreated"
    :validation-schema="schema"
    class="mx-auto mt-8 grid max-w-xl grid-cols-1 gap-y-5"
  >
    <div>
      <Input
        label="Registered Email"
        labelClass="block text-sm font-medium text-gray-900 dark:text-gray-50"
        type="email"
        name="email"
        rightIcon="lock"
        :text="emailValue"
        :disabled="true"
      >
      </Input>
      <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
        ** For changing email address, please contact<span class="inline"
          ><a
            href="#"
            title=""
            @click.prevent="openIntercom"
            class="text-sm font-semibold text-blue-600 hover:underline dark:text-blue-400"
          >
            support</a
          >.</span
        >
      </p>
    </div>

    <div>
      <Input
        label="Enter Current Password"
        labelClass="block text-sm font-medium text-gray-900 dark:text-gray-50"
        text="Your Current Password"
        name="Current Password"
        :type="currentPasswordFieldType"
        :rightIcon="currentPasswordFieldType === 'text' ? 'eye-off' : 'eye'"
        rightIconMouse="cursor-pointer"
        @onRightIconClick="onPasswordRightIconClick('current')"
      />
      <p class="mt-2 text-sm text-red-600">
        <ErrorMessage name="Current Password" />
      </p>
    </div>

    <div>
      <Input
        label=" Enter New Password"
        labelClass="block text-sm font-medium text-gray-900 dark:text-gray-50"
        text="Your new password"
        name="New Password"
        :type="newPasswordFieldType"
        :rightIcon="newPasswordFieldType === 'text' ? 'eye-off' : 'eye'"
        rightIconMouse="cursor-pointer"
        @onRightIconClick="onPasswordRightIconClick('new')"
      />
      <p class="mt-2 text-sm text-red-600">
        <ErrorMessage name="New Password" />
      </p>
    </div>

    <div>
      <Input
        label="Confirm New Password"
        labelClass="block text-sm font-medium text-gray-900 dark:text-gray-50"
        text="Re-enter new password"
        name="Confirm New Password"
        :type="confirmNewPasswordFieldType"
        :rightIcon="confirmNewPasswordFieldType === 'text' ? 'eye-off' : 'eye'"
        rightIconMouse="cursor-pointer"
        @onRightIconClick="onPasswordRightIconClick('confirm')"
      />
      <p class="mt-2 text-sm text-red-600">
        <ErrorMessage name="Confirm New Password" />
      </p>
    </div>

    <div>
      <Button type="submit" text="Save" :showLoader="isLoading" />
    </div>
  </vee-form>
</template>

<script>
import SecurityHeader from '@/components/settings/securityHeader.vue'
import Input from '@/components/Input.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Button from '@/components/Button.vue'
import UserProfile from '@/common/userWrapper'
import { mapState } from 'vuex'

//API call
import {
  changePassword,
  refreshToken
} from '@/apis/settings/settings-security-change-password'

export default {
  name: 'emailAndPasswordSection',
  components: {
    SecurityHeader,
    Input,
    SvgIcon,
    Button
  },
  data() {
    return {
      schema: {
        email: '',
        'Current Password': 'required|password_min:12',
        'New Password': 'required|password_min:12|password_no_spaces',
        'Confirm New Password':
          'required|password_mismatch:@New Password|password_min:12'
      },
      emailValue: '',
      isLoading: false,
      currentPasswordFieldType: 'password',
      newPasswordFieldType: 'password',
      confirmNewPasswordFieldType: 'password'
    }
  },
  computed: {
    ...mapState('user', ['userData'])
  },
  created() {
    this.emailValue = localStorage.getItem('email')
  },
  methods: {
    async submitCreated(values) {
      if (values['Current Password'] === values['New Password']) {
        this.$emit('apiResponse', {
          type: 'error',
          message: 'New Password Cannot Be Same As Current Password'
        })
        return
      }

      const data = {
        currentPassword: values['Current Password'],
        newPassword: values['New Password']
      }

      this.isLoading = true
      try {
        let user_ = UserProfile.getUser()
        const refreshResponse = await refreshToken({
          refreshToken: user_['RefreshToken']
        })
        if (refreshResponse['success']) {
          user_['AccessToken'] = refreshResponse.data['AccessToken']
          UserProfile.setUser(user_)
          const response = await changePassword(data)
          if (response['success']) {
            this.$emit('apiResponse', {
              type: 'success',
              message: 'Password Reset Successfully'
            })
          } else {
            throw response.message
          }
        } else {
          UserProfile.clearCookie()
          window.location.reload()
        }
      } catch (error) {
        this.$emit('apiResponse', { type: 'error', message: error })
      }

      this.isLoading = false
    },

    onPasswordRightIconClick(type) {
      if (type === 'current') {
        this.currentPasswordFieldType =
          this.currentPasswordFieldType === 'password' ? 'text' : 'password'
      } else if (type === 'new') {
        this.newPasswordFieldType =
          this.newPasswordFieldType === 'password' ? 'text' : 'password'
      } else if (type === 'confirm') {
        this.confirmNewPasswordFieldType =
          this.confirmNewPasswordFieldType === 'password' ? 'text' : 'password'
      } else {
        return
      }
    },
    openIntercom() {
      const email = localStorage.getItem('email')
      const phone = this.userData.phone
      window.Intercom(
        'showNewMessage',
        `Hello team, I would like to change my email address. Can you please help? Here is my email: ${email} and phone: ${phone}`
      )
    }
  }
}
</script>

<style scoped></style>
