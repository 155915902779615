<template>
  <div v-if="message.length" class="p-4 space-y-2 max-w-2xl ">
    <p class="text-md font-semibold text-gray-800 leading-relaxed mb-4 px-1 py-2 border-l-4 border-blue-500 bg-gray-50 rounded-r-md shadow-sm">{{ message }}</p>
    <div class="mt-4 space-y-2">
      <div 
        v-for="item in result"
        :key="item.id" 
        class="p-3 rounded-lg shadow-sm border cursor-pointer"
        :class="item.isPrimary ? 'bg-blue-600 text-white hover:bg-blue-800 border-blue-700 shadow-md' : 'bg-white text-gray-600 hover:bg-gray-50 hover:shadow-md'"
        @click="navigateToPage(item.id,item.platformId)"
      >
        <span class="font-medium">{{ item.name }}</span>
      </div>
    </div>
  </div>
  <div v-if="showLoading" class="flex items-center p-4 justify-center">
    <Spinner size="medium" />
  </div>
</template>

<script>
import { useKBarMatches } from "@bytebase/vue-kbar";
import Spinner from '@/components/Spinner.vue'
import { useKBarHandler } from "@bytebase/vue-kbar";

export default {
  name: "MyResultsRenderer",
  components: {
    Spinner,
  },
  props: {
    message: { type: String, default: '' },
    result: {
      type: Array,
      default: []
    },
    showLoading:{ type:Boolean, default:false }
  },
  setup() {
    const handler = useKBarHandler();
    return { handler };
  },
  methods: {
    navigateToPage(id,platformId) {
      const url = `/automation-store/workflow/create?operationId=${encodeURIComponent(id)}&platformId=${encodeURIComponent(platformId)}`;
      this.$router.push(url)
      this.handler.hide()
      this.$emit('navigation-complete');
    }
  },
};
</script>

<style>
.cursor-pointer {
  transition: background-color 0.2s ease-in-out;
}
</style>