import { mergeAttributes, Node } from '@tiptap/core'

export const Variable = Node.create({
  name: 'variable',
  addOptions() {
    return {
      HTMLAttributes: {},
      renderLabel({ node }) {
        if (node.attrs.outputLabel) {
          return `${node.attrs.outputLabel}`
        } else {
          return `${node.attrs.outputName}`
        }
      }
    }
  },
  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,
  addAttributes() {
    return {
      nodeId: {
        default: null,
        parseHTML: element => element.getAttribute('data-node-id'),
        renderHTML: attributes => {
          if (!attributes.nodeId) {
            return {}
          }

          return {
            'data-node-id': attributes.nodeId
          }
        }
      },

      outputName: {
        default: null,
        parseHTML: element => element.getAttribute('data-output-name'),
        renderHTML: attributes => {
          if (!attributes.outputName) {
            return {}
          }

          return {
            'data-output-name': attributes.outputName
          }
        }
      },

      outputLabel: {
        default: null,
        parseHTML: element => element.getAttribute('data-output-label'),
        renderHTML: attributes => {
          if (!attributes.outputLabel) {
            return {}
          }

          return {
            'data-output-label': attributes.outputLabel
          }
        }
      },

      srcLabel: {
        default: null,
        parseHTML: element => element.getAttribute('data-src-label'),
        renderHTML: attributes => {
          if (!attributes.srcLabel) {
            return {}
          }

          return {
            'data-src-label': attributes.srcLabel
          }
        }
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`
      }
    ]
  },
  renderText({ node }) {
    if (node.attrs.nodeId === 'null' || node.attrs.nodeId === null) {
      return `{{${node.attrs.outputName}}}`
    } else {
      return `{{${node.attrs.nodeId}.${node.attrs.outputName}}}`
    }
  },
  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(
        {
          'data-type': this.name,
          class:
            node.attrs.nodeId && node.attrs.nodeId === 'texAuVariables'
              ? 'inline rounded bg-green-100 px-1 text-sm font-semibold text-green-600 dark:bg-green-900 dark:text-green-50'
              : 'inline rounded bg-blue-100 px-1 text-sm font-semibold text-blue-600 dark:bg-blue-900 dark:text-blue-50 cursor-pointer'
        },
        this.options.HTMLAttributes,
        HTMLAttributes
      ),
      this.options.renderLabel({
        options: this.options,
        node
      })
    ]
  }
})
