<template>
  <KBarProvider :actions="globalActions">
    <KBarPortal>
      <KBarPositioner class="bg-gray-800/70  z-50 flex items-center justify-center">
        <KBarAnimator
          class="bg-white shadow-2xl rounded-xl overflow-hidden w-[600px] p-4 border border-gray-200"
        >
          <!-- Header -->
          <div class="px-4 pt-5 pb-3">
            <h2 class="text-2xl font-bold text-gray-800 mb-1 flex items-center justify-center">
              ⚡Workflow Automation
            </h2>
            <p class="text-base text-gray-600 text-center max-w-md mx-auto">
              Find the perfect automation to streamline your tasks
            </p>
            <div class="w-16 h-1 bg-blue-600 mx-auto mt-3 rounded-full"></div>
          </div>
          
          <!-- Search Input -->
          <div class="relative">
            <span class="absolute inset-y-0 left-3 flex items-center text-gray-400">
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"/></svg>
            </span>
            <KBarSearch
              class="pl-10 pr-4 py-3 text-lg border border-gray-300 focus:border-blue-400 w-full rounded-lg transition-all duration-200"
              @keydown.enter.prevent="handleSearch"
              placeholder="What do you want to automate?"
            />
          </div>
          <!-- Results Renderer -->
          <MyResultsRenderer 
            :result="searchResults" 
            :message="searchMessage" 
            :showLoading="showLoading"  
            @navigation-complete="resetSearch"
            class="mt-3"
          />
        </KBarAnimator>
      </KBarPositioner>
    </KBarPortal>
  <div class="flex h-full w-full items-center justify-center" v-if="loading">
    <Spinner size="large" />
  </div>
  <layout-default
    v-else-if="checkUser() && checkOnboarded() && showLayout"
    v-slot="scope"
  >
    <AllocateBandwidthModal
      v-if="showAllocateBandwidthModal"
      :pendingActionData="pendingActionData"
      @close="showAllocateBandwidthModal = false"
      @error="scope.error"
      @success="scope.success"
    />
    <DeleteWorkspaceModal
      v-if="showDeleteWorkspaceModal"
      :pendingActionData="pendingActionData"
      @close="showDeleteWorkspaceModal = false"
      @error="scope.error"
      @success="scope.success"
    />
    <router-view
      @error="scope.error"
      @success="scope.success"
      @warning="scope.warning"
      @showAllocationModal="checkForPendingAction"
    />
  </layout-default>

  <layout-generic v-else v-slot="scope">
    <router-view
      @error="scope.error"
      @success="scope.success"
      @warning="scope.warning"
    />
  </layout-generic>
  </KBarProvider>
</template>

<script>
import { getBaseUser, getUsers, getPendingAction } from '@/apis/user'
import { getAllActivities } from '@/apis/activities'
import UserProfile from '@/common/userWrapper'
import LayoutDefault from '@/layouts/LayoutDefault.vue'
import LayoutGeneric from '@/layouts/LayoutGeneric.vue'
import { mapActions, mapState } from 'vuex'
import Spinner from './components/Spinner.vue'
import { H } from 'highlight.run'
import { getExecutionTimeLeft } from '@/common/functions/executionTimeFetch'
import AllocateBandwidthModal from '@/components/pendingActionModals/AllocateBandwidthModal.vue'
import DeleteWorkspaceModal from '@/components/pendingActionModals/DeleteWorkspaceModal.vue'
import MyResultsRenderer from "./views/MyResultsRenderer.vue";
import {
  KBarProvider,
  KBarPortal,
  KBarPositioner,
  KBarAnimator,
  KBarSearch,
  createAction,
  useRegisterActions,
} from "@bytebase/vue-kbar";
import { getAutomationSuggestion } from '@/apis/automation-store/kbar'
import { computed,ref} from 'vue'

export default {
  name: 'App',
  components: {
    LayoutDefault,
    LayoutGeneric,
    Spinner,
    AllocateBandwidthModal,
    DeleteWorkspaceModal,
    KBarProvider,
    KBarPortal,
    KBarPositioner,
    KBarAnimator,
    KBarSearch,
    MyResultsRenderer,
  },
  setup() {
    const showLoading=ref(false)
    const searchResults = ref([]);
    const searchMessage=ref('')
    const globalActions = [
      createAction({
        id: "Automation Store",
        name: "Go to Automation Store",
        perform: () => (window.location.pathname = "/automation-store?platform=LinkedIn"),
      }),
    ];

    return {
      globalActions,  
      searchResults,
      searchMessage,
      showLoading,
    };
  },
  async created() {
    if (UserProfile.getUser()) {
      await this.getBaseUser()
    }

    // TODO: will be needed when adding dark mode

    // if (!localStorage.getItem('theme')) {
    //   if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
    //     localStorage.setItem('theme', 'dark')
    //     document.documentElement.classList.add('dark')
    //   } else {
    //     localStorage.setItem('theme', 'light')
    //     document.documentElement.classList.remove('dark')
    //   }
    // } else {
    //   if (localStorage.getItem('theme') === 'dark') {
    //     document.documentElement.classList.add('dark')
    //   } else {
    //     document.documentElement.classList.remove('dark')
    //   }
    // }

    //check if user data(auth user) is available or not
    if (Object.keys(this.userData).length > 0) {
      H.identify(this.userData.email, {
        firstName: this.userData.firstname,
        lastName: this.userData.lastname,
        organisationId: window.$cookies.get('organisationId'),
        orgUserId: window.$cookies.get('orgUserId'),
        workspaceId: window.$cookies.get('workspaceId')
      })

      window.clarity(
        'identify',
        this.userData.email,
        '',
        this.$route.params?.id
      )
    } else {
      try {
        //set ip address as identifier
        const response = await fetch('https://api.ipify.org?format=json')
        const data = await response.json()
        H.identify(data.ip, {})
      } catch (error) {
        console.error('Error:', error)
      }
    }

    this.loading = false

    this.checkForPendingAction()
  },

  data() {
    return {
      loading: true,
      showAllocateBandwidthModal: false,
      pendingActionData: null,
      showDeleteWorkspaceModal: false,
    }
  },

  computed: {
    showLayout() {
      return this.$route.meta.layout !== false
    },
    ...mapState('user', ['userData'])
  },
  methods: {
    ...mapActions('user', ['saveUserData']),
    ...mapActions('user', ['saveNotificationNumber']),
    ...mapActions('user', ['saveWorkspaceData']),
    resetSearch(){
      this.searchMessage = '';
      this.searchResults = [];
    },
    async handleSearch(event){
      try {
        this.showLoading=true
        this.searchResults = [];
        this.searchMessage = '';
        const query = event.target.value
        if (!query) {
          this.searchMessage = "Please enter a valid search query.";
          return;
        }
        const response = await getAutomationSuggestion({
          query,
          firstName: this.userData.firstname,
          lastName: this.userData.lastname,
          intent: "automation"
        });

        const inputs = response.apiResponse?.inputs;
        const otherRecommendations = response.apiResponse?.otherRecommendedAutomations || [];

        this.searchResults = inputs
          ? [
              { 
                id: inputs.automationId, 
                name: inputs.automationName, 
                platformId: inputs.platformId,
                isPrimary:true
              },
              ...otherRecommendations.map(({ automationId, automationName, platformId }) => ({
                id: automationId,
                name: automationName,
                platformId,
                isPrimary:false
              }))
            ]
          : [];

        this.searchMessage = response.apiResponse?.text || response.apiResponse?.message?.text || response.error || "No results found.";
      }catch (error) {
        console.error("Error fetching automation suggestions:", error);
        this.searchMessage = error.message || "An error occurred while fetching suggestions. Please try again.";
      } finally {
        this.showLoading = false;
      }
    },
    async getBaseUser() {
      const [baseUserResponse, activityResponse, userResponse] =
        await Promise.all([
          getBaseUser(),
          getAllActivities(),
          getUsers(),
          getExecutionTimeLeft()
        ])
      if (baseUserResponse['success']) {
        this.saveUserData({ payload: baseUserResponse.data })
      } else {
        return 'Unable to fetch your account data.'
      }
      if (activityResponse['success']) {
        this.saveNotificationNumber({ payload: activityResponse.total })
      }
      if (userResponse['success']) {
        this.saveWorkspaceData({ payload: userResponse.data })
      }
    },

    checkUser() {
      return !!UserProfile.getUser()
    },
    checkOnboarded() {
      const user = UserProfile.getUser()
      return user.isOnboarded
    },

    /**
     * Checks for any pending actions related to bandwidth allocation and updates the component's state.
     * If a relevant pending action is found, it sets `pendingActionData` with the action details
     * and displays the pending action modal.
     *
     * @async
     * @function checkForPendingAction
     * @returns {Promise<void>} Resolves without returning a value.
     * @throws Will log an error if the API call to retrieve pending actions fails.
     */
    async checkForPendingAction() {
      try {
        const response = await getPendingAction()
        if (response.data.length === 0) return
        if (response.data[0].actionStatus === 'todo') {
          switch (response.data[0].actionType) {
            case 'allocate-bandwidth':
            case 're-allocate-bandwidth':
              this.pendingActionData = response.data[0]
              this.showAllocateBandwidthModal = true
              break
            case 'delete-workspaces':
              this.pendingActionData = response.data[0]
              this.showDeleteWorkspaceModal = true
              break
            default:
              break
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
