import {
  addWorkflowNode,
  updateWorkflow,
  updateWorkflowNode
} from '@/apis/workflows'
import {
  GRID_SIZE,
  INPUT_UTILITY_REGEX,
  NEXT_NODE_CONNECTION_TYPE,
  NODE_SIZES,
  NODE_SIZE_DIFFERENCE,
  NODE_TYPE_NAMES,
  TAG_REGEX,
  UTILITY_TYPES,
  VARIABLE_REGEX,
  constants
} from '@/common/constants'
import { store } from '@/store/store'
import { Position } from '@vue-flow/core'
import ObjectID from 'bson-objectid'
import { utilities } from './utilities'

const directions = {
  ...Position,
  Top_Right: Position.Top + Position.Right,
  Bottom_Right: Position.Bottom + Position.Right
}

// this is a map of node type with an array
// where index is number of outgoing edges and
// value is an object with new node properties
// based on number of edges.
const nodeTypeWithEdgesToDirectionMap = {
  [NODE_TYPE_NAMES.AUTOMATION_NODE]: {
    left: {
      connectionType: NEXT_NODE_CONNECTION_TYPE.PRIMARY,
      sourceHandle: Position.Right,
      targetHandle: Position.Left,
      direction: directions.Left
    },
    right: {
      connectionType: NEXT_NODE_CONNECTION_TYPE.PRIMARY,
      sourceHandle: Position.Right,
      targetHandle: Position.Left,
      direction: directions.Right
    },
    top: {
      connectionType: NEXT_NODE_CONNECTION_TYPE.SECONDARY,
      sourceHandle: Position.Top,
      targetHandle: Position.Bottom,
      direction: directions.Top
    },
    bottom: {
      connectionType: NEXT_NODE_CONNECTION_TYPE.SECONDARY,
      sourceHandle: Position.Bottom,
      targetHandle: Position.Top,
      direction: directions.Bottom
    }
  },
  [NODE_TYPE_NAMES.UTILITY_NODE + UTILITY_TYPES.ROUTER]: {
    top: {
      connectionType: NEXT_NODE_CONNECTION_TYPE.PRIMARY,
      sourceHandle: Position.Top,
      targetHandle: Position.Left,
      direction: directions.Top_Right
    },
    bottom: {
      connectionType: NEXT_NODE_CONNECTION_TYPE.PRIMARY,
      sourceHandle: Position.Bottom,
      targetHandle: Position.Left,
      direction: directions.Bottom_Right
    }
  },
  [NODE_TYPE_NAMES.UTILITY_NODE + UTILITY_TYPES.FILTER]: {
    right: {
      connectionType: NEXT_NODE_CONNECTION_TYPE.PRIMARY,
      sourceHandle: Position.Right,
      targetHandle: Position.Left,
      direction: directions.Right
    }
  },
  [NODE_TYPE_NAMES.UTILITY_NODE + UTILITY_TYPES.DELAY]: {
    right: {
      connectionType: NEXT_NODE_CONNECTION_TYPE.PRIMARY,
      sourceHandle: Position.Right,
      targetHandle: Position.Left,
      direction: directions.Right
    }
  },
  [NODE_TYPE_NAMES.UTILITY_NODE + UTILITY_TYPES.DEDUPE]: {
    right: {
      connectionType: NEXT_NODE_CONNECTION_TYPE.PRIMARY,
      sourceHandle: Position.Right,
      targetHandle: Position.Left,
      direction: directions.Right
    }
  },
  [NODE_TYPE_NAMES.UTILITY_NODE + UTILITY_TYPES.OUTGOINGWEBHOOK]: {
    right: {
      connectionType: NEXT_NODE_CONNECTION_TYPE.PRIMARY,
      sourceHandle: Position.Right,
      targetHandle: Position.Left,
      direction: directions.Right
    }
  },
  [NODE_TYPE_NAMES.UTILITY_NODE + UTILITY_TYPES.INCOMINGWEBHOOK]: {
    right: {
      connectionType: NEXT_NODE_CONNECTION_TYPE.PRIMARY,
      sourceHandle: Position.Right,
      targetHandle: Position.Left,
      direction: directions.Right
    }
  }
}

// this will get the node outer box and center coordinates
const getNodeBoundingBoxAndCenter = (position, nodeType) => {
  return {
    center: {
      x: position.x + NODE_SIZES[nodeType] / 2,
      y: position.y + NODE_SIZES[nodeType] / 2
    },
    left: position.x,
    top: position.y,
    bottom: position.y + NODE_SIZES[nodeType],
    right: position.x + NODE_SIZES[nodeType]
  }
}

// this returns a map that can be used to get the
// position of the next node based on the direction
const getDirectionToPositionMap = (
  { left, center, top, right, bottom },
  fromNodeType,
  toNodeType
) => ({
  [directions.Top]: {
    x: center.x - NODE_SIZES[toNodeType] / 2,
    y:
      top -
      (GRID_SIZE +
        NODE_SIZES[toNodeType] +
        NODE_SIZE_DIFFERENCE[fromNodeType + toNodeType])
  },
  [directions.Bottom]: {
    x: center.x - NODE_SIZES[toNodeType] / 2,
    y: bottom + GRID_SIZE + NODE_SIZE_DIFFERENCE[fromNodeType + toNodeType]
  },
  [directions.Right]: {
    x: right + GRID_SIZE,
    y: center.y - NODE_SIZES[toNodeType] / 2
  },
  [directions.Left]: {
    x: left - (GRID_SIZE + NODE_SIZES[toNodeType]),
    y: center.y - NODE_SIZES[toNodeType] / 2
  },
  [directions.Top_Right]: {
    x: right + GRID_SIZE,
    y:
      top -
      (GRID_SIZE +
        NODE_SIZES[toNodeType] +
        NODE_SIZE_DIFFERENCE[fromNodeType + toNodeType])
  },
  [directions.Bottom_Right]: {
    x: right + GRID_SIZE,
    y: bottom + GRID_SIZE + NODE_SIZE_DIFFERENCE[fromNodeType + toNodeType]
  }
})

// this returns the position of next node based on
// the current node's position and type and next nodes type
const calculateNextNodePos = (
  fromPosition,
  fromNodeType,
  toNodeType,
  direction
) => {
  const boundingBox = getNodeBoundingBoxAndCenter(fromPosition, fromNodeType)
  return getDirectionToPositionMap(boundingBox, fromNodeType, toNodeType)[
    direction
  ]
}

// this will run all the validations required before adding a new node.
export const validateNewNodeConnection = (
  numberOfOutgoingEdges,
  currentNodeProps,
  newNodeAutomationData
) => {
  const validations = [
    {
      condition:
        currentNodeProps.data.connectionType ===
        NEXT_NODE_CONNECTION_TYPE.SECONDARY,
      errorMessage: 'Cannot connect nodes to secondary nodes'
    },
    {
      condition:
        currentNodeProps.type === NODE_TYPE_NAMES.AUTOMATION_NODE &&
        numberOfOutgoingEdges > 2,
      errorMessage: 'Cannot connect more nodes. All slots are used.'
    },
    {
      condition:
        currentNodeProps.type === NODE_TYPE_NAMES.UTILITY_NODE &&
        numberOfOutgoingEdges ===
          utilities()[currentNodeProps.data.automationData.utilityType]
            .numberOfOutputs,
      errorMessage: 'Cannot connect more nodes to this operation'
    },
    {
      condition:
        newNodeAutomationData.nodeType === NODE_TYPE_NAMES.UTILITY_NODE &&
        numberOfOutgoingEdges > 0,
      errorMessage: 'Cannot connect operations as secondary nodes'
    }
  ]

  for (const validation of validations) {
    if (validation.condition) {
      throw new Error(validation.errorMessage)
    }
  }
}

// handler to transform node data to api structure and call the api
export const addNewNode = (workflowId, nodeToAdd) => {
  const { automationData, previousNodeId, nextNodeId, connectionType } =
    nodeToAdd.data
  nodeToAdd.id = ObjectID().toHexString()

  addWorkflowNode(workflowId, {
    id: nodeToAdd.id,
    platformOperationId: automationData.platformOperationId,
    platformId: automationData.platformId,
    label: automationData.name,
    connectedAccountId: null,
    nextNode: nextNodeId,
    ...store._state.data.automationStore.automationOutputMode,
    previousNode: previousNodeId,
    utilityType: automationData.utilityType,
    meta: JSON.stringify({
      isSecondaryConnection:
        connectionType === NEXT_NODE_CONNECTION_TYPE.SECONDARY
          ? true
          : undefined
    })
  }).catch(console.log)
  return nodeToAdd
}

const getAllProperties = (
  currentNodeProps,
  newNodeAutomationData,
  inputHandlePosition
) => {
  // get all the properties for new node
  const nodeType =
    currentNodeProps.type +
    (currentNodeProps.data.automationData.utilityType || '')

  const { connectionType, sourceHandle, targetHandle, direction } =
    nodeTypeWithEdgesToDirectionMap[nodeType][inputHandlePosition]

  // get the next node's position
  const position = calculateNextNodePos(
    currentNodeProps.position,
    currentNodeProps.type,
    newNodeAutomationData.nodeType,
    direction
  )
  return {
    connectionType,
    sourceHandle,
    targetHandle,
    direction,
    position
  }
}

export const calculateNextNodeData = async (
  currentNodeProps,
  newNodeAutomationData,
  workflowId,
  inputHandlePosition,
  nodes,
  edges
) => {
  // get the next node edge
  const nextNodeEdge = edges.value
    .filter(e => e.source === currentNodeProps.id)
    .find(edge =>
      inputHandlePosition === Position.Left
        ? edge.sourceHandle === Position.Right
        : edge.sourceHandle === inputHandlePosition
    )

  const previousNodeEdge = edges.value
    .filter(e => e.target === currentNodeProps.id)
    .find(edge => edge.targetHandle === Position.Left)

  const isLeftInbetweenNode =
    inputHandlePosition === Position.Left && !!previousNodeEdge?.source

  const isRightInbetweenNode =
    inputHandlePosition !== Position.Left && !!nextNodeEdge?.target

  const isNewFirstNode =
    inputHandlePosition === Position.Left &&
    !currentNodeProps.data.previousNodeId

  // check if its an inbetween node
  if (isLeftInbetweenNode || isRightInbetweenNode) {
    // assign previous and next nodes
    let previousNodeId, nextNodeId
    if (isLeftInbetweenNode) {
      previousNodeId = previousNodeEdge?.source
      nextNodeId = currentNodeProps.id
    } else if (isRightInbetweenNode) {
      nextNodeId = nextNodeEdge?.target
      previousNodeId = currentNodeProps.id
    }

    // delete the edge between previous and next node
    edges.value = edges.value.filter(
      edge => !(edge.target === nextNodeId && edge.source === previousNodeId)
    )

    // calculate everything using previous node as the current node
    const previousNode = nodes.value.find(n => n.id === previousNodeId)
    const nextNode = nodes.value.find(n => n.id === nextNodeId)

    // check if the previous node is a router.
    const isPreviousRouterNode =
      previousNode.data.automationData.utilityType === UTILITY_TYPES.ROUTER
    const routerHandlePosition =
      previousNode?.position.y > nextNode?.position.y
        ? Position.Top
        : Position.Bottom

    // get all the properties for new node
    const { connectionType, targetHandle, position, sourceHandle } =
      getAllProperties(
        previousNode,
        newNodeAutomationData,
        isPreviousRouterNode ? routerHandlePosition : 'right'
      )

    // add the inbetween node and update surrounding nodes
    const { newNodes, newEdges } = await handleInbetweenNode(
      {
        type: newNodeAutomationData.nodeType,
        position,
        label: newNodeAutomationData.name,
        data: {
          automationData: newNodeAutomationData,
          connectionType,
          targetHandle,
          previousNodeId,
          nextNodeId,
          order: newNodeAutomationData.utilityType
            ? previousNode.data.order
            : previousNode.data.order + 1
        }
      },
      previousNodeId,
      nextNodeId,
      nodes,
      edges,
      workflowId,
      { targetHandle, sourceHandle },
      isPreviousRouterNode
    )
    return { newNodes, newEdges }
  } else if (isNewFirstNode) {
    // get all the properties for new node
    const { connectionType, targetHandle } = getAllProperties(
      currentNodeProps,
      newNodeAutomationData,
      inputHandlePosition
    )
    // create the new node
    const { newNode, newEdge } = await handleNewFirstNode(
      {
        type: newNodeAutomationData.nodeType,
        position: currentNodeProps.position,
        label: newNodeAutomationData.name,
        data: {
          automationData: newNodeAutomationData,
          connectionType,
          targetHandle,
          nextNodeId: currentNodeProps.id,
          order: 1
        }
      },
      currentNodeProps.id,
      workflowId,
      nodes,
      edges
    )
    return {
      newNodes: [newNode],
      newEdges: [newEdge]
    }
  } else {
    const { connectionType, sourceHandle, targetHandle, position } =
      getAllProperties(
        currentNodeProps,
        newNodeAutomationData,
        inputHandlePosition
      )
    // create the new node
    const newNode = addNewNode(workflowId, {
      type: newNodeAutomationData.nodeType,
      position,
      label: newNodeAutomationData.name,
      data: {
        automationData: newNodeAutomationData,
        connectionType,
        targetHandle,
        previousNodeId: currentNodeProps.id,
        order:
          connectionType === NEXT_NODE_CONNECTION_TYPE.SECONDARY
            ? undefined
            : newNodeAutomationData.utilityType
            ? currentNodeProps.data.order
            : currentNodeProps.data.order + 1
      }
    })
    const newEdge = {
      id: `${currentNodeProps.id}${sourceHandle}-${newNode.id}`,
      source: currentNodeProps.id,
      target: newNode.id,
      sourceHandle,
      targetHandle
    }
    return {
      newNodes: [newNode],
      newEdges: [newEdge]
    }
  }
}

const handleNewFirstNode = async (
  newNodeData,
  nextNodeId,
  workflowId,
  nodes,
  edges
) => {
  const nextNode = nodes.value.find(node => node.id === nextNodeId)
  moveAllNodesAfterCoordsBy(
    { x: calculateMoveDistance([newNodeData]), y: 0 },
    nextNode,
    nodes,
    edges,
    false
  )

  // create the new node
  const newNode = addNewNode(workflowId, newNodeData)

  // update start node and previous node
  Promise.all([
    updateWorkflow(workflowId, { startNode: newNode.id }),
    updateWorkflowNode(workflowId, nextNodeId, {
      previousNode: newNode.id
    })
  ])
  nodes.value = nodes.value.map(node => {
    if (node.id === nextNodeId) {
      node.data.previousNodeId = newNode.id
    }
    return node
  })

  const newEdge = {
    id: `${newNode.id}${Position.Right}-${nextNodeId}`,
    source: newNode.id,
    target: nextNodeId,
    sourceHandle: Position.Right,
    targetHandle: Position.Left
  }

  return { newNode, newEdge }
}

const handleInbetweenNode = async (
  newNodeData,
  previousNodeId,
  nextNodeId,
  nodes,
  edges,
  workflowId,
  previousNodeHandles,
  isPreviousRouterNode
) => {
  // make space for the new node
  // add the new node in the graph and save it
  // update previous and next nodes

  const nextNode = nodes.value.find(node => node.id === nextNodeId)
  moveAllNodesAfterCoordsBy(
    { x: calculateMoveDistance([newNodeData]), y: 0 },
    nextNode,
    nodes,
    edges,
    !!newNodeData.data.automationData.utilityType
  )

  // create the new node and update the previous and next nodes.
  const newNode = addNewNode(workflowId, newNodeData)
  Promise.all([
    isPreviousRouterNode ||
      updateWorkflowNode(workflowId, previousNodeId, {
        nextNode: newNode.id
      }),
    updateWorkflowNode(workflowId, nextNodeId, {
      previousNode: newNode.id
    })
  ])
  nodes.value = nodes.value.map(node => {
    if (node.id === nextNodeId) {
      node.data.previousNodeId = newNode.id
    } else if (node.id === previousNodeId) {
      node.data.nextNodeId = newNode.id
    }
    return node
  })

  const nextEdge = {
    id: `${newNode.id}${Position.Right}-${nextNodeId}`,
    source: newNode.id,
    target: nextNodeId,
    sourceHandle: Position.Right,
    targetHandle: Position.Left
  }
  const previousEdge = {
    id: `${previousNodeId}${previousNodeHandles.sourceHandle}-${newNode.id}`,
    source: previousNodeId,
    target: newNode.id,
    sourceHandle: previousNodeHandles.sourceHandle,
    targetHandle: Position.Left
  }

  return {
    newNodes: [newNode],
    newEdges: [nextEdge, previousEdge]
  }
}

export const roundDownToGridSize = position =>
  GRID_SIZE * Math.floor(position / GRID_SIZE) - GRID_SIZE + 50

// get nodes and edges in VueFlow format from db object
export const getNodeAndEdges = async (
  workflow,
  nodesResponse,
  nodeExecutions
) => {
  const nodes = []
  const edges = []
  let sourceHandle = Position.Right,
    targetHandle = Position.Left
  const nodeIdMap = {}
  nodesResponse.data.forEach(node => {
    nodeIdMap[node._id] = { ...node, numberOfOutgoingEdges: 0 }
  })

  if (!workflow.startNode) return { nodes, edges }
  const queue = [{ nodeId: workflow.startNode, position: 'right', order: 0 }]

  // loop through all the nodes in the workflow in DFS
  // and add nodes and edges for Vueflow
  while (queue.length > 0) {
    const {
      nodeId,
      position: nodeRelativePosition,
      order,
      routeLabel
    } = queue.shift()
    const node = nodeIdMap[nodeId]
    if (!node) continue
    //node.meta is not present in node for automations created from API for some reason ?!
    const { isSecondaryConnection } = node.meta ? JSON.parse(node.meta) : {}
    const type = node.utilityType
      ? NODE_TYPE_NAMES.UTILITY_NODE
      : NODE_TYPE_NAMES.AUTOMATION_NODE
    let position = {
      x: GRID_SIZE,
      y: roundDownToGridSize(window.innerHeight / 2)
    }

    // find the current node position using the previous node
    // if previous node is present
    if (node.previousNode) {
      const previousNode = nodeIdMap[node.previousNode]
      const previousNodeType = previousNode.utilityType
        ? NODE_TYPE_NAMES.UTILITY_NODE
        : NODE_TYPE_NAMES.AUTOMATION_NODE
      const nodeType = previousNodeType + (previousNode.utilityType || '')
      const {
        direction,
        sourceHandle: updatedSourceHandle,
        targetHandle: updatedTargetHandle
      } = nodeTypeWithEdgesToDirectionMap[nodeType][nodeRelativePosition]

      // get the next node's position
      position = calculateNextNodePos(
        previousNode.position,
        previousNodeType,
        type,
        direction
      )
      sourceHandle = updatedSourceHandle
      targetHandle = updatedTargetHandle

      // Increment the numberOfOutgoingEdges count for the previous node
      // when the current node is added
      nodeIdMap[node.previousNode].numberOfOutgoingEdges += 1

      // add edge to connect to the previous node

      edges.push({
        id: `${node._id}${sourceHandle}-${node._id}`,
        source: node.previousNode,
        target: node._id,
        sourceHandle,
        targetHandle,
        data: { routeLabel },
        animated: !(
          nodeExecutions?.[node.previousNode]?.status === 'completed' &&
          nodeExecutions?.[node._id]?.status === 'completed'
        )
      })
    }

    const currentOrder = node.utilityType ? order : order + 1
    // add the current node ot the nodes array
    nodes.push({
      id: node._id,
      type,
      position,
      label: node.label,
      data: {
        automationData: {
          platformId: node.platformId,
          platformOperationId: node.platformOperationId,
          logoUrl: node.platformIconUrl,
          ...utilities()[node.utilityType]
        },
        connectionType: isSecondaryConnection
          ? NEXT_NODE_CONNECTION_TYPE.SECONDARY
          : NEXT_NODE_CONNECTION_TYPE.PRIMARY,
        targetHandle,
        previousNodeId: node.previousNode,
        nextNodeId: node.nextNode,
        order: currentOrder,
        savedInputs: node.inputs,
        dynamicOutputs: node.dynamicOutputs
      }
    })

    // update the position of the node in the hash map
    // so that next nodes can use the position
    nodeIdMap[nodeId].position = position

    // add next node in the queue
    if (node.nextNode) {
      queue.push({
        nodeId: node.nextNode,
        position: 'right',
        order: currentOrder
      })
    }

    // add all the secondary nodes in the queue
    if (node.secondaryNodes) {
      node.secondaryNodes.forEach(secondaryNode => {
        queue.push({
          nodeId: secondaryNode.nodeId,
          position: secondaryNode.position
        })
      })
    }

    // add all the nodes from the router in the queue.
    if (node.utilityType === UTILITY_TYPES.ROUTER) {
      const positions = ['top', 'bottom']
      node.inputs?.routers?.forEach((route, index) => {
        queue.push({
          nodeId: route.nextNode,
          routeLabel: route.routeLabel,
          position: positions[index],
          order: currentOrder
        })
      })
    }
  }
  return { nodes, edges, nodeIdMap }
}

// calculate the distance to move all the next nodes
export const calculateMoveDistance = nodes => {
  return nodes.reduce((currentDistance, node) => {
    const { connectionType } = node.data
    if (connectionType === NEXT_NODE_CONNECTION_TYPE.PRIMARY) {
      return currentDistance + GRID_SIZE + NODE_SIZES[node.type]
    }
    return currentDistance
  }, 0)
}

// move all the nodes to the right of the given coords
// by the specified moveDistance.
export const moveAllNodesAfterCoordsBy = (
  moveDistance,
  node,
  nodes,
  edges,
  isUtilityNodeAdded,
  numberOfNodesDeleted
) => {
  const connectedNodes = [node.id]
  const nodeMap = {}
  nodes.value.forEach(node => {
    nodeMap[node.id] = node
  })
  while (connectedNodes.length > 0) {
    const nodeToMoveId = connectedNodes.shift()
    const nodeToMove = nodeMap[nodeToMoveId]
    const updatedPosition = {
      x: nodeToMove.position.x + moveDistance.x,
      y: nodeToMove.position.y + moveDistance.y
    }
    let order = isUtilityNodeAdded
      ? nodeToMove.data.order
      : nodeToMove.data.order + 1
    if (moveDistance.x < 0) {
      order = nodeToMove.data.order - numberOfNodesDeleted
    }
    nodeToMove.position = updatedPosition
    nodeToMove.data.order = order

    connectedNodes.push(
      ...edges.value
        .filter(edge => edge.source === nodeToMove.id)
        .map(edge => edge.target)
    )
  }
}

// get all the previous nodes
export const getAllPreviousNodes = (node, nodes) => {
  const nodeMap = {}
  nodes.forEach(node => {
    nodeMap[node.id] = node
  })
  let previousNode = nodeMap[node.previousNodeId]
  const previousNodes = []
  while (previousNode) {
    previousNodes.push({
      id: previousNode.id,
      label: previousNode.label,
      order: previousNode.data.order,
      ...previousNode.data.automationData
    })
    previousNode = nodeMap[previousNode.data.previousNodeId]
  }
  return previousNodes.filter(node => node.platformId)
}

// get all the nodes in the given direction from the coords
export const getAllNodesInTheDirection = (coords, nodes, direction) => {
  return nodes.value.filter(node => {
    const condition =
      direction === 'right'
        ? node.position.x >= coords.x
        : node.position.x <= coords.x
    return condition
  })
}

export const convertInputStringToHtml = (inputString,previousNodes={}) => {
  if (!inputString) return inputString
  inputString = String(inputString)
  let result,
    htmlString = inputString.trim()
  while ((result = INPUT_UTILITY_REGEX.exec(inputString.trim()))) {
    const [_, name, parameterString] = result
    htmlString = htmlString.replace(
      result[0],
      getHtmlFromAttribsForUtility(name, parameterString)
    )
  }

  while ((result = VARIABLE_REGEX.exec(inputString.trim()))) {
    let nodeId = null
    let outputName = null
    let outputLabel = null
    let srcLabel = null

    const path = result[1].split('.')
    if (path.length > 1) {
      nodeId = path[0]
      outputName = path.slice(1).join('.')
    } else {
      outputName = path.slice(0).join('.')
    }

    //loop hashmap in vuex to find label matching the outputName
    for (const [key, value] of store._state.data.workflow.variablesHashMap) {
      const matchingObject = value.find(obj => obj.name === outputName)
      if (matchingObject) {
        outputLabel = matchingObject.label
        break
      }
    }

    if (outputLabel === null || undefined) {
      outputLabel = outputName
    }
    
    const src = previousNodes.find(obj => obj.id === nodeId)
    if (src) {
      srcLabel = `${src.order ? `${src.order}.` : ''}${src.label}`
    }

    htmlString = htmlString.replace(
      result[0],

      getHtmlFromAttribs(nodeId, outputName, outputLabel, srcLabel)
    )
  }

  while ((result = TAG_REGEX.exec(inputString.trim()))) {
    const name = result[1]
    htmlString = htmlString.replace(result[0], getHtmlFromAttribsForTag(name))
  }

  return htmlString
}

const getHtmlFromAttribs = (nodeId, outputName, outputLabel, srcLabel) => { 
  return `<span data-type="variable" data-node-id="${nodeId}" data-output-name="${outputName}" data-output-label="${outputLabel}" data-src-label="${srcLabel}"></span>`
}
const getHtmlFromAttribsForTag = name => {
  return `<span data-type="tag" data-name="${name}"></span>`
}

const getHtmlFromAttribsForUtility = (name, parameterString) => {
  const start = `<span data-type="function-start" data-name="${name}" ></span>`
  const separators = parameterString
    .split('$;')
    .join(`<span data-type="function-param-separator" ></span>`)
  const end = `<span data-type="function-end" ></span>`
  return start + separators + end
}

export const updateNodeAutomation = (updatedNode, nodes) => {
  nodes.value = nodes.value.map(node => {
    if (node.id === updatedNode.id) {
      return {
        ...node,
        label: updatedNode.label,
        data: {
          ...node.data,
          savedInputs: updatedNode.inputs,
          dynamicOutputs: updatedNode.dynamicOutputs,
          automationData: {
            platformId: updatedNode.platformId,
            platformOperationId: updatedNode.platformOperationId,
            logoUrl: updatedNode.platformIconUrl,
            ...utilities()[updatedNode.utilityType]
          }
        }
      }
    }
    return node
  })
}

export const getNodeOutputs = node => {
  try {
    if (
      node.platformOperationId === constants.csvPlatformOperationId ||
      node.platformOperationId === constants.googlePlatformOperationId
    ) {
      return store.getters['workflow/getDynamicOutputs'](node.id) || []
    } else {
      return store.getters['workflow/getOutput'](node.platformOperationId) || []
    }
  } catch (error) {
    console.log(error)
  }
}
