<template>
  <div
    class="h-full overflow-x-hidden bg-white font-sans text-gray-900 antialiased dark:bg-gray-900 dark:text-gray-50"
  >
    <!-- START WRAPPER -->
    <div class="flex min-h-screen justify-between">
      <!-- START LEFT COLUMN -->
      <OnboardingLeftColumn />
      <!-- END LEFT COLUMN -->

      <!-- START RIGHT COLUMN -->
      <div v-if="!showTotp" class="flex-1 bg-white dark:bg-gray-900">
        <div class="grid h-full w-full items-start justify-center">
          <div class="h-full px-6 py-16 sm:px-12 sm:py-20 lg:px-20">
            <div class="flex h-full max-w-lg flex-col">
              <a class="" href="#" title="TexAu">
                <CompanyLogo />
              </a>

              <div class="mt-12 grid h-full place-items-center">
                <div>
                  <div class="text-center">
                    <Heading text="Reset your password here" />
                  </div>

                  <div v-if="resetPasswordDiv">
                    <ResetPassword
                      :email="email"
                      @passwordReset="passwordReset"
                    />
                  </div>

                  <div v-else>
                    <GetMobileOtp @codeSend="codeSend" />
                  </div>

                  <div class="flex items-center justify-between">
                    <div class="mt-4">
                      <label
                        class="block text-sm font-medium text-gray-900 dark:text-gray-50"
                        for=""
                      >
                        Don't have an account yet ?
                      </label>

                      <label
                        class="block cursor-pointer text-sm font-medium text-blue-900 dark:text-blue-400"
                        for=""
                        @click="onCreateAccount()"
                      >
                        Create an account
                      </label>
                    </div>
                    <label
                      class="block cursor-pointer text-sm font-medium text-blue-900 dark:text-blue-400"
                      for=""
                      @click="onLogin()"
                    >
                      Log In
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END RIGHT COLUMN -->

      <Totp v-if="showTotp" />
    </div>
    <!-- END WRAPPER -->
  </div>
</template>

<script>
import logo from '@/assets/images/onboarding/google-logo.svg'
import Heading from '../../components/onboarding/Heading.vue'
import OnboardingLeftColumn from '../../components/onboarding/OnboardingLeftColumn.vue'
import GetMobileOtp from '@/components/onboarding/GetMobileOtp.vue'
import ResetPassword from '../../components/onboarding/ResetPassword.vue'
import Totp from '@/views/onboarding/totp.vue'
import CompanyLogo from '@/components/onboarding/CompanyLogo.vue'

export default {
  name: 'forgetPassword',
  components: {
    OnboardingLeftColumn,
    Heading,
    GetMobileOtp,
    ResetPassword,
    Totp,
    CompanyLogo
  },
  data() {
    return {
      showTotp: false,
      email: null,
      isLoading: false,
      logo,
      schema: {
        Email: 'required|email|min:3|max:100',
        Password: 'required|password_min:12|password_no_spaces',
        code: 'required|min:6|max:6'
      },
      resetPasswordDiv: false
    }
  },
  methods: {
    onCreateAccount() {
      this.$router.push('/register')
    },

    onLogin() {
      this.$router.push('/login')
    },
    codeSend(data) {
      if (data.type === 'success') {
        this.$emit('success', data.message)
      } else {
        this.$emit('error', data.message)
        return
      }
      this.email = data.email
      this.resetPasswordDiv = true
    },
    passwordReset(data) {
      if (data.type === 'success') {
        this.$emit('success', data.message)
        this.$router.push('/login')
      } else {
        this.$emit('error', data.message)
        return
      }
    }
  }
}
</script>

<style scoped></style>
