import {
  Form as VeeForm,
  Field as VeeField,
  defineRule,
  ErrorMessage,
  configure
} from 'vee-validate'
import {
  required,
  email,
  min,
  max,
  alpha_spaces,
  numeric,
  confirmed
} from '@vee-validate/rules'

export default {
  install(app) {
    app.component('VeeForm', VeeForm)
    app.component('VeeField', VeeField)
    app.component('ErrorMessage', ErrorMessage)

    defineRule('required', required)
    defineRule('otp_required', required)
    defineRule('phone_required', required)
    defineRule('confirmPassword_required', required)
    defineRule('workspace_required', required)
    defineRule('email', email)
    defineRule('min', min)
    defineRule('password_min', min)
    defineRule('code_min', min)
    defineRule('workspace_max', max)
    defineRule('max', max)
    defineRule('alpha_spaces', alpha_spaces)
    defineRule('numeric', numeric)
    defineRule('password_mismatch', confirmed)
    defineRule('password_no_spaces', (value) => {
      if (!value || value.includes(' ')) {
        return 'Your password cannot contain spaces';
      }
      return true;
    });
    
    configure({
      generateMessage: ctx => {
        const messages = {
          //required messages
          required: ` ${ctx.field} is required`,
          otp_required:`OTP is required. Resend OTP if you didn’t receive it.`,
          workspace_required: `Workspace Name is required`,
          workspace_max: `Workspace name cannot exceed 50 characters`,
          phone_required: `Phone number is required`,
          confirmPassword_required: `Please confirm your password`,

          //email messages
          email: `This email is not valid`,

          //minimum messages
          password_min: `Your password must be at least 12 characters long`,
          code_min: `Your code must be at least 4 characters long`,
          confirm_new_password_required: ``,
          password_mismatch: `Please make sure your passwords match`
        }

        const message = messages[ctx.rule.name]
          ? messages[ctx.rule.name]
          : `${ctx.field} is invalid`

        return message
      },
      validateOnBlur: true,
      validateOnChange: true,
      validateOnInput: false,
      validateOnModelUpdate: true
    })
  }
}
