<template>
  <div v-if="label" class="mb-1.5">
    <label :class="labelClass" class="flex">
      {{ label }} {{ isRequired === false ? '(Optional)' : null }}
      <SvgIcon
        class="mb-3 ml-1 h-2 w-2 text-red-600"
        name="star"
        v-if="isRequired"
      />
    </label>

    <div class="flex items-center gap-x-2 mt-1.5">
      <Button
        class="w-[194px]"
        @click="gdriveConnectClick()"
        color="tertiary"
        :showLoader="buttonLoader"
        :leftIcon="buttonLoader ? null : 'google'"
        text="Open Google Drive"
      />
      <p class="font-semibold text-sm text-wrap max-w-[18rem] text-blue-500">
        {{ fileNameDisplay }}
      </p>
    </div>

    <p v-if="description" class="text-sm mt-2 text-gray-500">
      {{ description }}<slot></slot>
    </p>
  </div>
</template>

<script setup>
import { ref, watch, computed, onMounted } from 'vue'
import SvgIcon from './SvgIcon.vue'
import Button from './Button.vue'
import { getGoogleToken } from '../apis/automation-store/googleSheets'
import { useStore } from 'vuex'

const props = defineProps({
  modelValue: {},
  label: String,
  labelClass: String,
  type: String,
  placeholder: { type: String, default: 'write something...' },
  readonly: Boolean,
  disabled: Boolean,
  previousNodes: Array,
  isRequired: { type: Boolean, default: undefined },
  modalClass: String,
  isLabelEditable: Boolean,
  attachmentSpecifications: Object,
  personalisedTags: Array,
  description: String,
  allInputs: { type: Object },
  dropdownConfig: { type: Object }
})

const store = useStore()
const fileName = ref(null)
const buttonLoader = ref(false)
const apiKey = ref('AIzaSyAJrQWAO2uHdgMoW8Mc1LByBzKTtS5EcBw')
const appId = ref('big-signifier-391709')
const fileId = ref(null)

const fileNameDisplay = computed(() => {
  return fileName.value
    ? fileName.value
    : props.allInputs?.spreadSheetName
    ? props.allInputs?.spreadSheetName
    : null
})

onMounted(async () => {
  await gapi.load('client:picker', async () => {
    await gapi.client.load(
      'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'
    )
  })
})

const gdriveConnectClick = async () => {
  try {
    buttonLoader.value = true

    const tokenRes = await getGoogleToken(props.allInputs.connectedAccountId)
    buttonLoader.value = false
    const view = new google.picker.View(google.picker.ViewId.SPREADSHEETS)
    view.setMimeTypes('application/vnd.google-apps.spreadsheet')
    const picker = new google.picker.PickerBuilder()
      .enableFeature(google.picker.Feature.NAV_HIDDEN)
      .setDeveloperKey(apiKey.value)
      .setAppId(appId.value)
      .setOAuthToken(tokenRes.data.token)
      .addView(view)
      .addView(new google.picker.DocsUploadView())
      .setCallback(data => {
        if (data.action === google.picker.Action.PICKED) {
          const document = data[google.picker.Response.DOCUMENTS][0]
          fileId.value = document[google.picker.Document.ID]
          fileName.value = document[google.picker.Document.NAME]
          store.dispatch('automationStore/addAutomationInputData', {
            payload: {
              spreadSheetName: fileName.value
            }
          })
          emit('update:modelValue', fileId.value)
          emit('change', fileId.value)
          emit('blur', fileId.value)
        }
      })
      .build()
    picker.setVisible(true)
  } catch (error) {
    console.log(error)
  }
}

const emit = defineEmits([
  'update:modelValue',
  'label-update',
  'blur',
  'change',
  'toggleOpenAiAccounts'
])
</script>
