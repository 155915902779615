import { store } from '@/store/store'
import { getOrganisationLimits } from '@/apis/user'
const getExecutionTimeLeft = async () => {
  let executionTimeLeft = null
  let emailVerifiersLeft = null
  let emailEnrichmentsLeft = null
  let proxyAccess = null
  let socialAccountsLeft = null
  let socialAccountsAllowed = null
  let desktopUnlimited = false
  let aiAccess = false
  let integrationsAccess = false
  let apiAccess = false
  let additionalData = {}
  let isExecutionTimeExceeded=false
  try {
    const ExecutionTimeResponse = await getOrganisationLimits()

    if (ExecutionTimeResponse['success']) {
      executionTimeLeft = convertMsToTimeString(
        ExecutionTimeResponse.data.executionTime.left
          ? ExecutionTimeResponse.data.executionTime.left
          : '0000000'
      )

      additionalData.executionTimeInMs = ExecutionTimeResponse.data
        .executionTime.total
        ? Number(ExecutionTimeResponse.data.executionTime.total)
        : Number('0000000')

      additionalData.executionTimeLeftInMs = ExecutionTimeResponse.data
        .executionTime.left
        ? Number(ExecutionTimeResponse.data.executionTime.left)
        : Number('0000000')

      additionalData.totalEmailEnrichment = ExecutionTimeResponse.data
        .emailEnrichment.total
        ? ExecutionTimeResponse.data.emailEnrichment.total
        : 0

      additionalData.desktopUnlimitedExecutionTime = ExecutionTimeResponse.data
        .desktopUnlimitedExecutionTime
        ? ExecutionTimeResponse.data.desktopUnlimitedExecutionTime
        : false

      emailEnrichmentsLeft = ExecutionTimeResponse.data.emailEnrichment.left
        ? ExecutionTimeResponse.data.emailEnrichment.left
        : 0

      proxyAccess = ExecutionTimeResponse.data.proxyAccess
        ? ExecutionTimeResponse.data.proxyAccess
        : false

      socialAccountsLeft = ExecutionTimeResponse.data.socialAccounts.left
        ? ExecutionTimeResponse.data.socialAccounts.left
        : 0

      socialAccountsAllowed = ExecutionTimeResponse.data.socialAccounts.total
        ? ExecutionTimeResponse.data.socialAccounts.total
        : 0

      desktopUnlimited = ExecutionTimeResponse.data
        .desktopUnlimitedExecutionTime
        ? ExecutionTimeResponse.data.desktopUnlimitedExecutionTime
        : false

      aiAccess = ExecutionTimeResponse.data.aiAccess
        ? ExecutionTimeResponse.data.aiAccess
        : false

      integrationsAccess = ExecutionTimeResponse.data.integrationsAccess
        ? ExecutionTimeResponse.data.integrationsAccess
        : false

      apiAccess = ExecutionTimeResponse.data.apiAccess
        ? ExecutionTimeResponse.data.apiAccess
        : false

      const totalExecutionTime = ExecutionTimeResponse.data.executionTime.total
        ? Number(ExecutionTimeResponse.data.executionTime.total)
        : 0
      
      const executionTimeLeftMs = ExecutionTimeResponse.data.executionTime.left
        ? Number(ExecutionTimeResponse.data.executionTime.left)
        : 0
      
      // If less than 20% time is left, consider it exceeded (more than 80% used)
      isExecutionTimeExceeded =
        totalExecutionTime > 0 && ((totalExecutionTime-executionTimeLeftMs) / totalExecutionTime) > 0.8
    } else {
      throw 'error'
    }
  } catch (error) {
    executionTimeLeft = 'Error'
    emailVerifiersLeft = 'Error'
    emailEnrichmentsLeft = 'Error'
    proxyAccess = false
    socialAccountsLeft = 0
    socialAccountsAllowed = 0
    desktopUnlimited = false
    aiAccess = false
    integrationsAccess = false
    apiAccess = false
    additionalData = {}
  } finally {
    store.commit('user/SAVE_EXECUTION_TIME_LEFT', executionTimeLeft)
    store.commit('user/SAVE_EMAIL_VERIFIERS_LEFT', emailVerifiersLeft)
    store.commit('user/SAVE_EMAIL_ENRICHMENTS_LEFT', emailEnrichmentsLeft)
    store.commit('user/SAVE_PROXY_ACCESS', proxyAccess)
    store.commit('user/SAVE_SOCIAL_ACCOUNTS_LEFT', socialAccountsLeft)
    store.commit('user/SAVE_SOCIAL_ACCOUNTS_ALLOWED', socialAccountsAllowed)
    store.commit('user/SAVE_DESKTOP_ACCESS', desktopUnlimited)
    store.commit('user/SAVE_AI_ACCESS', aiAccess)
    store.commit('user/SAVE_INTEGRATION_ACCESS', integrationsAccess)
    store.commit('user/SAVE_API_ACCESS', apiAccess)
    store.commit('user/SAVE_ADDITIONAL_DATA', additionalData)
    store.commit('user/SAVE_EXECUTION_TIME_EXCEEDED', isExecutionTimeExceeded)
  }
}

const convertMsToTimeString = time => {
  const number = Number(time)
  const d = number / 1000
  const h = Math.floor(d / 3600)
  const m = Math.floor((d % 3600) / 60)
  const s = Math.floor((d % 3600) % 60)

  const hDisplay = h > 0 ? (h.toString().length === 1 ? `0${h}` : h) : '00'
  const mDisplay = m > 0 ? (m.toString().length === 1 ? `0${m}` : m) : '00'
  const sDisplay = s > 0 ? (s.toString().length === 1 ? `0${s}` : s) : '00'

  return `${hDisplay}h ${mDisplay}m ${sDisplay}s`
}

export { getExecutionTimeLeft }
