<template>
  <div
    class="absolute left-0 top-0 z-50 grid h-full w-full place-items-center bg-transparent/50 px-4 py-12 antialiased dark:bg-transparent/50 sm:px-6 lg:px-8"
  >
    <div
      class="relative mx-auto h-full w-full max-w-3xl overflow-x-hidden rounded-xl bg-white dark:bg-gray-900"
    >
      <div class="px-4 py-5 sm:px-6">
        <div class="flex items-center justify-between">
          <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
            Execution Settings
          </p>

          <div class="">
            <Button
              @click="emitClose"
              icon="close-1"
              class="!p-1.5"
              size="large"
              color="tertiary"
            />
          </div>
        </div>
      </div>
      <div v-if="loading" class="flex items-center justify-center h-full">
        <Spinner size="large" />
      </div>

      <div v-else class="px-4 pb-5 sm:px-6 sm:pb-6 flex flex-col gap-y-4">
        <table class="min-w-full">
          <thead class="bg-gray-50 dark:bg-gray-800">
            <tr>
              <th
                scope="col"
                class="min-w-[12rem] px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
              >
                Name
              </th>

              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
              >
                Created At
              </th>

              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
              >
                Current Status
              </th>

              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
              >
                Change Status
              </th>
            </tr>
          </thead>

          <tbody
            class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
          >
            <tr v-for="data of executionData">
              <td
                class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
              >
                {{ rowData?.name }}
              </td>

              <td
                class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
              >
                {{ `${getFormatDateAndTime(data.createdAt)}` }}
              </td>

              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                <div class="flex items-center justify-center space-x-5">
                  <Popper hover>
                    <SvgIcon
                      class="h-5 w-6"
                      v-if="data.status === 'running'"
                      name="automationLoader"
                    />

                    <button
                      v-else
                      type="button"
                      class="flex items-center justify-center"
                      :class="[
                        {
                          'text-orange-500':
                            data.status === 'paused' ||
                            data.status === 'waiting',
                          'text-red-600': data.status === 'stopped',
                          'text-red-600': data.status === 'failed',
                          'text-green-600': data.status === 'completed',
                          'text-gray-500': data.status === 'delayed',
                          'text-black': data.status === 'partially-failed'
                        }
                      ]"
                    >
                      <svg
                        class="h-5 w-5"
                        fill="currentColor"
                        viewBox="0 0 8 8"
                      >
                        <circle cx="4" cy="4" r="3" />
                      </svg>
                    </button>
                    <template #content>
                      <div class="rounded bg-blue-50 p-2 text-sm capitalize">
                        {{
                          data.status === 'failed' || data.status === 'delayed'
                            ? data?.statusReason || 'Failed'
                            : data.status
                        }}
                      </div>
                    </template>
                  </Popper>
                </div>
              </td>

              <td
                class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
              >
                <div class="flex gap-2">
                  <Popper
                    v-if="data.status === 'running'"
                    class="cursor-pointer"
                    hover
                  >
                    <div class="h-5 w-5">
                      <SvgIcon
                        v-if="!isPauseLoading"
                        @click="changeExecutionStatus(data._id, 'pause')"
                        class="text-orange-500"
                        name="pause"
                      />
                      <SvgIcon
                        v-else
                        class="text-orange-500 animate-spin"
                        name="Loader"
                      />
                    </div>
                    <template #content>
                      <div class="rounded bg-blue-50 p-2 text-sm capitalize">
                        Pause
                      </div>
                    </template>
                  </Popper>

                  <Popper
                    v-if="data.status === 'paused'"
                    class="cursor-pointer"
                    hover
                  >
                    <div class="h-5 w-5">
                      <SvgIcon
                        v-if="!isResumeLoading"
                        @click="changeExecutionStatus(data._id, 'resume')"
                        class="text-green-500"
                        name="right-triangle"
                      />
                      <SvgIcon
                        v-else
                        class="text-green-500 animate-spin"
                        name="Loader"
                      />
                    </div>
                    <template #content>
                      <div class="rounded bg-blue-50 p-2 text-sm capitalize">
                        Resume
                      </div>
                    </template>
                  </Popper>

                  <Popper
                    v-if="data.status === 'running' || data.status === 'paused'"
                    class="cursor-pointer"
                    hover
                  >
                    <div class="h-5 w-5">
                      <SvgIcon
                        v-if="!isStopLoading"
                        @click="changeExecutionStatus(data._id, 'stop')"
                        class="text-red-600"
                        name="cancel"
                      />
                      <SvgIcon
                        v-else
                        class="text-red-600 animate-spin"
                        name="Loader"
                      />
                    </div>
                    <template #content>
                      <div class="rounded bg-blue-50 p-2 text-sm capitalize">
                        Stop
                      </div>
                    </template>
                  </Popper>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/Input.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Button from '@/components/Button.vue'
import Spinner from '../Spinner.vue'
import Select from '@/components/Select.vue'
import {
  getWorkflowExecution,
  executionStatusChange
} from '@/apis/workflows.js'
import {
  formatDate,
  formatTime,
  formatExecutionTime
} from '@/common/functions/formatDateAndTime'
import Popper from 'vue3-popper'
export default {
  name: 'executionSettings',
  components: {
    Input,
    SvgIcon,
    Button,
    Spinner,
    Select,
    Popper
  },

  data() {
    return {
      loading: true,
      executionData: null,
      rowData: this.selectedRow,
      isPauseLoading: false,
      isResumeLoading: false,
      isStopLoading: false
    }
  },
  props: {
    selectedRow: {}
  },
  mounted() {
    this.getData()
  },

  methods: {
    getFormatDateAndTime(d) {
      return `${formatDate(d)} ${formatTime(d)}`
    },

    emitClose() {
      this.$emit('closeModal')
    },
    async getData() {
      this.loading = true
      const response = await getWorkflowExecution(this.rowData.id)
      this.executionData = response.data
      this.loading = false
    },
    async changeExecutionStatus(id, status) {
      try {
        if (status === 'pause') this.isPauseLoading = true
        if (status === 'resume') this.isResumeLoading = true
        if (status === 'stop') this.isStopLoading = true

        const response = await executionStatusChange(id, status)
        if (response['success']) {
          this.$emit('success', response.message)
          this.getData()
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('error', error)
      } finally {
        this.isPauseLoading = false
        this.isResumeLoading = false
        this.isStopLoading = false
      }
    }
  }
}
</script>
