<template>
  <div
    class="h-full overflow-x-hidden bg-white font-sans text-gray-900 antialiased dark:bg-gray-900 dark:text-gray-50"
  >
    <div
      v-if="showOtpBanner"
      class="flex items-center justify-center bg-transparent z-50 absolute w-full p-1"
    >
      <Banner class="" :text="uaeOtpText" @close="showOtpBanner = false" />
    </div>
    <!-- START WRAPPER -->
    <div class="flex min-h-screen justify-between">
      <!-- START LEFT COLUMN -->
      <OnboardingLeftColumn />
      <!-- END LEFT COLUMN -->

      <!-- START RIGHT COLUMN -->
      <div class="flex-1 bg-white dark:bg-gray-900">
        <div class="grid h-full w-full items-start justify-center">
          <div class="h-full px-6 py-16 sm:px-12 sm:py-20 lg:px-20">
            <div class="flex h-full max-w-lg flex-col">
              <a class="" href="#" title="TexAu">
                <CompanyLogo />
              </a>

              <div class="mt-12 grid h-full place-items-center">
                <div>
                  <div class="text-center">
                    <Heading text="Unlock your Sales Potential using TexAu" />
                    <p class="text-sm mt-2 text-blue-600 font-[500]">
                      You'll need to provide an email address and phone number,
                      but don't worry you can verify them later when you're
                      ready to start automating.
                    </p>
                  </div>

                  <vee-form
                    :validation-schema="schema"
                    class="mt-10"
                    @submit="submitCreate()"
                  >
                    <div class="space-y-5">
                      <div class="flex flex-row space-x-1">
                        <div class="w-full">
                          <label
                            class="block text-sm font-medium text-gray-900 dark:text-gray-50"
                            for=""
                          >
                            First Name
                          </label>
                          <div class="mt-1.5">
                            <Input
                              v-model="contact.firstname"
                              name="FirstName"
                              text="John"
                              type=""
                            />
                            <p class="mt-2 text-sm text-red-600">
                              <ErrorMessage name="FirstName" />
                            </p>
                          </div>
                        </div>

                        <div class="w-full">
                          <label
                            class="block text-sm font-medium text-gray-900 dark:text-gray-50"
                            for=""
                          >
                            Last Name
                          </label>
                          <div class="mt-1.5">
                            <Input
                              v-model="contact.lastname"
                              name="LastName"
                              text="Doe"
                              type=""
                            />
                            <p class="mt-2 text-sm text-red-600">
                              <ErrorMessage name="LastName" />
                            </p>
                          </div>
                        </div>
                      </div>

                      <div>
                        <label
                          class="block text-sm font-medium text-gray-900 dark:text-gray-50"
                          for=""
                        >
                          Email Address
                        </label>
                        <div class="mt-1.5">
                          <Input
                            v-model="contact.email"
                            name="Email"
                            text="john.doe@company.com"
                            type="email"
                          />
                          <p class="mt-2 text-sm text-red-600">
                            <ErrorMessage name="Email" />
                          </p>
                        </div>
                      </div>

                      <div>
                        <label 
                          class="block text-sm font-medium text-gray-900 dark:text-gray-50"
                          for=""
                        >
                          Your Phone
                        </label>
                        <div class="mt-1.5 flex space-x-1">
                          <Select
                            text="Country code"
                            :options="phoneCodes"
                            v-model="phoneCodeOption"
                            class="!m-0 !w-fit whitespace-nowrap"
                            :includeSearch="true"
                          />
                          <div class="flex w-full rounded-md shadow-sm">
                            <span
                              class="inline-flex items-center whitespace-nowrap rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 caret-blue-600 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:hover:border-gray-500 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                              >{{ phoneCode }}</span
                            >
                            <vee-field
                              v-model="contact.phone"
                              name="phone"
                              placeholder="9000090000"
                              type="number"
                              class="block w-full rounded-lg rounded-l-none border border-gray-300 px-3 py-2.5 placeholder-gray-500 caret-blue-600 transition-all duration-200 [appearance:textfield] hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:hover:border-gray-500 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                            />
                          </div>
                        </div>
                        <p class="mt-2 text-sm text-red-600">
                          <ErrorMessage name="phone" />
                        </p>
                      </div>
                      <p class="text-[10px] text-gray-600">
                        *I agree to receive automated transactional text
                        messages (e.g. password reset) at the phone number
                        provided. Consent is not a condition to purchase. Msg &
                        data rates may apply. Reply HELP for help and STOP to
                        cancel. View our
                        <span>
                          <a class="text-blue-600" href="#">Terms of Service</a>
                        </span>
                        and
                        <span>
                          <a class="text-blue-600" href="#">Privacy Policy.</a>
                        </span>
                      </p>

                      <div>
                        <label
                          class="block text-sm font-medium text-gray-900 dark:text-gray-50"
                          for=""
                        >
                          Password
                        </label>
                        <div class="mt-1.5">
                          <Input
                            v-model="contact.password"
                            name="Password"
                            text="Enter Password"
                            :type="passwordFieldType"
                            :rightIcon="
                              passwordFieldType === 'text' ? 'eye-off' : 'eye'
                            "
                            rightIconMouse="cursor-pointer"
                            @onRightIconClick="onRightIconClick('password')"
                          />
                          <p class="mt-2 text-sm text-red-600">
                            <ErrorMessage name="Password" />
                          </p>
                          <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
                            Your password must include:
                          </p>
                          <ul class="ml-4 list-disc text-sm text-gray-600 dark:text-gray-400">
                            <li>At least 12 characters</li>
                            <li>At least one uppercase letter (A-Z)</li>
                            <li>At least one lowercase letter (a-z)</li>
                            <li>At least one number (0-9)</li>
                            <li>At least one special character (@$!%*?&)</li>
                          </ul>
                        </div>
                        <div/>
                      </div>
                      <div>
                        <label
                          class="block text-sm font-medium text-gray-900 dark:text-gray-50"
                          for=""
                        >
                          Confirm Password
                        </label>
                        <div class="mt-1.5">
                          <Input
                            v-model="confirmPassword"
                            name="confirmPassword"
                            text="Confirm New Password"
                            :type="confirmPasswordFieldType"
                            :rightIcon="confirmPasswordFieldType === 'text' ? 'eye-off' : 'eye'"
                            rightIconMouse="cursor-pointer"
                            @onRightIconClick="onRightIconClick('confirmPassword')"
                          />
                          <p class="mt-2 text-sm text-red-600">
                            <ErrorMessage name="confirmPassword" />
                          </p>
                        </div>
                        <div/>
                      </div>
                      <div class="relative flex gap-x-3">
                        <div class="flex h-6 items-center">
                          <input
                            id="terms_checkbox"
                            name="terms_checkbox"
                            type="checkbox"
                            v-model="tncCheck"
                            class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                            :disabled="isLoading"
                          />
                        </div>
                        <div class="text-sm flex leading-6">
                          <label
                            for="terms_checkbox"
                            class="font-medium text-gray-900"
                          >
                            I have read and agreed to the
                            <span>
                              <a
                                class="text-blue-600"
                                target="_blank"
                                href="https://texau.com/terms-and-conditions"
                                >Terms and Conditions</a
                              >
                            </span>
                          </label>
                          <SvgIcon
                            class="mb-3 h-2 w-2 text-red-600"
                            name="star"
                          />
                        </div>
                      </div>

                      <div>
                        <Button
                          :showLoader="isLoading"
                          :color="tncCheck ? 'primary' : 'gray'"
                          :disabled="tncCheck ? false : true"
                          class="w-full"
                          text="Get Started"
                          type="submit"
                        />
                      </div>
                    </div>
                  </vee-form>
                  <div class="mt-4 text-center">
                    <label
                      class="block text-sm font-medium text-gray-900 dark:text-gray-50"
                      for=""
                    >
                      Already have an account ?
                    </label>

                    <label
                      class="block cursor-pointer text-sm font-medium text-blue-900 dark:text-blue-400"
                      for=""
                      @click="onLoginAccount()"
                    >
                      Click here to login
                    </label>
                  </div>

                  <OnboardingStep1Footer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END RIGHT COLUMN -->
    </div>
    <!-- END WRAPPER -->
  </div>
</template>

<script>
import { register } from '@/apis/onboarding'
import logo from '@/assets/images/onboarding/google-logo.svg'
import { phoneCountryCodes } from '@/common/constants'
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
import Select from '@/components/Select.vue'
import CompanyLogo from '@/components/onboarding/CompanyLogo.vue'
import OnboardingStep1Footer from '@/components/onboarding/OnboardingStep1Footer.vue'
import { mapActions } from 'vuex'
import Heading from '../../components/onboarding/Heading.vue'
import OnboardingLeftColumn from '../../components/onboarding/OnboardingLeftColumn.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Checkboxes from '@/components/Checkboxes.vue'
import Banner from '@/components/Banner.vue'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { constants } from '@/common/constants'
import { createWorkspace } from '@/apis/workspace'
import { login } from '@/apis/onboarding'

import { updateUserDetail } from '@/apis/user'
import UserProfile from '@/common/userWrapper'
import { amplitudeTrackEvent } from '@/common/functions/eventTracker'
import { EVENTS_TO_BE_TRACKED,ONBOARDING_STEPS } from '@/common/constants'

export default {
  name: 'onboarding',
  components: {
    Input,
    Button,
    OnboardingStep1Footer,
    OnboardingLeftColumn,
    Heading,
    Select,
    CompanyLogo,
    SvgIcon,
    Checkboxes,
    Banner,
  },

  mounted() {
    this.loadRecaptcha()
  },

  data() {
    return {
      contact: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        password: '',
        organisationId: window.$cookies.get('organisationId') || null,
        orgUserId: window.$cookies.get('orgUserId') || null
      },
      showOtpBanner: false,
      phoneCodes: phoneCountryCodes.map(({ label }) => label),
      phoneCodeOption: 'United States',
      passwordFieldType: 'password',
      confirmPasswordFieldType: 'password',
      isLoading: false,
      isRecaptchaLoaded: false,
      recaptchaInstance: null,
      logo,
      schema: {
        Email: 'required|email|min:3|max:100',
        phone: 'phone_required|max:15|min:4',
        Password: 'required|password_min:12|password_no_spaces',
        confirmPassword: 'confirmPassword_required|password_min:12|password_mismatch:@Password',
        FirstName: 'required',
        LastName: 'required'
      },
      tncCheck: false,
      uaeOtpText: `<p class="flex-1 text-sm font-normal text-gray-900"> Currently, there is an issue with OTP delivery to UAE numbers. Please reach out to our support team for account verification. Link support to <span class=" font-semibold text-blue-600">support@texau.com</span></p>`
    }
  },
  watch: {
    phoneCodeOption(newVal, oldVal) {
      if (newVal === 'United Arab Emirates') {
        this.showOtpBanner = true
      } else {
        this.showOtpBanner = false
      }
    }
  },
  methods: {
    
    async updateNextStep() {
      try {
        const responseUser = await updateUserDetail({
          currentOnboardingStep: ONBOARDING_STEPS.ADDITIONAL_DETAILS,
          firstname: this.contact.firstName,
          lastname: this.contact.lastName
        })
        if (responseUser.success) {
          UserProfile.updateOnboardingStateInCookies(ONBOARDING_STEPS.ADDITIONAL_DETAILS)
        } else {
          throw new Error('Error in moving to next step.')
        }
      } catch (error) {
        this.$emit('error', error.message || 'Failed to update onboarding step')
      }
    },
    async createFirstWorkspace() {
      try {
        const organisationId = this.organisationId
        const orgUserId = this.orgUserId
      
        const response = await createWorkspace(
          organisationId,
          organisationId,
          orgUserId
        )

        if (response.success) {
          window.$cookies.set('workspaceId', response.data.workspaceId, -1, '/')
          window.$cookies.set('orgUserId', response.data.orgUserId, -1, '/')
        } else {
          throw new Error(response.message)
        }
      } catch (error) {
        this.$emit('error', error.message || 'Failed to create workspace')
      }
    },
    async internalLogin() {
      try {
        // Finish registration process
        this.registerFinished()
        const { firstname,lastname,email, phone, password } = this.contact
        // Login the user internally
        const loginResponse = await login(email,password)

        if (loginResponse.success) {
          // Successful login
          localStorage.setItem('email', email)
          this.loginSuccess({ payload: loginResponse.data })
          
          await this.updateNextStep()

          this.$router.go() // Refresh the page to reflect login changes
          
          await this.createFirstWorkspace()
        } else {
          // Login failed, redirect to login page
          await this.$router.push('/login')
          throw new Error(loginResponse.message)
        }
      } catch (error) {
        // Handle errors
        this.$emit('error', error.message || 'An error occurred')
      } finally {
        this.isLoading = false // Ensure loading state is reset
      }
    },
    // Map Vuex actions
    ...mapActions('registration', [
      'registerFinished',
      'registerStarted',
      'loginSuccess'
    ]),
    
    onLoginAccount() {
      this.$router.push('/login')
    },

    /**
     * Handles the submission of the create form and user registration process.
     * Validates the phone number, triggers the registration request, and processes the response.
     * If the phone number is invalid, it emits an error event and stops the process.
     * On successful registration, it navigates the user to the confirmation page.
     *
     * @async
     * @returns {Promise<void>} - Returns nothing but handles the registration flow asynchronously.
     */
    async submitCreate() {
      this.isLoading = true

      try {
        // Execute reCAPTCHA and get token
        if (!this.recaptchaInstance) {
          throw new Error('reCAPTCHA not initialized')
        }

        const token = await this.recaptchaInstance.execute(
          constants.recaptchaSiteKey,
          {
            action: 'submit'
          }
        )

        // Validate the phone number
        if (!this.validatePhoneNumber()) {
          this.$emit(
            'error',
            'Invalid phone number. Please enter a valid number.'
          )
          return
        }

        // Destructure necessary values from the contact object
        const { firstname,lastname,email, phone, password } = this.contact
        const newPhone = `${this.phoneCode}${phone}`

        // Register the user with recaptcha token
        let response
        if (window.tolt_referral) {
          response = await register(
            firstname,
            lastname,
            email,
            newPhone,
            password,
            window.tolt_referral,
            token
          )
        } else {
          response = await register(firstname,lastname,email, newPhone, password, null, token)
        }

        // Handle registration success
        this.registerStarted({
          payload: {
            firstname,
            lastname,
            email,
            newPhone,
            password,
            isV1User: response.data?.isV1User
          }
        })
        if (response.success) {
          await this.internalLogin()
        } else {
          if (response.errors && response.errors.length > 0) {
            this.$emit('error', response.errors[0].message)
          } else {
            this.$emit('error', response.message)
          }
        }
      } catch (error) {
        // Handle unexpected errors
        this.$emit(
          'error',
          'An unexpected error occurred. Please try again later.'
        )
        console.error('Error during registration:', error)
      } finally {
        // Ensure loading state is reset in all cases
        this.isLoading = false
      }
    },

    onRightIconClick(field) {
      if (field === 'password') {
        this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
      } else if (field === 'confirmPassword') {
        this.confirmPasswordFieldType = this.confirmPasswordFieldType === 'password' ? 'text' : 'password';
      }
    },

    /**
     * Validates the phone number using the country code and the phone number provided.
     * Combines the `phoneCode` and `contact.phone` values, parses it, and checks if it is a valid phone number.
     *
     * @returns {boolean} - Returns `true` if the phone number is valid, otherwise `false`.
     *                      In case of an error during parsing, it logs the error and returns `false`.
     */
    validatePhoneNumber() {
      try {
        const phoneNumber = parsePhoneNumberFromString(
          `${this.phoneCode}${this.contact.phone}`
        )
        return phoneNumber && phoneNumber.isValid()
      } catch (error) {
        console.log('Error parsing phone number:', error)
        return false
      }
    },
  
    loadRecaptcha() {
      if (typeof window === 'undefined') return

      const script = document.createElement('script')
      script.src = `https://www.google.com/recaptcha/api.js?render=${constants.recaptchaSiteKey}`
      script.async = true
      script.defer = true

      script.onload = () => {
        this.isRecaptchaLoaded = true
        window.grecaptcha.ready(() => {
          this.recaptchaInstance = window.grecaptcha
        })
      }

      script.onerror = error => {
        this.$emit('error', error)
      }

      document.head.appendChild(script) 
    }
  },
  computed: {
    phoneCode() {
      return phoneCountryCodes.find(
        ({ label }) => label === this.phoneCodeOption
      )?.value
    }
  }
}
</script>

<style></style>