<template>
  <div
    class="h-full overflow-x-hidden bg-white font-sans text-gray-900 antialiased dark:bg-gray-900 dark:text-gray-50"
  >
    <!-- START WRAPPER -->
    <div class="flex min-h-screen items-stretch justify-between">
      <!-- START LEFT COLUMN -->
      <OnboardingLeftColumn />
      <!-- END LEFT COLUMN -->

      <!-- START RIGHT COLUMN -->
      <div class="flex-1 bg-white dark:bg-gray-900">
        <div class="grid h-full items-start justify-center">
          <div class="h-full px-6 sm:px-12 ">
            <div class="my-6">
              <a href="#" title="TexAu" class="">
                <img
                  class="mx-auto h-12 w-auto "
                  src="@/assets/images/logo-alt.png"
                  alt="TexAu logo"
                />
              </a>

              <div class="mt-12 grid h-full place-items-center">
                <Heading text="Import Accounts" />
                <div>
                  <div class="mt-12 text-center">
                    <ConnectNewAccountModal
                      v-if="modalActive"
                      :integrationData="integrationData"
                      @close="modalActive = false" 
                      @goBack="goBackToAccounts"
                      @updateNotificationApiResponse="proxyChangeResponse"
                    />

                    <ViewPlatforms 
                      :showBackButton="false"
                      @showConnect="showConnect"
                      @proxyChangeResponse="proxyChangeResponse"
                    />

                    <div class="mt-8 flex items-center justify-center">
                      <Button
                        :show-loader="skipLoading"
                        right-icon="right-arrow"
                        color="secondary"
                        text="Skip"
                        @click="onSkipImportClick"
                        rightIcon="right-arrow"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END RIGHT COLUMN -->
    </div>
    <!-- END WRAPPER -->
  </div>
</template>

<script>
import OnboardingLeftColumn from '@/components/onboarding/OnboardingLeftColumn.vue'
import Heading from '@/components/onboarding/Heading.vue'
import Button from '@/components/Button.vue'
import { updateUserDetail } from '@/apis/user'
import { amplitudeTrackEvent } from '@/common/functions/eventTracker'
import UserProfile from '@/common/userWrapper'
import ViewPlatforms from '@/components/settings/WorkspaceManagerCloud/ViewPlatforms.vue'
import ConnectNewAccountModal from '@/components/settings/integration/connectNewAccountModal.vue'

export default {
  // step-4
  name: 'triggerExtension',
  components: {
    OnboardingLeftColumn,
    Heading,
    Button,
    ViewPlatforms,
    ConnectNewAccountModal
  },

  data() {
    return {
      isLoading: false,
      skipLoading: false,
      integrationData: null,
      modalActive: false
    }
  },
  
  methods: {
    showConnect(data) {
      this.integrationData = data
      this.modalActive = true
    },
    goBackToAccounts() {
      this.modalActive = false
      this.integrationData = null; // Reset to prevent stale data issues
      this.currentComp = 'accountTable'
    },
    proxyChangeResponse(data) {
      if (!data || typeof data !== 'object') {
        console.warn('Invalid response data:', data);
        return;
      }

     const message = data.msg || data.value || 'No message provided';
    
      if (data.type === 'success') {
        this.$emit('success', message);
      } else if (data.type === 'error') {
        this.$emit('error', message);
      } else {
        console.warn('Unexpected response type:', data.type);
        this.$emit('error', 'Unexpected response type');
      }
    },
    async updateNextStep() {
      const responseUser = await updateUserDetail({
        currentOnboardingStep: 'Importing'
      })
      UserProfile.updateOnboardingStateInCookies('Importing') 
      this.isLoading = false
      if (responseUser['success']) {
        await this.$router.push({
          path: '/onboarding/importing',
          query: {
            newImport: false
          }
        })
      } else {
        this.$emit('error', 'Error Occurred!')
      }
    },

    async onImportClick(e) {
      // this.isLoading = true;
      if (e.currentTarget.innerText === 'Install Extension') {
        //TODO: open chrome extension store link in new tab
        var newTab = window.open(
          'https://chromewebstore.google.com/detail/texau-v2/hbhdepogdnphlloancjiifodpchlkeam',
          '_blank'
        )
        newTab.focus()
      } else {
        await this.updateNextStep()
      }
    },
    async onSkipImportClick() {
      this.skipLoading = true
      const responseUser = await updateUserDetail({
        isOnboarded: true,
        currentOnboardingStep: null
      })
      let user_ = UserProfile.getUser()
      user_['isOnboarded'] = true
      delete user_['currentOnboardingStep']
      UserProfile.setUser(user_)
      this.skipLoading = false
      if (responseUser['success']) {
        amplitudeTrackEvent(
          'Sing-up Completed',
          localStorage.getItem('email'),
          {
            signupMethod: 'Email'
          }
        )
        await this.$router.go()
      } else {
        this.$refs.toast.addToast({
          timeout: 7000,
          text: 'Error Occurred!',
          color: 'red',
          caption: 'Error in moving to next step.',
          action: false,
          close: true
        })
      }
    }
  }
}
</script>

<style></style>
