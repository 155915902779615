<template>
  <div class="flex items-center">
    <button
      type="button"
      class="elative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-400 transition-colors duration-200 ease-in-out hover:outline-none hover:ring-2 hover:ring-blue-600 hover:ring-offset-2 dark:bg-blue-500 dark:hover:ring-blue-500 dark:hover:ring-offset-gray-900"
      role="switch"
      name="switch"
      aria-checked="false"
      :disabled="disabled"
      :aria-checked="modelValue.toString()"
      @click="onClick"
      :class="{
        '!bg-blue-600 dark:bg-blue-500': modelValue,
        'bg-gray-400 dark:bg-gray-500': !modelValue,
        'outline-none ring-2 ring-blue-600 ring-offset-2 dark:ring-blue-500 dark:ring-offset-gray-900': showFocus
      }"
    >
      <span
        aria-hidden="true"
        class="pointer-events-none inline-block h-5 w-5 translate-x-0 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
        :class="{
          'translate-x-5': modelValue,
          'translate-x-0': !modelValue
        }"
      ></span>
    </button>
    <span v-if="label || caption" class="ml-4 flex flex-grow flex-col" id="">
      <span
        v-if="label"
        class="text-sm font-semibold text-gray-900 dark:text-gray-50"
      >
        {{ label }}
      </span>
      <span v-if="caption" class="text-sm text-gray-500 dark:text-gray-400">
        {{ caption }}
      </span>
    </span>
  </div>
</template>

<script setup>
import { ref, watch , onMounted, onUnmounted  } from 'vue'

const props = defineProps({
  modelValue: {
    type: Boolean
  },
  label: {
    type: String,
    default: undefined
  },
  caption: {
    type: String,
    default: undefined
  },
  disabled: {
    type: Boolean,
    default: false
  }
})
const showFocus = ref(false)

const emit = defineEmits(['update:modelValue', 'change'])
const onClick = () => {
  emit('update:modelValue', !props.modelValue)
  emit('change')
}

const handleOutsideClick = (event) => {
  // Check if the click target is outside the component
  if (showFocus.value === true) {
    showFocus.value = false
  }
}
watch(() => props.modelValue, () => {
  showFocus.value = true
})

onMounted(() => {
  // Add event listener for clicks anywhere in the document
  document.addEventListener('click', handleOutsideClick, true)
})

onUnmounted(() => {
  // Remove event listener when component is unmounted
  document.removeEventListener('click', handleOutsideClick, true)
})
</script>
