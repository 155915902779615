import axios from '@/common/axios'

const fetchAll = async (
  start = 0,
  limit = 20,
  q = '',
  sortField,
  status,
  connectedAccountId,
  platformId,
  sortOrder,
  createdFrom,
  signal,
  platformOperationId,
  startDate,
  endDate
) => {
  try {
    const response = await axios.get(`/workflows`, {
      params: {
        start,
        limit,
        q,
        status,
        connectedAccountId,
        platformId,
        sortField,
        sortOrder,
        createdFrom,
        platformOperationId,
        startDate,
        endDate
      },
      signal
    })
    return response.data
  } catch (error) {
    return error?.response?.data
  }
}

const fetchCustomWorkflows = async (
  start = 0,
  limit = 20,
  q = '',
  platformId
) => {
  try {
    const response = await axios.get(`/workflows/custom-workflow`, {
      params: {
        start,
        limit,
        q,
        platformId
      }
    })
    return response.data
  } catch (error) {
    return error?.response?.data
  }
}

const getWorkflowStatus = async ids => {
  try {
    const response = await axios.get(`/workflows/status?workflowIds=${ids}`)
    return response.data
  } catch (error) {
    return error?.response?.data
  }
}

const createWorkflow = async (
  source,
  name,
  description,
  createdFrom,
  platformName = '',
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  desktop = false,
  fingerprint
) => {
  try {
    const response = await axios.post(`/workflows`, {
      inputSource: source,
      name,
      description,
      timezone,
      createdFrom,
      desktop,
      automationName: name,
      platformName,
      fingerprint
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const getWorkflow = async workflowId => {
  try {
    const response = await axios.get(`/workflows/${workflowId}`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const updateWorkflow = async (workflowId, data) => {
  try {
    const response = await axios.put(`/workflows/${workflowId}`, data)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const deleteWorkflow = async workflowId => {
  try {
    const response = await axios.delete(`/workflows/${workflowId}`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}
const bulkDeleteWorkflow = async data => {
  try {
    const response = await axios.delete(`/workflows/bulk`, {
      data
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const resetWorkflow = async workflowId => {
  try {
    const response = await axios.post(`/workflows/${workflowId}/reset`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const getWorkflowAllNodes = async workflowId => {
  try {
    const response = await axios.get(`/workflows/${workflowId}/nodes`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}
const addWorkflowNode = async (workflowId, nodeData) => {
  try {
    const response = await axios.post(
      `/workflows/${workflowId}/nodes`,
      nodeData
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const updateWorkflowNode = async (workflowId, nodeId, updateData) => {
  try {
    const response = await axios.put(
      `/workflows/${workflowId}/nodes/${nodeId}`,
      updateData
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const getWorkflowNode = async (workflowId, nodeId) => {
  try {
    const response = await axios.get(`/workflows/${workflowId}/nodes/${nodeId}`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const deleteWorkflowNodes = async (workflowId, nodeIds) => {
  try {
    const response = await axios.delete(`/workflows/${workflowId}/nodes`, {
      data: {
        nodeIds
      }
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const getWorkflowExecution = async (workflowId, start = 0, limit = 50) => {
  try {
    const response = await axios.get(
      `/workflows/${workflowId}/executions?start=${start}&limit=${limit}`
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}
const getWorkflowExecutionById = async executionId => {
  try {
    const response = await axios.get(`/executions/${executionId}`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const getWorkflowExecutionAllNodesById = async executionId => {
  try {
    const response = await axios.get(`/executions/${executionId}/nodes`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const postExecution = async workflowId => {
  try {
    const response = await axios.post(`/workflows/${workflowId}/executions`, {
      desktop: false
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const deleteExecution = async data => {
  try {
    const response = await axios.delete(`/executions`, { data })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const previewData = async (
  executionId,
  nodeId,
  start = 0,
  limit = 100,
  manipulationConfig,
  signal,
  all = 0
) => {
  try {
    const response = await axios.get(
      `/executions/${executionId}/nodes/${nodeId}/result?start=${start}&limit=${limit}&all=${all}`,
      {
        params: {
          sortField: manipulationConfig?.sortField,
          sortOrder: manipulationConfig?.sortOrder,
          filters: JSON.stringify(manipulationConfig?.filters || {}),
          q: manipulationConfig?.search
        },
        signal
      }
    )
    return response.data
  } catch (error) {
    return error?.response?.data || error.message
  }
}

const cloneWorkflow = async workflowId => {
  try {
    const response = await axios.post(`/workflows/${workflowId}/clone`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const pauseSchedule = async workflowId => {
  try {
    const response = await axios.post(
      `/workflows/${workflowId}/executions/pause`
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const reRunExecution = async executionId => {
  try {
    const response = await axios.post(`/executions/${executionId}/rerun`, {
      desktop: false
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const executionStatusChange = async (executionId, status) => {
  try {
    const response = await axios.post(`/executions/${executionId}/${status}`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

//Public workflows
const getPublicWorkflows = async (start = 0, limit = 10, q = '') => {
  try {
    const response = await axios.get(`/public-workflows/public`, {
      params: {
        start,
        limit,
        q
      }
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const clonePublicWorkflow = async (workflowId, timezone) => {
  try {
    const response = await axios.post(`/workflows/${workflowId}/clone`, {
      timezone
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const getPublicWorkflowInputs = async workflowId => {
  try {
    const response = await axios.get(`/workflows/${workflowId}/inputs`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const savePublicWorkflowInputs = async (workflowId, data) => {
  try {
    const response = await axios.post(`/workflows/${workflowId}/inputs`, data)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const getWorkflowLogs = async (workflowId, start = 0, limit = 10, logLevel) => {
  try {
    const response = await axios.get(`/workflow-logs/${workflowId}`, {
      params: {
        start,
        limit,
        logLevel
      }
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export {
  addWorkflowNode,
  cloneWorkflow,
  createWorkflow,
  deleteExecution,
  deleteWorkflow,
  resetWorkflow,
  deleteWorkflowNodes,
  fetchAll,
  fetchCustomWorkflows,
  getWorkflow,
  getWorkflowAllNodes,
  getWorkflowExecution,
  getWorkflowExecutionAllNodesById,
  getWorkflowExecutionById,
  getWorkflowNode,
  pauseSchedule,
  postExecution,
  previewData,
  updateWorkflow,
  updateWorkflowNode,
  reRunExecution,
  executionStatusChange,
  bulkDeleteWorkflow,
  getWorkflowStatus,
  getPublicWorkflows,
  clonePublicWorkflow,
  getPublicWorkflowInputs,
  savePublicWorkflowInputs,
  getWorkflowLogs
}
