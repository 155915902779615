<template>
  <div class="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
    <div
      class="relative flex h-[90vh] w-full max-w-5xl flex-col rounded-lg bg-white shadow-xl dark:bg-gray-900"
    >
      <!-- Fixed Header -->
      <div class="flex-none border-b border-gray-200 p-6 dark:border-gray-700">
        <div class="flex items-center justify-between">
          <div class="flex items-center space-x-4">
            <h2 class="text-2xl font-medium text-gray-900 dark:text-white">
              Export to Google Sheets
            </h2>
          </div>
          <ModalCloseButton @click="emitClose" />
        </div>
      </div>

      <!-- Scrollable Content -->
      <div class="flex-1 overflow-y-auto p-6">
        <!-- Loading State -->
        <div
          v-if="loading"
          class="flex min-h-[400px] items-center justify-center"
        >
          <Spinner size="large" />
        </div>

        <!-- Content -->
        <div v-else class="space-y-6">
          <!-- Account Selection -->
          <div class="space-y-4">
            <div class="flex justify-between" :class="{'items-center': appendToExistingSheet === false}">
              <div v-if="appendToExistingSheet === false" class="space-y-2">
                <label
                  class="text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  Google Account
                </label>
                <div class="w-[400px]">
                  <Select
                    :options="choices"
                    v-model="accountId"
                    placeholder="Select a Google account"
                  />
                </div>
              </div>
              <div class="w-[500px]" v-if="appendToExistingSheet === true">
                <ValidationForm
                  :key="inputData"
                  class="mt-4"
                  :platformInputs="platformInputs"
                  :inputs="inputData"
                  :previousNodes="previousNodes"
                  @input-blur="onInputBlur"
                  />
              </div>

              <div v-if="userRole !== 'viewer'" class="text-center">
                <p
                  class="mb-2 text-sm text-left px-5 font-medium text-gray-700 dark:text-gray-300"
                >
                  Add New Account
                </p>
                <div
                  @click="addOauthAccount()"
                  class="inline-flex items-center space-x-2 rounded-md bg-white p-2 shadow-sm transition hover:bg-gray-100 cursor-pointer dark:bg-gray-800 dark:hover:bg-gray-700"
                >
                  <img
                    src="@/assets/images/logos/btn_google_signin_dark_normal_web@2x.png"
                    alt="Google"
                    class="h-[46px]"
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
          <Popper v-if="appendToExistingSheet === false && fromBuilder === false" hover class="flex space-x-4 w-[300px]">
            
            <Button
              :left-icon="appendLoader ? null : 'plus'"
              size="small"
              :showLoader="appendLoader"
              @click="onAppendToExistingSheet"
              class="flex-1 bg-green-500 text-white hover:bg-green-600"
            >
              Append to Existing Sheet
            </Button>
            <template #content>
              <div class="w-max max-w-xl rounded bg-blue-50 p-2 text-sm">
                By default, we create a new sheet. Click to append data to an existing sheet.
              </div>
            </template>
          </Popper hover>

          
            
            <Button
            v-if="appendToExistingSheet === true"
              left-icon="left-arrow"
              size="small"
              @click="onCreateNewSheet"
             
            >
              Go Back 
            </Button>
            
         
        </div>

          <!-- Export Mode Selection -->
          <div class="space-y-4 rounded-lg bg-gray-50 p-4 dark:bg-gray-800">
            <h3 class="text-sm font-medium text-gray-700 dark:text-gray-300">
              Export Mode
            </h3>

            <div class="flex space-x-6">
              <label class="flex items-center space-x-3">
                <input
                  type="radio"
                  name="exportMode"
                  value="default"
                  v-model="selectedMode"
                  class="h-4 w-4 text-blue-600 focus:ring-blue-500"
                />
                <span class="text-sm text-gray-700 dark:text-gray-300"
                  >Default</span
                >
              </label>

              <label
                v-if="userPreferredLabels?.length > 0"
                class="flex items-center space-x-3"
              >
                <input
                  type="radio"
                  name="exportMode"
                  value="preferred"
                  v-model="selectedMode"
                  class="h-4 w-4 text-blue-600 focus:ring-blue-500"
                />
                <span class="text-sm text-gray-700 dark:text-gray-300"
                  >Preferred</span
                >
              </label>

              <label class="flex items-center space-x-3">
                <input
                  type="radio"
                  name="exportMode"
                  value="custom"
                  v-model="selectedMode"
                  class="h-4 w-4 text-blue-600 focus:ring-blue-500"
                />
                <span class="text-sm text-gray-700 dark:text-gray-300"
                  >Custom</span
                >
              </label>
            </div>
          </div>

          <!-- Mode Content -->
          <div class="space-y-4">
            <!-- Default Mode -->
            <div v-if="selectedMode === 'default'" class="space-y-4">
              <div class="grid grid-cols-2 gap-4 md:grid-cols-3">
                <div
                  v-for="data in listData"
                  :key="data._id"
                  class="rounded-md border border-gray-200 bg-gray-50 p-[10px] dark:border-gray-700 dark:bg-gray-800"
                >
                  <p class="text-sm text-gray-700 dark:text-gray-300">
                    {{ data.label }}
                  </p>
                </div>
              </div>
              <p class="mt-4 text-xs text-gray-500 dark:text-gray-400">
                All columns will be exported to Google Sheets
              </p>
            </div>

            <!-- Preferred Mode -->
            <div v-if="selectedMode === 'preferred'" class="space-y-4">
              <div v-if="userPreferredLabels?.length > 0">
                <div class="grid grid-cols-2 gap-4 md:grid-cols-3">
                  <div
                    v-for="item in userPreferredLabels"
                    :key="item.label"
                    class="rounded-md border border-gray-200 bg-gray-50 p-[10px] dark:border-gray-700"
                  >
                    <p class="text-sm text-gray-700 dark:text-gray-300">
                      {{ item.label }}
                    </p>
                  </div>
                </div>
                <p class="mt-4 text-xs text-gray-500 dark:text-gray-400">
                  These are your saved preferred settings. Switch to Custom mode
                  to modify.
                </p>
              </div>
              <div v-else class="text-center text-sm text-gray-500">
                No preferred settings saved. Create custom settings first.
              </div>
            </div>

            <!-- Custom Mode -->
            <div v-if="selectedMode === 'custom'" class="space-y-4">
              <!-- Enhanced Save as Preferred Settings Checkbox -->

              <div
                v-if="fromBuilder === false"
                class="mt-6 rounded-lg border border-blue-100 bg-blue-50 p-4 dark:border-blue-800 dark:bg-blue-900/30"
              >
                <label class="flex items-center justify-between cursor-pointer">
                  <div class="flex items-center space-x-3">
                    <input
                      type="checkbox"
                      v-model="saveAsPreferred"
                      class="h-5 w-5 rounded text-blue-600 focus:ring-blue-500 focus:ring-offset-blue-50 dark:focus:ring-offset-blue-900"
                    />
                    <div>
                      <span
                        class="font-medium text-blue-900 dark:text-blue-100"
                      >
                        Save as preferred settings
                      </span>
                      <p class="text-xs text-blue-700 dark:text-blue-300 mt-1">
                        These selected columns will be saved for future exports
                      </p>
                    </div>
                  </div>
                </label>
              </div>
              <!-- Search Bar (shows only in custom mode when labels > 9) -->
              <div v-if="listData?.length > 9" class="mb-4">
                <div class="relative">
                  <input
                    type="text"
                    v-model="searchQuery"
                    placeholder="Search labels..."
                    class="w-full rounded-lg border border-gray-200 pl-10 pr-4 py-2 text-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                  />
                  <SvgIcon
                    name="search"
                    class="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-400"
                  />
                </div>
              </div>

              <div class="flex items-center justify-between">
                <h4
                  class="text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  Select {{ fromBuilder ? 'Steps' : 'Columns' }}
                </h4>
                <label class="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    v-model="allCheckboxTick"
                    @click="onAllClick"
                    class="h-4 w-4 rounded text-blue-600 focus:ring-blue-500"
                  />
                  <span class="text-sm text-gray-600 dark:text-gray-400"
                    >Select All</span
                  >
                </label>
              </div>

              <div class="grid grid-cols-2 gap-4 md:grid-cols-3">
                <div
                  v-for="data in filteredListData"
                  :key="data._id"
                  class="rounded-md border border-gray-200 p-3 dark:border-gray-700"
                >
                  <label class="flex items-center space-x-3">
                    <input
                      type="checkbox"
                      :checked="isItemSelected(data)"
                      @change="event => onClick(event, data.label, data._id)"
                      class="h-4 w-4 rounded text-blue-600 focus:ring-blue-500"
                    />
                    <span class="text-sm text-gray-700 dark:text-gray-300">
                      {{ data.label }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <!-- Success Banner -->
          <div
            v-if="bannerMessage"
            class="rounded-md bg-green-50 p-4 dark:bg-green-900/30"
          >
            <p class="text-sm text-green-700 dark:text-green-300">
              {{ bannerMessage }}
            </p>
          </div>
        </div>
      </div>

      <!-- Fixed Footer -->
      <div class="flex-none border-t border-gray-200 p-6 dark:border-gray-700">
        <div class="flex items-center justify-between">
          <Button
            v-if="gSheetUrl"
            :text="copied ? 'URL Copied!' : 'Copy Sheet URL'"
            :left-icon="copied ? 'check' : 'copy'"
            @click="copyClick"
            color="secondary"
          />
          <div class="flex space-x-3">
            <Button text="Cancel" @click="emitClose" color="tertiary" />
            <Button
              :text="saveAsPreferred ? 'Save and Export' : 'Export'"
              :showLoader="buttonLoader"
              :disabled="selectedMode === 'custom' && labelArray.length === 0"
              @click="onSave"
              color="primary"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Input from '@/components/Input.vue'
import Select from '../Select.vue'
import Spinner from '@/components/Spinner.vue'
import { mapActions, mapState } from 'vuex'
const googlePlatformId = import.meta.env.VITE_GOOGLE_SHEETS_PLATFORMID
import { WORKFLOW_CREATED_FROM, constants } from '@/common/constants'
//api
import { getConnectedAccounts } from '@/apis/automation-store/Page1'
import { openResultsInGoogleSheets } from '@/apis/automation-store/googleSheets'
import {
  getWorkflow,
  getWorkflowAllNodes,
  getWorkflowNode
} from '@/apis/workflows'
import { savePreferredLabels, getPreferredLabels } from '@/apis/workspace'
import { getOperationVariableServices } from '@/apis/getPlatformDetails'
import Checkboxes from '../Checkboxes.vue'
import { startOauthConnection } from '@/apis/oauth'
import ModalCloseButton from '@/components/ModalCloseButton.vue'
import Banner from '../Banner.vue'
import { amplitudeTrackEvent } from '@/common/functions/eventTracker'
import Popper from 'vue3-popper'
import ValidationForm from '@/components/ValidationForm.vue'

export default {
  name: 'GoogleSheetsModal',
  data() {
    return {
      choices: null,
      loading: true,
      accountId: null,
      labelArray: [],
      isAllClicked: true,
      allCheckboxTick: true,
      buttonLoader: false,
      listData: null,
      fromBuilder: false,
      channel: null,
      gSheetUrl: null,
      copied: false,
      bannerMessage: null,
      selectedMode: 'default', // can be 'default', 'preferred', or 'custom'
      saveAsPreferred: false,
      userPreferredLabels: null,
      automationId: null,
      searchQuery: '',
      appendToExistingSheet: false,
      platformInputs: null,
      previousNodes: null,
      inputs: null,
      appendData: null,
      validationResult: false,
      appendLoader: false,
      inputData: {},
    }
  },
  components: {
    Button,
    SvgIcon,
    Input,
    Spinner,
    Select,
    Checkboxes,
    ModalCloseButton,
    Banner,
    Popper,
    ValidationForm,
  },
  async mounted() {
    try {
      await this.initialize()
    } catch (error) {
      this.$emit('error', error)
    } finally {
      this.loading = false
    }
  },
  computed: {
    ...mapState('automationStore', ['automationStoreData']),
    ...mapState('settings', ['userRole']),
    /**
     * Filters the list data based on search query
     * @returns {Array} Filtered list of items
     */
    filteredListData() {
      if (!this.searchQuery) return this.listData
      const query = this.searchQuery.toLowerCase()
      return this.listData.filter(item =>
        item.label.toLowerCase().includes(query)
      )
    },
    /**
     * Filters the preferred labels based on search query
     * @returns {Array} Filtered list of preferred items
     */
    filteredPreferredLabels() {
      if (!this.searchQuery) return this.userPreferredLabels
      const query = this.searchQuery.toLowerCase()
      return this.userPreferredLabels.filter(item =>
        item.label.toLowerCase().includes(query)
      )
    }
  },
  props: {
    modalData: { type: Object }
  },
  methods: {
    emitClose() {
      this.$emit('close')
    },
    async onClick(e, label, id) {
      this.allCheckboxTick = false
      const isChecked = e.target.checked

      if (isChecked) {
        this.addToLabelArray(label, id)
      } else {
        this.removeFromLabelArray(label, id)
      }
    },
    onAllClick(e) {
      if (e.target.checked) {
        this.allCheckboxTick = true
        this.isAllClicked = true
        this.refillArray()
      } else {
        this.allCheckboxTick = false
        this.isAllClicked = false
        this.labelArray = []
      }
    },
    refillArray() {
      this.labelArray = this.listData.map(data => data.label)
    },
    async onSave() {
      if(this.appendToExistingSheet === true){
       if(this.validationResult === false){
        this.$emit('error', 'Please make sure to select spreadSheet')
        return
       }
      }
      amplitudeTrackEvent('Data Exported', localStorage.getItem('email'), {
        exportType: 'Google Sheets'
      })
      this.buttonLoader = true
      try {
        await this.processExport()
      } catch (error) {
        this.$emit('error', error)
      } finally {
        this.buttonLoader = false
      }
    },
    async processExport() {
      const exportData = this.buildExportData()

      if (this.selectedMode === 'custom' && this.saveAsPreferred) {
        await this.handleCustomExportWithPreferred(exportData)
      } else {
        await this.handleStandardExport(exportData)
      }
    },
    buildExportData() {
      const data = {
        executionId:
          this.$route.query.executionId ?? this.modalData.executionId,
        connectedAccountId: this.accountId,
        mode: this.selectedMode
      }

      if (this.selectedMode !== 'default') {
        const labels =
          this.selectedMode === 'preferred'
            ? this.userPreferredLabels.map(item =>
                this.fromBuilder ? item._id : item.label
              )
            : this.labelArray

        this.fromBuilder ? (data.nodeIds = labels) : (data.columns = labels)
      }

      return data
    },
    async handleStandardExport(data) {
      if (!data.connectedAccountId) {
        throw 'Please select a Google account'
      }

      const baseParams = {
        executionId: data.executionId,
        connectedAccountId: data.connectedAccountId
      }

      if(this.appendToExistingSheet === true){
    baseParams.sheetDetails = {
    googleSpreadSheetId: this.appendData.googleSpreadsheetId,
    googleSheetId: this.appendData.googleSheetId
  }
      }

      const exportParams = {
        default: () => {
          if (this.fromBuilder) {
            const nodeIdArray = this.listData.map(item => item._id)
            return { ...baseParams, nodeIds: nodeIdArray }
          }
          return baseParams
        },
        preferred: () => ({
          ...baseParams,
          exportPreferredFields: true,
          platformOperationId: this.automationId
        }),
        custom: () => ({
          ...baseParams,
          ...(this.fromBuilder
            ? { nodeIds: data.nodeIds }
            : { columns: data.columns })
        })
      }

      const params = exportParams[this.selectedMode]()
      const googleSheetResponse = await openResultsInGoogleSheets(params)

      if (!googleSheetResponse.success) {
        throw googleSheetResponse.message
      }

      const successMessage = `${googleSheetResponse.data?.uiMessage}. You can visit the Exports Page to view and monitor the status of your Google Sheets exports.`
      
      this.$emit('success', successMessage)
      this.bannerMessage = successMessage
      this.gSheetUrl = googleSheetResponse.data?.sheetUrl
    },
    async handleCustomExportWithPreferred(data) {
      const baseParams = {...data}

      if(this.appendToExistingSheet === true){
        if(this.validationResult === false){
          this.$emit('error', 'Please fill all the required fields')
          return
         }
          baseParams.sheetDetails = {
          googleSpreadSheetId: this.appendData.googleSpreadsheetId,
          googleSheetId: this.appendData.googleSheetId
        }
            }

      

      const [preferredLablesResponse, googleSheetResponse] = await Promise.all([
        savePreferredLabels(this.automationId, {
          preferredOutputs: data.columns
        }),
        openResultsInGoogleSheets(baseParams)
      ])
      const object = {
        data: {
          preferredOutputs: data.columns
        }
      }
      this.setupPreferredLabels(object)

      if (googleSheetResponse['success']) {
        this.$emit(
          'success',
          `${googleSheetResponse.data?.uiMessage}. You can visit the Exports Page to view and monitor the status of your Google Sheets exports.`
        )
        this.bannerMessage = `${googleSheetResponse.data?.uiMessage}. You can visit the Exports Page to view and monitor the status of your Google Sheets exports.`
        this.gSheetUrl = googleSheetResponse.data?.sheetUrl
      } else {
        throw googleSheetResponse.message
      }
    },
    async addOauthAccount() {
      const response = await startOauthConnection('google-sheets')

      if (response['success']) {
        window.open(response.data.url, '_blank')
        this.emitClose()
      } else {
        this.$emit('error', 'Error in adding new google account')
      }
    },
    async copyClick() {
      await navigator.clipboard.writeText(this.gSheetUrl)
      this.copied = true

      setTimeout(() => {
        this.copied = false
      }, 2000)
    },
    /**
     * Initializes the component by fetching Google accounts and workflow data
     * @async
     * @throws {Error} If API calls fail
     */
    async initialize() {
      try {
        await this.fetchGoogleAccounts()
        await this.fetchWorkflowData()
        this.refillArray()
      } catch (error) {
        this.$emit('error', error)
      } finally {
        this.loading = false
      }
    },
    /**
     * Fetches connected Google accounts and sets up account choices
     * @async
     */
    async fetchGoogleAccounts() {
      const response = await getConnectedAccounts(googlePlatformId)
      this.choices = this.mapAccountsToChoices(response.data)
      if (this.choices.length > 0) {
        this.accountId = this.choices[0].value
      }
    },
    /**
     * Maps raw account data to select component choices
     * @param {Array} accounts - Raw account data from API
     * @returns {Array} Formatted choices for select component
     */
    mapAccountsToChoices(accounts) {
      return accounts.map(account => ({
        value: account._id,
        label: account.name
      }))
    },
    /**
     * Fetches workflow data based on workflow type and input source
     * @async
     */
    async fetchWorkflowData() {
      if (!this.automationStoreData.outputList) {
        const workflow = await getWorkflow(this.modalData.workflowId)

        if (workflow.data.createdFrom === WORKFLOW_CREATED_FROM.BUILDER) {
          await this.handleBuilderWorkflow()
        } else {
          if (
            this.modalData.inputSource === 'sheet' ||
            this.modalData.inputSource === 'csv'
          ) {
            //Since we are using sheet or csv, we need to get the nodes response and pass the second node id to the handleStandardWorkflow
            const nodesResponse = await getWorkflowAllNodes(
              this.modalData.workflowId
            )

            await this.handleStandardWorkflow(nodesResponse.data[1]._id)
          } else {
            await this.handleStandardWorkflow(workflow.data.startNode)
          }
        }
      } else {
        this.listData = this.automationStoreData.outputList
        this.automationId = this.automationStoreData.platformOperationId
        const preferredLabelsResponse = await this.fetchPreferredLabels(
          this.automationId
        )
        if (
          preferredLabelsResponse.data &&
          preferredLabelsResponse.data.preferredOutputs.length > 0
        ) {
          this.selectedMode = 'preferred'
          this.setupPreferredLabels(preferredLabelsResponse)
        }
      }
    },
    /**
     * Handles data fetching for builder-type workflows
     * @async
     */
    async handleBuilderWorkflow() {
      const response = await getWorkflowAllNodes(this.modalData.workflowId)
      this.listData = response.data.filter(
        item =>
          !item.hasOwnProperty('utilityType') ||
          item.utilityType === 'incomingwebhook' ||
          item.utilityType === 'outgoingwebhook'
      )
      this.fromBuilder = true
    },
    /**
     * Handles data fetching for standard workflows
     * @async
     * @param {string} startNode - Starting node ID
     */
    async handleStandardWorkflow(startNode) {
      const { nodeId, operationId, platformId } =
        await this.getWorkflowNodeData(startNode)
      this.automationId = operationId

      const [variableResponse, preferredLabelsResponse] = await Promise.all([
        this.fetchOperationVariables(platformId, operationId),
        this.fetchPreferredLabels(operationId)
      ])

      if (
        preferredLabelsResponse.data &&
        preferredLabelsResponse.data.preferredOutputs.length > 0
      ) {
        this.selectedMode = 'preferred'
        this.setupPreferredLabels(preferredLabelsResponse)
      }
      this.listData = variableResponse.data
    },
    /**
     * Handles checkbox selection for individual items
     * @param {Event} e - Click event
     * @param {string} label - Item label
     * @param {string} id - Item ID
     */
    handleItemSelection(e, label, id) {
      this.allCheckboxTick = false
      const isChecked = e.target.checked

      if (isChecked) {
        this.addToLabelArray(label, id)
      } else {
        this.removeFromLabelArray(label, id)
      }
    },
    /**
     * Adds an item to the label array based on workflow type
     * @param {string} label - Item label
     * @param {string} id - Item ID
     */
    addToLabelArray(label, id) {
      if (this.fromBuilder) {
        this.labelArray.push(id)
      } else {
        this.labelArray.push(label)
      }
    },
    /**
     * Removes an item from the label array based on workflow type
     * @param {string} label - Item label
     * @param {string} id - Item ID
     */
    removeFromLabelArray(label, id) {
      if (this.fromBuilder) {
        this.labelArray = this.labelArray.filter(data => data !== id)
      } else {
        this.labelArray = this.labelArray.filter(data => data !== label)
      }
    },
    /**
     * Fetches workflow node data
     * @async
     * @param {string} startNode - Starting node ID
     * @returns {Object} Parsed response data
     */
    async getWorkflowNodeData(startNode) {
      const response = await getWorkflowNode(
        this.modalData.workflowId,
        startNode
      )
      return this.parseResponse(response.data)
    },
    /**
     * Parses workflow node data response
     * @param {Object} data - Raw response data
     * @returns {Object} Parsed data
     */
    parseResponse(data) {
      return {
        nodeId: data._id,
        operationId: data.platformOperationId,
        platformId: data.platformId
      }
    },
    /**
     * Fetches operation variables for a given platform and operation
     * @async
     * @param {string} platformId - Platform ID
     * @param {string} operationId - Operation ID
     * @returns {Object} API response
     */
    async fetchOperationVariables(platformId, operationId) {
      const response = await getOperationVariableServices(
        platformId,
        operationId,
        'output',
        null,
        null
      )
      return response
    },
    /**
     * Fetches preferred labels for a given operation
     * @async
     * @param {string} operationId - Operation ID
     * @returns {Object} API response
     */
    async fetchPreferredLabels(operationId) {
      const response = await getPreferredLabels(operationId)

      return response
    },
    /**
     * Sets up preferred labels from API response
     * @param {Object} preferredLabelsResponse - API response
     */
    setupPreferredLabels(preferredLabelsResponse) {
      if (
        preferredLabelsResponse.data &&
        preferredLabelsResponse.data.preferredOutputs.length > 0
      ) {
        this.userPreferredLabels =
          preferredLabelsResponse.data.preferredOutputs.map((item, index) => ({
            label: item,
            _id: index
          }))
      }
    },
    /**
     * Checks if an item is currently selected
     * @param {Object} data - The item to check
     * @returns {boolean} Whether the item is selected
     */
    isItemSelected(data) {
      return this.fromBuilder
        ? this.labelArray.includes(data._id)
        : this.labelArray.includes(data.label)
    },
    /**
     * Adds an item to the label array
     * @param {string} label - Item label
     * @param {string} id - Item ID
     */
    addToLabelArray(label, id) {
      const valueToAdd = this.fromBuilder ? id : label
      if (!this.labelArray.includes(valueToAdd)) {
        this.labelArray.push(valueToAdd)
      }
    },
    /**
     * Removes an item from the label array
     * @param {string} label - Item label
     * @param {string} id - Item ID
     */
    removeFromLabelArray(label, id) {
      const valueToRemove = this.fromBuilder ? id : label
      this.labelArray = this.labelArray.filter(item => item !== valueToRemove)
    },
    /**
     * Handles "Select All" checkbox click
     * @param {Event} e - The click event
     */
    onAllClick(e) {
      const isChecked = e.target.checked
      this.allCheckboxTick = isChecked
      this.isAllClicked = isChecked

      if (isChecked) {
        this.refillArray()
      } else {
        this.labelArray = []
      }
    },
    /**
     * Refills the label array with all available items
     */
    refillArray() {
      this.labelArray = this.listData.map(data =>
        this.fromBuilder ? data._id : data.label
      )
    },
    async onAppendToExistingSheet() {
      try {
        this.appendLoader = true
        const response = await getOperationVariableServices(
          constants.googlePlatformId,
          constants.googlePlatformOperationId,
       
        )
        this.platformInputs = response.data.filter(item => item.name === 'googleSpreadsheetId' || item.name === 'googleSheetId')
        this.platformInputs.unshift({
        name: 'connectedAccountId',
        type: 'select',
        label: 'Select Google Account:',
        isRequired: true,
        choices: this.choices
      })
        this.inputData.connectedAccountId = this.choices[0].value
        this.appendToExistingSheet = true
      } catch (error) {
        this.$emit('error', error)
      } finally {
        this.appendLoader = false
      }
    },

    onCreateNewSheet(){
      this.validationResult = false
      this.appendData = null
      this.appendToExistingSheet = false
    },
    onInputBlur(inputs, validationResult, updatedField) {
        this.appendData = inputs
        this.validationResult = validationResult.valid
    }
  }
}
</script>
