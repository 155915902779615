<template>
  <div
    class="flex-1 flex flex-col justify-between overflow-y-auto overflow-x-hidden"
  >
    <div class="px-4 py-5 sm:p-5 flex items-center justify-center">
      <div class="max-w-4xl mt-6">
        <!-- TODO: will need later -->
        <!-- <Input
          class="mx-auto mb-3 max-w-lg"
          leftIcon="search"
          text="Search"
          @input="inputChange"
        /> -->
        <div class="mt-1 grid grid-cols-2 gap-6">
          <div
            class="transform w-[300px] rounded-lg border border-gray-200 bg-white transition-all duration-200 hover:-translate-y-1 hover:border-blue-600 hover:shadow-lg dark:border-gray-700 dark:bg-gray-900 dark:hover:border-blue-500"
            v-for="item in filteredData"
            :key="item._id"
          >
            <div
              @click="onClick(item)"
              class="px-4 cursor-pointer py-5 flex flex-col gap-4"
            >
              <div class="flex -space-x-2 overflow-hidden mx-auto w-auto">
                <img
                  v-for="img in item.logoUrl"
                  class=" mt-8 h-16  shrink-0 ring-2 ring-white rounded-full object-cover"
                  :src="img"
                />
              </div>

              <p
                class="text-sm font-semibold text-gray-900 dark:text-gray-50 text-center"
              >
                {{ item.label === 'LinkedIn' ? 'LinkedIn, Sales Navigator, Recruiter Lite' : item.label }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="this.btn==='back'" class="mt-auto flex px-4 py-5">
      <Button text="Go Back" color="secondary" @click="onClickBack()" />
    </div>
    <div v-else class="mt-auto flex px-4 py-5">
      <Button text="Skip" color="secondary" @click="onClickSkip()" />
    </div>
  </div>
</template>

<script>
import IntegrationsHeader from '@/components/settings/integrationsHeader.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Button from '@/components/Button.vue'

//API call
import { getAllIntegrations } from '@/apis/settings/settings-integration'
import { startOauthConnection } from '@/apis/oauth'
import Input from '@/components/Input.vue'

export default {
  name: 'ViewPlatforms',
  components: {
    IntegrationsHeader,
    SvgIcon,
    Button,
    Input
  },

  data() {
    return {
      filteredData: null,
      // ArrayData: null,
      listData: null,
      salesNavigatorUrl:
        'https://assets-of-v2.s3.amazonaws.com/platforms/logos/sales-navigator.svg',
      recruiterLiteUrl:
        'https://assets-of-v2.s3.amazonaws.com/platforms/logos/recruiter.svg',
      btn: localStorage.getItem('fromOnboarding') === 'true' ? 'skip' : 'back'
    }
  },
  mounted() {
    localStorage.removeItem('fromOnboarding')
  },
  props: {
    showBackButton: {
      type: Boolean,
      default: true
    }
  },
  created() {
    this.getIntegrations()
  },
  methods: {
    async getIntegrations() {
      if (sessionStorage.integrationData) {
        this.listData = JSON.parse(sessionStorage.integrationData)
        this.filterArray()
      } else {
        try {
          const response = await getAllIntegrations()
          sessionStorage.setItem(
            'integrationData',
            JSON.stringify(response.data)
          )
          this.listData = response.data

          this.filterArray()
        } catch (error) {
          this.$emit('emitError', {
            type: 'fail',
            value: 'Something Went Wrong Please Try Again Later'
          })
        }
      }
    },

    filterArray() {
      const { salesNavigatorUrl, recruiterLiteUrl } = this

      this.filteredData = this.listData
        .filter(({ auth, name }) => 
          auth?.type === 'cookie' && 
          !['Recruiter Lite', 'Sales Navigator'].includes(name)
        )
        .map(item => ({
          ...item,
          logoUrl: item.name === 'LinkedIn' 
            ? [item.logoUrl, salesNavigatorUrl, recruiterLiteUrl]
            : [item.logoUrl]
        }))
    },

    async modalToggle(item, e) {
      if (e.currentTarget.innerText === 'Connect Account') {
        return
      } else if (item.auth.type === 'OAuth2') {
        this.addOauthAccount(item.name)
      } else {
        let val = true
        item['update'] = false
        this.$emit('modalActivation', { item, val })
      }
    },
    async addOauthAccount(name) {
      const response = await startOauthConnection(name)

      if (response['success']) {
        window.open(response.data.url, '_blank')
      } else {
        this.$emit('error', 'Error in adding new google account')
      }
    },
    //TODO: will need later
    // inputChange(e) {
    //   const searchStr = e.target.value.toLowerCase()
    //   this.filteredData = this.ArrayData.filter(value => {
    //     return value.label.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1
    //   })
    // },
    onClick(item) {
      this.$emit('showConnect', item)
    },
    onClickBack() {
      this.$emit('showTable')
    },
    onClickSkip() {
      this.$router.push('/automation-store?platform=LinkedIn')
    }
  }
}
</script>

<style scoped></style>
