<template>
  <div
    class="fixed flex h-fit w-full flex-col items-center border-l border-gray-200 outline-none transition-all duration-300 ease-in-out"
    :class="{
      'bottom-[64px]': open,
      'bottom-[-1000px]': !open
    }"
    ref="modalEl"
  >
    <h3>History Timeline</h3>
    <div class="h-full w-fit max-w-2xl overflow-x-auto bg-white p-2 shadow">
      <Loader v-if="loading" class="m-auto w-[60vw] max-w-2xl" />
      <div v-else>
        <div
          v-if="executions.length === 0"
          class="w-[60vw] max-w-2xl p-2 text-center text-sm text-gray-500"
        >
          This workflow has no history.
        </div>
        <div class="flex flex-col gap-4" v-else>
          <div class="grid grid-flow-col gap-2">
            <div
              v-for="execution of executions"
              class="border-grey-500 group flex w-max cursor-pointer gap-2 rounded border-2 p-2 hover:border-blue-500 hover:bg-blue-50"
              @click="$emit('select', execution._id)"
              :class="{
                'border-blue-500 bg-blue-50':
                  $route.query.executionId === execution._id
              }"
            >
              <div>
                <div class="flex justify-center">
                  <svg-icon
                    :name="`status-${execution.status}`"
                    :class="{
                      'fill-yellow-400':
                        execution.status === 'running' ||
                        execution.status === 'paused' ||
                        execution.status === 'waiting' ||
                        execution.status === 'delayed',
                      'fill-green-500': execution.status === 'completed',
                      'fill-red-500':
                        execution.status === 'failed' ||
                        execution.status === 'partially-failed'
                    }"
                    class="h-6 w-6"
                  />
                </div>

                <div class="text-xs">
                  <div class="mt-2">
                    <div class="flex items-center">
                      <svg-icon
                        name="clock"
                        class="h-6 w-6"
                      />
                      <p>
                          Execution Started:
                      </p>
                    </div>
                    <p>
                      {{ formatTimestamp(execution.createdAt) }}
                    </p>
                  </div>
                  <div class="mt-2" v-if="execution.status==='completed'">
                    <div class="flex items-center">
                      <svg-icon
                        name="clock"
                        class="h-6 w-6"
                      />
                      <p>
                        Execution Completed:
                      </p>
                    </div>
                    <p>
                      {{ formatTimestamp(execution.updatedAt) }}
                    </p>
                  </div>
                </div>
              </div>
              <span
                class="invisible absolute z-50 top-0 ml-[41px] rounded bg-white border-[1px] p-1 text-sm capitalize group-hover:visible"
              >
                {{ execution.status
                }}{{
                  execution?.statusReason ? `: ${execution?.statusReason}` : ''
                }}
              </span>
            </div>

            <!-- Load More Button -->
            <div
              v-if="hasMoreExecutions"
              class="w-40 border-2 border-gray-200 rounded-lg p-2 transition-colors hover:border-gray-300"
            >
              <button
                @click="loadMore"
                class="w-full h-full flex items-center justify-center gap-2 text-gray-700 hover:text-blue-600 disabled:opacity-50 disabled:hover:text-gray-700 transition-colors"
                :disabled="buttonLoader"
              >
                <span v-if="buttonLoader" class="flex items-center gap-2">
                  <SvgIcon name="Loader" class="h-4 w-4" />
                  Loading...
                </span>
                <span v-else class="flex items-center gap-2">
                  <SvgIcon name="plus" class="h-5 w-5" />
                  Load More
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { getWorkflowExecution } from '@/apis/workflows'
import Loader from '@/assets/icons/Loader.vue'
import { onClickOutside } from '@vueuse/core'
import { onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import SvgIcon from '@/components/SvgIcon.vue'
import { formatTimestamp } from '@/common/functions/formatDateAndTime'

const props = defineProps({
  open: Boolean
})

const emit = defineEmits(['modal-close', 'select'])
const route = useRoute()

const executions = ref([])
const modalEl = ref()
const loading = ref(true)
const currentPage = ref(0)
const hasMoreExecutions = ref(true)
const ITEMS_PER_PAGE = 50
const buttonLoader = ref(false)

onClickOutside(modalEl, clicked => {
  if (clicked) {
    emit('modal-close')
  }
})

/**
 * Fetches the workflow executions for the current workflow.
 *
 * This function retrieves a paginated list of executions for a specific workflow
 * identified by the workflow ID from the route parameters. It updates the local
 * state with the fetched executions and manages loading states and pagination.
 *
 * @async
 * @function getExecutions
 * @returns {Promise<void>} A promise that resolves when the executions have been fetched and the state updated.
 *
 * @throws {Error} Logs any errors encountered during the fetch operation.
 */
const getExecutions = async () => {
  try {
    currentPage.value === 0 && (loading.value = true)
    const workflowId = route.params.id
    const response = await getWorkflowExecution(
      workflowId,
      currentPage.value * ITEMS_PER_PAGE,
      ITEMS_PER_PAGE
    )

    if (currentPage.value === 0) {
      executions.value = response.data
    } else {
      executions.value = [...executions.value, ...response.data]
    }

    hasMoreExecutions.value = executions.value.length < response.total
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
  }
}

const loadMore = async () => {
  buttonLoader.value = true
  currentPage.value++
  await getExecutions()
  buttonLoader.value = false
}

watch(
  () => props.open,
  open => {
    if (open) {
      currentPage.value = 0
      hasMoreExecutions.value = true
      getExecutions()
    }
  }
)

onMounted(() => {
  if (route.query.executionId) {
    currentPage.value = 0
    getExecutions()
  }
})
</script>
