<template>
  <div>
    <vee-form :validation-schema="schema" class="mt-10" @submit="submitEmail()">
      <div class="space-y-5">
        <div>
          <label
            class="block text-sm font-medium text-gray-900 dark:text-gray-50"
          >
            Email Address
          </label> 
          <div class="mt-1.5">
            <Input
              v-model="email"
              name="Email"
              text="john.doe@company.com"
              type="email"
            />
            <p class="mt-2 text-sm text-red-600">
              <ErrorMessage name="Email" />
            </p>
          </div>
        </div>
        <div>
          <Button
            :showLoader="isLoading"
            class="w-full"
            text="Send Code"
            type="submit"
          />
        </div>
      </div>
    </vee-form>
  </div>
</template>

<script>
import { forgotPassword } from '@/apis/onboarding'
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
import { constants } from '@/common/constants'

export default {
  name: 'GetMobileOtp',
  components: {
    Input,
    Button,
  },
  mounted() {
    this.loadRecaptcha()
  },
  data() {
    return {
      email: null,
      isLoading: false,
      isRecaptchaLoaded: false,
      recaptchaInstance: null,
    }
  },
  computed: {
    schema() {
      return {
        Email: 'required|email|min:3|max:100'
      }
    }
  },
  methods: {
    async submitEmail() {
      this.isLoading = true
      this.errorMessage = null
      
      try {
        // Execute reCAPTCHA and get token
        if (!this.recaptchaInstance) {
          throw new Error('reCAPTCHA not initialized')
        }

        const token = await this.recaptchaInstance.execute(
          constants.recaptchaSiteKey,
          {
            action: 'submit'
          }
        )

        const response = await forgotPassword(this.email,token)
        if (response?.success) {
          localStorage.setItem('otpSentVia', response.data.AttributeName)
          localStorage.setItem('otpSentTo', response.data.Destination)
          this.$emit('codeSend', {
            email: this.email,
            message: `Code sent to your registered ${response.data.AttributeName === 'phone_number' ? 'phone number' : 'email'}`,
            type: 'success'
          })
        } else {
          throw new Error(response?.message || 'Failed to send code')
        }
      } catch (error) {
        this.errorMessage = error.message || 'An error occurred'
        this.$emit('codeSend', {
          email: this.email,
          message: this.errorMessage,
          type: 'fail'
        })
      }finally {
        this.isLoading = false
      }
    },

    loadRecaptcha() {
       if (typeof window === 'undefined' || (window.grecaptcha && this.isRecaptchaLoaded && this.recaptchaInstance)) return

      const script = document.createElement('script')
      script.src = `https://www.google.com/recaptcha/api.js?render=${constants.recaptchaSiteKey}`
      script.async = true
      script.defer = true

      script.onload = () => {
        window.grecaptcha.ready(() => {
          this.recaptchaInstance = window.grecaptcha
          this.isRecaptchaLoaded = true
        })
      }

      script.onerror = error => {
        this.errorMessage = 'Failed to load reCAPTCHA'
        this.$emit('error', error)
      }

      document.head.appendChild(script) 
    }
  }
}
</script>

<style></style>
