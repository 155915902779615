import axios from '@/common/axios'
import UserProfile from '@/common/userWrapper'

const register = async (
  firstname,
  lastname,
  email,
  phone,
  password,
  referredBy = null,
  recaptchaToken = null
) => {
  try {
    const response = await axios.post('/auth/register', {
      firstname,
      lastname,
      email,
      phone,
      password,
      referredBy,
      recaptchaToken 
    })
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const confirmPhone = async (email, verificationCode) => {
  try {
    const response = await axios.post('/auth/confirm/phone', {
      email,
      verificationCode
    })
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const login = async (email, password) => {
  try {
    const response = await axios.post('/auth/login', {
      email,
      password
    })
    if (!response.data.data.isMfaEnabled) {
      UserProfile.setUser(response.data.data)
    }
    return response.data
  } catch (error) {
    return handleError(error, 'An error occurred while logging in.')
  }
}

const confirmLogin = async (email, session, code) => {
  try {
    const response = await axios.post('/auth/confirm/login', {
      email,
      session,
      code
    })
    UserProfile.setUser(response.data.data)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const confirmMail = async code => {
  try {
    const response = await axios.post(
      '/auth/confirm/email',
      {
        code
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const setupBaseUser = async () => {
  try {
    const response = await axios.post('/base-users/setup')
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const resendPhoneOtp = async body => {
  try {
    const response = await axios.post('/auth/verify/phone', body)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const resendMailOtp = async () => {
  try {
    const response = await axios.post('/auth/verify/email')
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const forgotPassword = async email => {
  try {
    const response = await axios.post('/auth/forgot-password', {
      email
    })
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const resetPassword = async data => {
  try {
    const response = await axios.post('/auth/reset-password', {
      ...data
    })
    return response.data
  } catch (e) {
    return e.response.data
  }
}

/**
 * Handles API errors, providing a fallback message if the error response is unavailable.
 * @param {Object} error - The error object caught from the Axios request.
 * @param {String} fallbackMessage - A fallback message to return if the error response is unavailable.
 * @returns {Object|String} - The error response data or the fallback message.
 */
const handleError = (error, fallbackMessage) => {
  return error?.response?.data || { message: fallbackMessage }
}

export {
  register,
  confirmPhone,
  login,
  confirmMail,
  setupBaseUser,
  confirmLogin,
  resendPhoneOtp,
  resendMailOtp,
  forgotPassword,
  resetPassword
}
