<template>
  <div v-if="noAccessFlag" class="flex-1 overflow-y-hidden px-6">
    <NoAccess />
  </div>
  <div v-else class="mt-4 flex flex-col overflow-y-auto px-6">
    <div class="flex">
      <div class="flex items-center h-[32px] justify-center">
        <label
          for=""
          class="flex mr-4 h-8 w-8 cursor-pointer items-center rounded bg-gray-50 p-1 text-sm font-medium text-gray-600 hover:bg-gray-100 dark:text-gray-50"
          v-if="selectedPlatform"
          @click="() => clearSearch()"
        >
          <svg-icon class="h-6 w-6 text-gray-400" name="left-arrow" />
        </label>
      </div>
      <div class="flex items-center justify-center">
        <label
          for=""
          class="block text-sm font-medium capitalize text-gray-900 dark:text-gray-50"
        >
          {{ searchLabel }}
        </label>
      </div>
    </div>
    <Popper :interactive="true" :show="showPopper">
      <div class="relative my-1.5 text-gray-400 focus-within:text-gray-600">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3">
          <SvgIcon name="search" />
        </div>

        <input
          type="text"
          name=""
          id=""
          class="block w-full !pr-10 rounded-lg border border-gray-300 py-2.5 pl-10 text-gray-900 placeholder-gray-500 caret-blue-600 transition-all duration-200 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:hover:border-gray-500 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
          placeholder="Search automation"
          v-debounce:300ms="keyValidate"
          :debounce-events="'keydown'"
          v-model="search"
          ref="selectInputContainer"
          @click="showPopper = false"
          @keyup.stop
        />
        <div
          v-if="selectedPlatform"
          class="absolute cursor-pointer inset-y-0 right-0 flex items-center pr-3 text-gray-400 dark:text-gray-50"
        >
          <SvgIcon
            @click="showPopper = !showPopper"
            :class="`h-5 w-5 `"
            name="down-arrow"
          />
        </div>
      </div>
      <template #content>
        <div
          class="dropdown-border bg-white flex flex-1 flex-col px-2 w-full space-y-1.5"
          :style="{ width: `${dropdownWidth}px` }"
        >
          <ul>
            <li
              v-for="(option, index) in categoryOptions"
              :key="option"
              @click="onCategoryClick(option.name)"
              class="align-start my-1 flex cursor-pointer items-center rounded-lg px-3 py-2 text-center text-sm font-medium text-gray-600 transition-all duration-200 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50"
              :class="{
                'bg-blue-50 text-blue-600 dark:bg-blue-500 dark:!text-blue-50':
                  selectedCategory === option.name
              }"
              aria-current="page"
            >
              <div
                class="mr-2 flex items-center justify-center rounded-md p-1"
                :class="[
                  {
                    'bg-blue-200 text-blue-600':
                      selectedCategory === option.name
                  },
                  { 'bg-slate-100': selectedCategory !== option.name }
                ]"
                v-if="option.icon"
              >
                <SvgIcon class="h-5 w-5" :name="option.icon" />
              </div>
              <p class="truncate">{{ option.name }}</p>
            </li>
          </ul>
        </div>
      </template>
    </Popper>
    <AutomationGrid
      :list="platforms"
      v-if="!selectedPlatform"
      @item-selected="onPlatformSelected"
    />
    <AutomationGrid
      :list="automations"
      v-else
      @item-selected="onAutomationSelected"
      @load-more="onLoadMore"
    />
    <div
      v-if="loading"
      class="my-2 flex w-full items-center justify-center gap-1 text-sm text-gray-400"
    >
      Loading
      <Spinner size="small" class="text-gray-400" />
    </div>
  </div>
</template>

<script setup>
import {
  getAllPlatforms,
  getPlatformOperations
} from '@/apis/getPlatformDetails'
import { NODE_TYPE_NAMES } from '@/common/constants'
import Spinner from '@/components/Spinner.vue'
import { computed, onMounted, ref, watch, onUnmounted } from 'vue'
import { vue3Debounce } from 'vue-debounce'
import AutomationGrid from './automationGrid.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Popper from 'vue3-popper'
import { useStore } from 'vuex'
import NoAccess from '@/components/NoAccess.vue'

const props = defineProps({
  type: String
})

window.addEventListener('resize', setDropdownWidth)

const store = useStore()
const noAccessFlag = ref(false)
const firstTime = ref(null)
const loading = ref(false)
const search = ref('')
const platforms = ref([])
const selectedPlatform = ref(null)
const automations = ref([])
const areAllAutomationsFetched = ref(false)
const showPopper = ref(false)
const categoryOptions = ref([
  { name: 'All', icon: 'list' },
  { name: 'Search & Collect', icon: 'search' },
  { name: 'Scan', icon: 'Scan' },
  { name: 'Communicate', icon: 'Communicate' },
  { name: 'React', icon: 'React' },
  { name: 'Network', icon: 'Network' },
  { name: 'Enrich', icon: 'mail-verification' },
  { name: 'AI', icon: 'stars' }
])
const selectedCategory = ref('All')
const selectInputContainer = ref()
const dropdownWidth = ref(selectInputContainer.value?.clientWidth)

const vDebounce = vue3Debounce({})

const emit = defineEmits(['item-selected'])

const searchLabel = computed(() =>
  selectedPlatform.value ? `2. Select ${props.type}` : `1. Select Platform`
)

watch(
  () => props.type,
  (newType, oldType) => {
    if (newType !== oldType) {
      clearSearch(true)
      if (
        newType === 'integration' &&
        store._state.data.user.integrationsAccess === false
      ) {
        noAccessFlag.value = true
        return
      } else {
        noAccessFlag.value = false
      }
      fetchOptions()
    }
  }
)

function setDropdownWidth() {
  dropdownWidth.value = selectInputContainer.value?.clientWidth
}

const onLoadMore = () => {
  if (firstTime.value) {
    firstTime.value = null
    return
  }

  fetchOptions(search.value)
}

const fetchOptions = async (q, reset) => {
  if (selectedPlatform.value && areAllAutomationsFetched.value && !reset) {
    return
  }
  loading.value = true
  if (selectedPlatform.value) {
    const platform = selectedPlatform.value

    let start = automations.value.length
    if (reset) {
      start = 0
      automations.value = []
    }

    const response = await getPlatformOperations(
      platform._id,
      q?.trim(),
      start,
      100,
      selectedCategory.value === 'All' ? null : selectedCategory.value
    )

    automations.value = response.data.map(res => ({
      ...res,
      logoUrl: platform.logoUrl,
      platformId: platform._id,
      nodeType: NODE_TYPE_NAMES.AUTOMATION_NODE
    }))

    areAllAutomationsFetched.value = response.total === automations.value.length
  } else {
    const response = await getAllPlatforms(0, 50, props.type, q)
    platforms.value = response.data
  }
  loading.value = false
}

const onCategoryClick = name => {
  //update selected category
  selectedCategory.value = name
  //fetch platform operations with the searchtext and reset
  fetchOptions(search.value, true)
  //close dropdown
  showPopper.value = false
}

//validate keys so that search function does not run on shift or ctrl press
const keyValidate = (q, e) => {
  if (
    e.key === 'Shift' ||
    e.key === 'Control' ||
    e.key === 'Meta' ||
    e.key === 'Backspace'
  ) {
    return
  } else {
    fetchOptions(q, true)
  }
}

onMounted(async () => {
  if (
    props.type === 'integration' &&
    store._state.data.user.integrationsAccess === false
  ) {
    noAccessFlag.value = true
    return
  }
  await fetchOptions()
  setDropdownWidth()
})
onUnmounted(() => {
  window.removeEventListener('resize', setDropdownWidth)
})

const clearSearch = async shouldResetPlatforms => {
  selectedPlatform.value = null
  if (shouldResetPlatforms) platforms.value = []
  search.value = ''
  areAllAutomationsFetched.value = false
  automations.value = []
}

const onPlatformSelected = async platform => {
  firstTime.value = platform._id
  search.value = ''
  areAllAutomationsFetched.value = false
  selectedPlatform.value = platform
  await fetchOptions()
}

const onAutomationSelected = async automation => {
  search.value = ''
  emit('item-selected', automation)
  fetchOptions('', true)
}
</script>

<style scoped>
.dropdown-border {
  border: 0.5px solid #bec0c5;
  /* Drop Shadow - 5% */

  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05);
  border-radius: 7px;
}
</style>
