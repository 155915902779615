import axios from 'axios';
const env = import.meta.env

const API_URL = env.VUE_APP_AI_WIZARD_URL || "https://ai-wizard-beta.vercel.app";

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const getAutomationSuggestion = async (data = {}) => {
  try {
    const response = await axiosInstance.post("/ai-wizard", data);
    return response.data;
  } catch (error) {
    console.error("API Error in getAutomationSuggestion:", error);
    return error.response?.data || { error: "An unexpected error occurred. Please try again later." };
  }
};

export {getAutomationSuggestion};