<template>
  <div>
    <div class="my-4 flex flex-wrap gap-4">
      <Badge
        v-for="variable of Object.entries(texauVariables)
          .filter(
            ([_, value]) =>
              value !== '' &&
              value !== ' ' &&
              value !== null &&
              value !== undefined
          )
          .map(([key]) => key)
          .slice(0, expanded ? undefined : 4)"
        :text="variable"
        color="primary"
        class="cursor-pointer rounded bg-green-100 px-1 text-sm font-semibold text-green-600 dark:bg-green-900 dark:text-green-50"
        @click="$emit('selected', variable)"
      />
      <Badge
        v-if="
          Object.entries(texauVariables).filter(
            ([_, value]) =>
              value !== '' &&
              value !== ' ' &&
              value !== null &&
              value !== undefined
          ).length > 4
        "
        :icon="expanded ? 'close' : 'plus'"
        size="small"
        class="cursor-pointer"
        text=""
        @click="expanded = !expanded"
      />
    </div>
  </div>
</template>
<script setup>
import Badge from '@/components/Badge.vue'
import { ref } from 'vue'

defineProps({
  texauVariables: Object
})
defineEmits(['selected'])
const expanded = ref(false)
</script>
