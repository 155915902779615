<script setup>
import { EdgeLabelRenderer } from '@vue-flow/core'
import { computed } from 'vue'

const props = defineProps({
  sourceX: {
    type: Number,
    required: true
  },
  sourceY: {
    type: Number,
    required: true
  },
  targetX: {
    type: Number,
    required: true
  },
  targetY: {
    type: Number,
    required: true
  },
  data: {},
  target: {},
  source: {}
})

const id = props.sourceY < props.targetY ? 'routerTop' : 'routerBottom'

const style = computed(() => {
  if (props.sourceY < props.targetY) {
    return {
      top: (props.sourceY + props.targetY) / 2 - 40 + 'px',
      left: (props.sourceX + props.targetX) / 2 - 72 + 'px'
    }
  } else {
    return {
      top: (props.sourceY + props.targetY) / 2 + 20 + 'px',
      left: (props.sourceX + props.targetX) / 2 - 72 + 'px'
    }
  }
})
</script>

<template>
  <g>
    <defs>
      <linearGradient id="red" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop
          offset="0%"
          style="stop-color: var(--color-red-start); stop-opacity: 1"
        />
        <stop
          offset="100%"
          style="stop-color: var(--color-red-end); stop-opacity: 1"
        />
      </linearGradient>
      <linearGradient id="green" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop
          offset="0%"
          style="stop-color: var(--color-green-start); stop-opacity: 1"
        />
        <stop
          offset="100%"
          style="stop-color: var(--color-green-end); stop-opacity: 1"
        />
      </linearGradient>
      <linearGradient id="blue" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop
          offset="0%"
          style="stop-color: var(--color-blue-start); stop-opacity: 1"
        />
        <stop
          offset="100%"
          style="stop-color: var(--color-blue-end); stop-opacity: 1"
        />
      </linearGradient>
      <linearGradient id="toplightblue" x1="0%" y1="100%" x2="0%" y2="0%">
        <stop
          offset="0%"
          style="stop-color: var(--color-lightblue-start); stop-opacity: 1"
        />
        <stop
          offset="100%"
          style="stop-color: var(--color-lightblue-end); stop-opacity: 1"
        />
      </linearGradient>
      <linearGradient id="bottomlightblue" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop
          offset="0%"
          style="stop-color: var(--color-lightblue-start); stop-opacity: 1"
        />
        <stop
          offset="100%"
          style="stop-color: var(--color-lightblue-end); stop-opacity: 1"
        />
      </linearGradient>
    </defs>
    <path
      v-if="
        Math.abs(targetX - sourceX) <= 10 || Math.abs(sourceY - targetY) <= 10
      "
      :stroke="
        Math.abs(sourceY - targetY) <= 10
          ? 'url(#blue)'
          : sourceY < targetY
          ? 'url(#bottomlightblue)'
          : 'url(#toplightblue)'
      "
      :stroke-width="4"
      :d="`M${sourceX},${sourceY} L${targetX + 0.0001},${targetY + 0.0001}`"
      stroke-dasharray="0,7"
      stroke-linecap="round"
    />
    <g v-else>
      <path
        :stroke="sourceY < targetY ? 'url(#red)' : 'url(#green)'"
        :stroke-width="4"
        :d="`M${sourceX + 4},${sourceY} L${targetX + 4},${targetY}`"
        stroke-dasharray="0,7"
        stroke-linecap="round"
        :id="id"
      />
      <EdgeLabelRenderer>
        <div
          v-if="sourceY !== targetY"
          :style="{
            pointerEvents: 'all',
            position: 'absolute',

            ...style
          }"
          class="nodrag nopan group flex w-32 flex-col items-center"
        >
          <div
            :style="{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }"
            class="w-fit max-w-[128px] rounded-lg bg-white bg-opacity-40 p-1 text-xs font-semibold text-gray-900"
          >
            {{ data?.routeLabel || (sourceY > targetY ? 'Yes' : 'No') }}
          </div>
          <button
            @click.stop="data.editFilter({ target, source })"
            class="invisible group-hover:visible"
          >
            <svg-icon name="wrench" class="h-4 w-4" />
          </button>
        </div>
      </EdgeLabelRenderer>
    </g>
  </g>
</template>

<style>
:root {
  --color-lightblue-start: theme('colors.blue.100');
  --color-lightblue-end: theme('colors.blue.200');
  --color-blue-start: theme('colors.blue.100');
  --color-blue-end: theme('colors.blue.500');
  --color-red-start: theme('colors.red.100');
  --color-red-end: theme('colors.red.500');
  --color-green-start: theme('colors.green.100');
  --color-green-end: theme('colors.green.500');
}
</style>
