<template>
  <div class="overflow-y-auto py-5 pr-2" ref="gridEl">
    <div class="grid grid-cols-3 justify-items-center gap-5">
      <div
        class="group cursor-pointer text-center"
        v-for="item of list.filter(
          item => !item.hide && !item?.hideFromFrontEnd
        )"
      >
        <div
          class="transition-all relative duration-200"
          :class="[
            {
              'cursor-not-allowed': item?.issue?.hasIssue
            }
          ]"
          @click="
            () => {
              if (item?.issue?.hasIssue) {
                return
              } else $emit('item-selected', item)
            }
          "
        >
          <div
            v-if="item.nodeType === 'automationNode' && item?.issue?.hasIssue"
            class="absolute right-1 top-1 text-orange-500"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path
                d="M12 1.67c.955 0 1.845 .467 2.39 1.247l.105 .16l8.114 13.548a2.914 2.914 0 0 1 -2.307 4.363l-.195 .008h-16.225a2.914 2.914 0 0 1 -2.582 -4.2l.099 -.185l8.11 -13.538a2.914 2.914 0 0 1 2.491 -1.403zm.01 13.33l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm-.01 -7a1 1 0 0 0 -.993 .883l-.007 .117v4l.007 .117a1 1 0 0 0 1.986 0l.007 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z"
                stroke-width="0"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div
            class="flex items-center justify-center rounded-xl border border-gray-200 bg-white group-hover:border-blue-600 group-hover:bg-blue-50 dark:border-gray-600 dark:bg-gray-900 dark:group-hover:border-blue-500 dark:group-hover:bg-blue-900"
            :style="{
              height: NODE_SIZES[NODE_TYPE_NAMES.AUTOMATION_NODE] + 2 + 'px',
              width: NODE_SIZES[NODE_TYPE_NAMES.AUTOMATION_NODE] + 2 + 'px'
            }"
          >
            <SvgIcon v-if="item.isIcon" :name="item.logoUrl" />
            <img
              v-else
              class="mx-auto h-10 w-10 rounded-full object-cover"
              :src="item.logoUrl"
              alt=""
            />
          </div>
          <p
            class="mt-3 overflow-hidden break-words text-xs font-semibold text-gray-900 group-hover:text-blue-600 dark:text-gray-50 dark:group-hover:text-gray-50"
            :style="{
              width: NODE_SIZES[NODE_TYPE_NAMES.AUTOMATION_NODE] + 2 + 'px'
            }"
          >
            {{ item.type ? item.label : item.name }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { NODE_SIZES, NODE_TYPE_NAMES } from '@/common/constants'
import { useInfiniteScroll } from '@/common/useInfiniteScroll'
import SvgIcon from '@/components/SvgIcon.vue'
import { ref } from 'vue'

const props = defineProps({
  list: {
    type: Array,
    required: true
  },
  onLoadMore: Function
})

const emit = defineEmits(['dragstart', 'item-selected', 'load-more'])

const gridEl = ref(null)

useInfiniteScroll(gridEl, {
  onLoadMore: () => emit('load-more')
})
</script>
